import React, { useState } from "react";

import {
  ButtonContainer,
  LoaderContainer,
} from "../../../functions/STYLE/ACQUISTATO_NEW/ACQ_VIEW_CONTAINER";

import acquistatoService from "../../../services/acquistato";
import filefService from "../../../services/filef";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import BeatLoader from "react-spinners/BeatLoader";

import EXP_FILEF_FIRSTMATCH from "../../../exports/ACQ/EXP_FILEF_FIRSTMATCH";

const FileFAnalysis = ({
  purchasedList,
  loadingPurchasedsList,
  setACQ_Selected,
  ACQ_Selected,
  setSingleACQ_Downloaded,
  SingleACQ_Downloaded,
  loadingSinglePurchased,
  setLoadingSinglePurchased,
  fileFList,
}) => {
  const [fileFSEL, setFileFSEL] = useState(null);
  const [loadingMatchFileF, setLoadingMatchFileF] = useState(false);
  const [fileFAN, setFileFAN] = useState(null);

  const handlePurchasedDownload = async () => {
    setLoadingSinglePurchased(true);

    try {
      const res = await acquistatoService.getAcquistatoByPurchasedId(
        ACQ_Selected.id
      );
      const sortedAcq = res.data.acquistato_inserito.sort((a, b) =>
        a.principio_attivo.localeCompare(b.principio_attivo)
      );
      const resultATC = sortedAcq.reduce((acc, item) => {
        (acc[item.principio_attivo] = acc[item.principio_attivo] || []).push(
          item
        );
        return acc;
      }, {});
      let newOBJ = [];
      Object.keys(resultATC).forEach((principioAttivo) => {
        let ATCListTemp = resultATC[principioAttivo].sort((a, b) =>
          a.codice_gruppo_ospedaliero.localeCompare(b.codice_gruppo_ospedaliero)
        );
        newOBJ.push(ATCListTemp);
      });
      let finalOBJ = [];
      newOBJ.forEach((Selected) => {
        let resultATCComp = Selected.reduce((acc, item) => {
          (acc[item.codice_gruppo_ospedaliero] =
            acc[item.codice_gruppo_ospedaliero] || []).push(item);
          return acc;
        }, {});
        Object.keys(resultATCComp).forEach((codiceGruppo) => {
          let ATCListTemp = resultATCComp[codiceGruppo].sort(
            (a, b) => a.prezzo_unitario - b.prezzo_unitario
          );
          finalOBJ.push(ATCListTemp);
        });
      });
      setSingleACQ_Downloaded(res.data);
    } catch (error) {
      console.error("Errore durante il recupero dell'acquistato:", error);
    } finally {
      setLoadingSinglePurchased(false);
    }
  };

  const handleResetACQ = () => {
    setSingleACQ_Downloaded(null);
  };

  const handleMatchFileF = async () => {
    console.log("handleMatchFileF");
    setLoadingMatchFileF(true);

    const ACQ_TEMP = [...SingleACQ_Downloaded?.acquistato_inserito];
    try {
      const res = await filefService.getFileFByFileFId(fileFSEL.id);
      console.log("res.data", res.data);

      const setListaFSet = new Set(res.data?.obj_file);

      const FILEF = ACQ_TEMP.filter((item) => setListaFSet.has(item.aic));
      const NO_FILEF = ACQ_TEMP.filter((item) => !setListaFSet.has(item.aic));

      console.log("FILEF", FILEF);
      console.log("NO_FILEF", NO_FILEF);

      setFileFAN({
        FILEF: FILEF,
        NO_FILEF: NO_FILEF,
      });
    } catch (error) {
      console.error("Errore durante il recupero dell'acquistato:", error);
    } finally {
      setLoadingMatchFileF(false);
    }
  };
  return (
    <>
      {loadingPurchasedsList ? (
        <LoaderContainer>
          <BeatLoader
            color="#1976d2"
            loading={loadingPurchasedsList}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </LoaderContainer>
      ) : (
        <>
          {SingleACQ_Downloaded === null ? (
            <>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={purchasedList}
                getOptionLabel={(option) => option.nome_salvataggio}
                sx={{ width: "100%", padding: "10% 38% 0" }}
                renderInput={(params) => (
                  <TextField {...params} label="LISTA ACQUISTATI" />
                )}
                value={ACQ_Selected}
                onChange={(e, newValue) => setACQ_Selected(newValue)}
              />
              {ACQ_Selected && (
                <ButtonContainer>
                  <Button
                    variant="contained"
                    color="bluetest"
                    startIcon={<FileDownloadOutlinedIcon />}
                    onClick={handlePurchasedDownload}
                  >
                    CARICA SELEZIONATO
                  </Button>
                </ButtonContainer>
              )}
              {loadingSinglePurchased && (
                <LoaderContainer>
                  <BeatLoader
                    color="#1976d2"
                    loading={loadingSinglePurchased}
                    size={30}
                  />
                </LoaderContainer>
              )}
            </>
          ) : (
            <>
              ACQ SELECTED: {SingleACQ_Downloaded?.nome_salvataggio}
              <div
                style={{
                  display: "inline-block",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <Button
                  variant="contained"
                  color="redtest"
                  onClick={handleResetACQ}
                  startIcon={<ArrowBackIosNewOutlinedIcon />}
                >
                  INDIETRO
                </Button>
              </div>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={fileFList}
                getOptionLabel={(option) => option.anno}
                sx={{ width: "100%", padding: "10% 38% 0" }}
                renderInput={(params) => (
                  <TextField {...params} label="FILE F INSERITI" />
                )}
                value={fileFSEL}
                onChange={(e, newValue) => setFileFSEL(newValue)}
              />
              {fileFSEL !== null && (
                <>
                  <div
                    style={{
                      display: "inline-block",
                      width: "100%",
                      textAlign: "center",
                      marginTop: "2%",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="bluetest"
                      onClick={handleMatchFileF}
                      // startIcon={<ArrowBackIosNewOutlinedIcon />}
                    >
                      MATCH FILE F
                    </Button>
                  </div>
                  {loadingMatchFileF && (
                    <LoaderContainer>
                      <BeatLoader
                        color="#1976d2"
                        loading={loadingMatchFileF}
                        size={30}
                      />
                    </LoaderContainer>
                  )}
                  <EXP_FILEF_FIRSTMATCH data={fileFAN} />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default FileFAnalysis;
