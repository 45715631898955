import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import BeatLoader from "react-spinners/BeatLoader";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import EXP_ANALYSIS_OFF_COMPLETE_BIS from "../../exports/OFF/EXP_ANALYSIS_OFF_COMPLETE_BIS";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OFFER_CONTROL_POPUP = ({
  offerControlPopupOpen,
  setOfferControlPopupOpen,
  loadingOffersResumeList,
  offersResumeList,
  offersResumeSelected,
  setOffersResumeSelected,
  handleSelectOffersResume,
  loadingMultipleOfferAnalysis,
  handleValutazioneOFF,
  supplierNOTFoundFlag,
  setSupplierNOTFoundFlag,
  VAL_Flag,
  setVAL_Flag,
  completezzaOFF_OBJ,
  setCompletezzaOFF_OBJ,
}) => {
  // console.log("offersResumeSelected", offersResumeSelected);

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="acqPage">
          <Dialog
            open={offerControlPopupOpen}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="md"
            keepMounted
            onClose={() => {
              setOfferControlPopupOpen(false);
              setSupplierNOTFoundFlag(false);
              setVAL_Flag(false);
              setOffersResumeSelected(null);
              setCompletezzaOFF_OBJ(null);
            }}
            PaperProps={{
              style: {
                backgroundColor: "#f4f4f4",
                border: "1px solid #ccc",
                borderRadius: "10px",
                height: "400px",
              },
            }}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle
              sx={{
                fontFamily: "Calibri",
                fontWeight: "bold",
                color: "#1976d2",
                fontSize: 32,
                textAlign: "center",
              }}
            >
              CONTROLLO COMPLETEZZA OFFERTA:
            </DialogTitle>
            <DialogContent>
              {loadingOffersResumeList ? (
                <div className="off-an-loading">
                  <BeatLoader
                    color="#1976d2"
                    loading={loadingOffersResumeList}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                    CARICAMENTO...
                  </h6>
                </div>
              ) : (
                <>
                  <h3
                    style={{
                      fontFamily: "Calibri",
                      fontWeight: "bold",
                      color: "#1976d2",
                      textAlign: "center",
                      margin: "1% 0%",
                    }}
                  >
                    SELEZIONA IL LISTINO
                  </h3>
                  <Autocomplete
                    disablePortal
                    id="list-select"
                    options={offersResumeList}
                    getOptionLabel={(option) => option.nome_listino}
                    sx={{
                      width: "100%",
                      padding: "0 25%",
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="LISTINI" />
                    )}
                    value={offersResumeSelected}
                    onChange={(e, newValue) =>
                      handleSelectOffersResume(newValue)
                    }
                  />
                  {offersResumeSelected !== null && (
                    <>
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "3%",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="bluetest"
                          // startIcon={<SaveOutlinedIcon />}
                          onClick={handleValutazioneOFF}
                        >
                          CONTROLLA COMPLETEZZA OFFERTA
                        </Button>
                      </div>
                      {loadingMultipleOfferAnalysis && (
                        <div className="off-an-loading">
                          <BeatLoader
                            color="#1976d2"
                            loading={loadingMultipleOfferAnalysis}
                            size={30}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                          <h6
                            style={{ fontFamily: "Calibri", color: "#1976d2" }}
                          >
                            CARICAMENTO...
                          </h6>
                        </div>
                      )}

                      {VAL_Flag && (
                        <>
                          {!supplierNOTFoundFlag ? (
                            <div
                              style={{ paddingTop: "2%", textAlign: "center" }}
                            >
                              <EXP_ANALYSIS_OFF_COMPLETE_BIS
                                data={completezzaOFF_OBJ}
                                list_name={offersResumeSelected.nome_listino}
                              />
                            </div>
                          ) : (
                            <h2 className="off-an-error">
                              FORNITORE NON TROVATO!
                            </h2>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </DialogContent>
          </Dialog>
        </div>
      </ThemeProvider>
    </>
  );
};

export default OFFER_CONTROL_POPUP;
