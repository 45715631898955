import React, { useState } from "react";

import DispMediciPage from "./DispMediciPage";
import DaRichiederePage from "./DaRichiederePage";

import Button from "@mui/material/Button";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import EXP_DETTAGLIO_GP_BIS from "../../../../exports/ACQ/EXP_DETTAGLIO_GP_BIS";

import {
  ABB_RESUME_COLUMNS,
  ABB_SELECTED_LIST_COLUMNS,
  ABB_SELECTION_ACQ_COLUMNS,
  ABB_SELECTION_OFFERS_COLUMNS,
  ABB_SELECTION_GAREPUB_COLUMNS,
  ABB_TOWAIT_COLUMNS,
  ABB_TOWAIT_ATC_COLUMNS,
  ABB_SELECTION_ACQ_COLUMNS_BIS,
  FILEF_TEMP_RESUME_COLUMNS,
} from "../../../../colums/ABB_COLUMNS";

import {
  // BENCH_ACQ_RESUME_COLUMNS,
  // ABB_SELECTION_ACQ_COLUMNS,
  // ABB_SELECTION_ACQ_COLUMNS_NODIFF,
  ABB_SELECTION_ACQ_COLUMNS_NODIFF_BIS,
  GARE_GROUPED_ACQ_COLUMNS_BIS,
  GARE_SELECTION_ACQ_COLUMNS,
  // GARE_GROUPED_ACQ_COLUMNS,
  GARE_GROUPED_ACQ_COLUMNS_TEST,
} from "../../../../colums/BENCHMARKING_COLUMNS";

import { BENCH_RESUME_COLUMNS } from "../../../../colums/BENCHMARKING_COLUMNS";

import EXP_ABB_SELECTED_TAB from "../../../../exports/ABB/EXP_ABB_SELECTED_TAB";
import EXP_ABB_CONFRONTO from "../../../../exports/ABB/EXP_ABB_CONFRONTO_GP";
import EXP_ABB_STRUTTURE_RESUME from "../../../../exports/ABB/EXP_ABB_STRUTTURE_RESUME";

import EXP_BENCHMARKING_RESUME from "../../../../exports/ABB/EXP_BENCHMARKING_RESUME";

import EXP_MULTIPLI_POPUP from "../../../../popup/ABB_POPUPS/EXP_MULTIPLI_POPUP";
import GP_DETAILS_POPUP from "../../../../popup/ABB_POPUPS/GP_DETAILS_POPUP";
import CHANGE_SELECTION_POPUP from "../../../../popup/ABB_POPUPS/CHANGE_SELECTION_POPUP";
import SAVE_UPDATE_POPUP from "../../../../popup/ABB_POPUPS/SAVE_UPDATE_POPUP";
import EXPS_RESUME_POPUP from "../../../../popup/ABB_POPUPS/EXPS_RESUME_POPUP";
import EXPS_RESUME_PRESAVE_POPUP from "../../../../popup/ABB_POPUPS/EXPS_RESUME_PRESAVE_POPUP";

import BENCHMARKING_DETAILS_POPUP from "../../../../popup/ABB_POPUPS/BENCHMARKING_DETAILS_POPUP";

const SelectionMain = ({
  //*Flags
  selectionListPageFlag,
  specSelectionPageFlag,
  daRichiederePageFlag,
  dispMediciPageFlag,
  //*Oggetti Abbinato
  abbinatoDownloaded,
  abbinatoResume,
  specMatchedTOT,
  specToWait,
  specToWaitATC,
  garePubBench,
  // fileFOBJ_TEMP,
  fileFTAB_TEMP,
  //*
  handleBackToInitialPage,
  newABBFlag,
  handleSelectionListPage,
  handleReturnToResumePage,
  handleReturnToResumePageAndUpdate,
  setPopUpSaveABBOpen,
  popUpExpMultipliOpen,
  setPopUpExpMultipliOpen,
  searchedFlag,
  setSearchedFlag,
  setSearchPopupOpen,
  filteredResults,
  handleResetSearch,
  handleSpecSelectionPage,
  handleSpecSelectionPageBIS,
  handleBackToSelectionList,
  specTEMP,
  specGP_TEMP,
  popUpGaraPubOpen,
  handleGPPopUp,
  handlePopUpGaraPubClose,
  dettaglioGaraPub,
  popUpChangeSelectionOpen,
  paramsTEMP,
  handlePopUpChangeSelectionOpen,
  handlePopUpChangeSelectionClose,
  handleDoubleClickSpecSelection,
  setPopUpUpdateABBOpen,
  popUpUpdateABBOpen,
  // setLoadingUpdateABB,
  loadingUpdateABB,
  handleUpdateNewABB,
  //----
  garaDetailsOpen,
  gareSelected,
  setGaraDetailsOpen,
  setGareSelected,
  handleGaraDetailsClose,
  handleViewDetailsTab,
  //----
  dettaglioFlag,
  setDettaglioFlag,
  gareSingleDetail,
  arrayGrupedFlag,
  rowNameSEL,
  setArrayGrupedFlag,
  handleSingleDetail,
  handleSingleDetailGrouped,
  arrayGrupedTEMP,
  setGareSingleDetail,
  quantitaTOT,
  spesaTOT,
  setQuantitaTOT,
  setSpesaTOT,
  acqSelGareList,
  quantitaTOT_SPEC,
  spesaTOT_SPEC,
  //---
  arrayGrupedFlag_SPEC,
  setArrayGrupedFlag_SPEC,
  specMatchedTOT_RESUME,
}) => {
  // console.log("abbinatoResume", abbinatoResume);
  // console.log("specMatchedTOT", specMatchedTOT);
  // console.log("garePubBench", garePubBench);
  // console.log("acqSelGareList", acqSelGareList);
  // console.log("gareSingleDetail", gareSingleDetail);
  // console.log("gareSelected", gareSelected);

  // console.log("specTEMP", specTEMP);
  // console.log("specGP_TEMP", specGP_TEMP);

  // console.log("filteredResults", filteredResults);

  const handleReturnToGareDetails = () => {
    setGareSingleDetail(null);
    setQuantitaTOT(null);
    setSpesaTOT(null);
  };

  const RapprPrezzo = (PrezzoDaRappresentare) => {
    if (PrezzoDaRappresentare === "") {
      return PrezzoDaRappresentare;
    } else {
      let IntDec = PrezzoDaRappresentare.toString();
      IntDec = IntDec.split(".");

      let nuovoVal = "";
      let vecchioVal = IntDec[0];

      while (vecchioVal?.length > 3) {
        nuovoVal = "." + vecchioVal.substr(vecchioVal?.length - 3) + nuovoVal;
        vecchioVal = vecchioVal.substr(0, vecchioVal?.length - 3);
      }
      if (IntDec[1] !== undefined) {
        if (IntDec[1].length === 1) {
          nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1] + "0";
        } else {
          nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1];
        }
      } else {
        nuovoVal = vecchioVal + nuovoVal + ",00";
      }

      let finalVal = nuovoVal;

      if (finalVal.startsWith("-.")) {
        finalVal = "- " + finalVal.substr(2);
      } else if (finalVal.startsWith(".")) {
        finalVal = finalVal.substr(1);
      }

      return finalVal + " €";
    }
  };

  const [expsVariPopUpOpen, setExpsVariPopUpOpen] = useState(false);
  const [expsVariPreSavePopUpOpen, setExpsVariPreSavePopUpOpen] =
    useState(false);

  // console.log("filteredResults", filteredResults);
  return (
    <>
      {!selectionListPageFlag &&
        !specSelectionPageFlag &&
        !daRichiederePageFlag &&
        !dispMediciPageFlag &&
        !dettaglioFlag && (
          <>
            <div
              style={{
                marginBottom: "3%",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  display: "inline-block",
                  width: "10%",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  color="redtest"
                  startIcon={<ArrowBackIosNewOutlinedIcon />}
                  onClick={handleBackToInitialPage}
                >
                  INDIETRO
                </Button>
              </div>

              {!newABBFlag ? (
                <>
                  <div
                    style={{
                      textAlign: "left",
                      display: "inline-block",
                      width: "20%",
                    }}
                  >
                    {/* <Button
                      variant="contained"
                      component="label"
                      color="bluetest"
                      startIcon={<InventoryIcon />}
                      disabled
                      // onClick={() => handlePopUpSuppResumeOpen()}
                    >
                      SINTESI FORNITORI
                    </Button> */}
                  </div>
                  {/* <div
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      width: "25%",
                    }}
                  >
                    <EXP_ABB_CONFRONTO data={abbinatoDownloaded} />
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      width: "20%",
                    }}
                  >
                    <EXP_ABB_SELECTED_TAB
                      data={specMatchedTOT}
                      abb_name={abbinatoDownloaded?.nome_abbinato}
                    />
                  </div> */}
                  <div
                    style={{
                      textAlign: "CENTER",
                      display: "inline-block",
                      width: "45%",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="bluetest"
                      startIcon={<FileDownloadOutlinedIcon />}
                      onClick={() => setExpsVariPopUpOpen(true)}
                    >
                      EXPORT
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      width: "65%",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="bluetest"
                      startIcon={<FileDownloadOutlinedIcon />}
                      onClick={() => setExpsVariPreSavePopUpOpen(true)}
                    >
                      EXPORT
                    </Button>
                  </div>
                </>
              )}

              <div
                style={{
                  textAlign: "right",
                  display: "inline-block",
                  width: "25%",
                }}
              >
                {newABBFlag ? (
                  <>
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={<SaveOutlinedIcon />}
                      onClick={() => setPopUpSaveABBOpen(true)}
                    >
                      PROSEGUI E SALVA
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="bluetest"
                      startIcon={<SaveOutlinedIcon />}
                      onClick={() => setPopUpUpdateABBOpen(true)}
                    >
                      SALVA MODIFICHE
                    </Button>
                  </>
                )}
              </div>
            </div>
            <Box
              sx={{
                width: "100%",
                marginBottom: "1%",
                paddingRight: "10%",
                paddingLeft: "10%",

                "& .theme_header_1": {
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  fontFamily: "Calibri",
                  fontSize: "16px",
                },

                "& .theme_cell": {
                  fontFamily: "Calibri",
                  fontSize: "17px",
                  border: "1px solid #000",
                },
                "& .theme_cell_2": {
                  fontFamily: "Calibri",
                  fontSize: "17px",
                  border: "1px solid #000",
                  borderLeft: "2px solid #000",
                },
                "& .theme_cell_lost": {
                  fontFamily: "Calibri",
                  backgroundColor: "#FFC7CE",
                  color: "#9C0006",
                  fontSize: "17px",
                  border: "1px solid",
                },
                "& .theme_cell_gain": {
                  fontFamily: "Calibri",
                  backgroundColor: "#C6EFCE",
                  color: "#006100",
                  fontSize: "17px",
                  border: "1px solid",
                },
              }}
            >
              <DataGridPro
                columns={ABB_RESUME_COLUMNS}
                rows={abbinatoResume}
                getRowId={(row) => row.title}
                headerHeight={40}
                rowHeight={33}
                autoHeight
                {...abbinatoResume}
                onRowDoubleClick={handleSelectionListPage}
                disableColumnMenu
                hideFooter
              />
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {newABBFlag ? (
                <>
                  <Box
                    sx={{
                      display: "inline-block",
                      width: "43%",
                      paddingBottom: "1%",

                      "& .theme_header": {
                        backgroundColor: "#1976d2",
                        color: "#fff",
                        fontFamily: "Calibri",
                        fontSize: "16px",
                      },
                      "& .theme_cell": {
                        fontFamily: "Calibri",
                        fontSize: "17px",
                        border: "0.5px solid rgba(0, 0, 0, 0.5)",
                      },
                      "& .theme_cell_lost": {
                        fontFamily: "Calibri",
                        backgroundColor: "#FFC7CE",
                        color: "#9C0006",
                        fontSize: "17px",
                        border: "0.5px solid rgba(0, 0, 0, 0.5)",
                      },
                      "& .theme_cell_gain": {
                        fontFamily: "Calibri",
                        backgroundColor: "#C6EFCE",
                        color: "#006100",
                        fontSize: "17px",
                        border: "0.5px solid rgba(0, 0, 0, 0.5)",
                      },
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        display: "inline-block",
                        color: "#1976d2",
                        fontSize: "1.35vw",
                        fontFamily: "Calibri",
                        fontWeight: "bold",
                        textShadow: "2px 2px 8px rgba(0,0,0,0.2)",
                      }}
                    >
                      FILE F
                    </div>
                    <DataGridPro
                      columns={FILEF_TEMP_RESUME_COLUMNS}
                      rows={fileFTAB_TEMP}
                      getRowId={(row) => row.title}
                      headerHeight={40}
                      rowHeight={33}
                      autoHeight
                      {...fileFTAB_TEMP}
                      disableColumnMenu
                      hideFooter
                    />
                  </Box>
                </>
              ) : (
                <></>
              )}

              <Box
                sx={{
                  display: "inline-block",
                  width: "2%",
                  paddingBottom: "1%",
                }}
              ></Box>
              <Box
                sx={{
                  display: "inline-block",
                  width: "55%",
                  paddingBottom: "1%",

                  "& .theme_header": {
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    fontFamily: "Calibri",
                    fontSize: "16px",
                  },
                  "& .theme_cell": {
                    fontFamily: "Calibri",
                    fontSize: "17px",
                    border: "0.5px solid rgba(0, 0, 0, 0.5)",
                  },
                  "& .theme_cell_lost": {
                    fontFamily: "Calibri",
                    backgroundColor: "#FFC7CE",
                    color: "#9C0006",
                    fontSize: "17px",
                    border: "0.5px solid rgba(0, 0, 0, 0.5)",
                  },
                  "& .theme_cell_gain": {
                    fontFamily: "Calibri",
                    backgroundColor: "#C6EFCE",
                    color: "#006100",
                    fontSize: "17px",
                    border: "0.5px solid rgba(0, 0, 0, 0.5)",
                  },
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    display: "inline-block",
                    color: "#1976d2",
                    fontSize: "1.35vw",
                    fontFamily: "Calibri",
                    fontWeight: "bold",
                    textShadow: "2px 2px 8px rgba(0,0,0,0.2)",
                  }}
                >
                  BENCHMARKING GARE PUBBLICHE
                </div>
                <DataGridPro
                  columns={BENCH_RESUME_COLUMNS}
                  rows={garePubBench.BENCH_TAB}
                  getRowId={(row) => row.title}
                  headerHeight={40}
                  rowHeight={33}
                  autoHeight
                  {...garePubBench.BENCH_TAB}
                  onRowDoubleClick={handleViewDetailsTab}
                  disableColumnMenu
                  hideFooter
                />
              </Box>
            </div>
          </>
        )}
      {daRichiederePageFlag && (
        <>
          <DaRichiederePage
            handleReturnToResumePage={handleReturnToResumePage}
            specToWait={specToWait}
            ABB_TOWAIT_COLUMNS={ABB_TOWAIT_COLUMNS}
            abb_name={abbinatoDownloaded?.nome_abbinato}
          />
        </>
      )}
      {dispMediciPageFlag && (
        <>
          <DispMediciPage
            handleReturnToResumePage={handleReturnToResumePage}
            specToWaitATC={specToWaitATC}
            ABB_TOWAIT_ATC_COLUMNS={ABB_TOWAIT_ATC_COLUMNS}
            abb_name={abbinatoDownloaded?.nome_abbinato}
          />
        </>
      )}
      {selectionListPageFlag && !specSelectionPageFlag && (
        <>
          <div>
            <div
              style={{
                textAlign: "left",
                paddingLeft: "0.3%",
                display: "inline-block",
                width: "10%",
              }}
            >
              <Button
                variant="contained"
                color="redtest"
                startIcon={<ArrowBackIosNewOutlinedIcon />}
                onClick={handleReturnToResumePageAndUpdate}
              >
                INDIETRO
              </Button>
            </div>
            {!arrayGrupedFlag_SPEC ? (
              <>
                {" "}
                <div
                  style={{
                    display: "inline-block",
                    width: "15%",
                    textAlign: "left",
                  }}
                >
                  <Button
                    variant="contained"
                    color="bluetest"
                    startIcon={<SearchIcon />}
                    onClick={() => setSearchPopupOpen(true)}
                    // disabled
                  >
                    CERCA
                  </Button>
                </div>
                {!searchedFlag ? (
                  <div
                    style={{
                      display: "inline-block",
                      width: "25%",
                      textAlign: "left",
                    }}
                  ></div>
                ) : (
                  <div
                    style={{
                      display: "inline-block",
                      width: "25%",
                      textAlign: "left",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="redtest"
                      startIcon={<RestartAltIcon />}
                      onClick={handleResetSearch}
                      // disabled
                    >
                      RESET CERCA
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <>
                {" "}
                <div
                  style={{
                    display: "inline-block",
                    width: "35%",
                    textAlign: "left",
                  }}
                ></div>
              </>
            )}

            <div
              style={{
                textAlign: "right",
                display: "inline-block",
                width: "10%",
              }}
            >
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Switch
                        checked={arrayGrupedFlag_SPEC}
                        onChange={() =>
                          setArrayGrupedFlag_SPEC(!arrayGrupedFlag_SPEC)
                        }
                      />
                    }
                    label={
                      <h4
                        style={{
                          fontFamily: "Calibri",
                          color: arrayGrupedFlag_SPEC ? "#1976d2" : "#ccc",
                        }}
                      >
                        DETTAGLIO TIPOLOGIA
                      </h4>
                    }
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <div
              style={{
                textAlign: "right",
                display: "inline-block",
                width: "20%",
              }}
            >
              <EXP_ABB_SELECTED_TAB
                data={specMatchedTOT}
                abb_name={abbinatoDownloaded?.nome_abbinato}
              />
            </div>
            <div
              style={{
                textAlign: "right",
                paddingRight: "0.3%",
                display: "inline-block",
                width: "15%",
              }}
            >
              {newABBFlag ? (
                <>
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<SaveOutlinedIcon />}
                    onClick={() => setPopUpSaveABBOpen(true)}
                  >
                    PROSEGUI E SALVA
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="bluetest"
                    startIcon={<SaveOutlinedIcon />}
                    // disabled
                    onClick={() => setPopUpUpdateABBOpen(true)}
                  >
                    SALVA MODIFICHE
                  </Button>
                </>
              )}
            </div>
          </div>
          {!arrayGrupedFlag_SPEC ? (
            <>
              {" "}
              <Box
                sx={{
                  height: "500px",
                  width: "100%",
                  paddingLeft: "0.3%",
                  paddingRight: "0.3%",
                  paddingTop: "1%",
                  paddingBottom: "1%",
                  "& .theme_header": {
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    fontFamily: "Calibri",
                    fontSize: "16px",
                  },
                  "& .theme_cell": {
                    fontFamily: "Calibri",
                    fontSize: "17px",
                    border: "1px solid #000",
                  },
                  "& .theme_cell_collectivePrize": {
                    fontFamily: "Calibri",
                    fontSize: "17px",
                    backgroundColor: "#d9d9d9",
                    border: "1px solid #000",
                  },

                  "& .theme_cell_selected": {
                    fontFamily: "Calibri",
                    fontSize: "17px",
                    backgroundColor: "#CAFEBA",
                    border: "1px solid #000",
                  },

                  "& .theme_cell_lost": {
                    fontFamily: "Calibri",
                    backgroundColor: "#FFC7CE",
                    color: "#9C0006",
                    fontSize: "17px",
                    border: "1px solid",
                  },
                  "& .theme_cell_gain": {
                    fontFamily: "Calibri",
                    backgroundColor: "#C6EFCE",
                    color: "#006100",
                    fontSize: "17px",
                    border: "1px solid",
                  },
                }}
              >
                {!searchedFlag ? (
                  <DataGridPro
                    columns={ABB_SELECTED_LIST_COLUMNS}
                    rows={specMatchedTOT}
                    getRowId={(row) => row._id}
                    headerHeight={40}
                    rowHeight={33}
                    initialState={{
                      pinnedColumns: {
                        left: [
                          "principio_attivo",
                          "descrizione_gruppo_ospedaliero",
                          "diff_spesa",
                        ],
                      },
                    }}
                    onRowDoubleClick={handleSpecSelectionPage}
                    disableColumnMenu
                    hideFooter
                  />
                ) : (
                  <DataGridPro
                    columns={ABB_SELECTED_LIST_COLUMNS}
                    rows={filteredResults}
                    getRowId={(row) => row._id}
                    headerHeight={40}
                    rowHeight={33}
                    initialState={{
                      pinnedColumns: {
                        left: [
                          "principio_attivo",
                          "descrizione_gruppo_ospedaliero",
                          "diff_spesa",
                        ],
                      },
                    }}
                    onRowDoubleClick={handleSpecSelectionPage}
                    disableColumnMenu
                    hideFooter
                  />
                )}
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  height: "500px",
                  width: "100%",
                  paddingTop: "0.5%",
                  paddingLeft: "4%",
                  paddingRight: "4%",

                  "& .theme_header": {
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    fontFamily: "Calibri",
                    fontSize: "16px",
                  },
                  "& .theme_cell": {
                    fontFamily: "Calibri",
                    fontSize: "17px",
                    border: "1px solid #000",
                  },
                  "& .theme_cell_lost": {
                    fontFamily: "Calibri",
                    backgroundColor: "#FFC7CE",
                    color: "#9C0006",
                    fontSize: "17px",
                    border: "1px solid",
                  },
                  "& .theme_cell_gain": {
                    fontFamily: "Calibri",
                    backgroundColor: "#C6EFCE",
                    color: "#006100",
                    fontSize: "17px",
                    border: "1px solid",
                  },
                }}
              >
                <DataGridPro
                  columns={GARE_GROUPED_ACQ_COLUMNS_TEST}
                  rows={specMatchedTOT_RESUME}
                  getRowId={(row) => row.id_temp}
                  headerHeight={40}
                  rowHeight={33}
                  onRowDoubleClick={handleSpecSelectionPageBIS}
                  disableColumnMenu
                  hideFooter
                />
              </Box>
            </>
          )}
        </>
      )}
      {specSelectionPageFlag && (
        <>
          <div>
            <div
              style={{
                textAlign: "left",
                paddingLeft: "1%",
                display: "inline-block",
                width: "15%",
              }}
            >
              <Button
                variant="contained"
                color="redtest"
                onClick={handleBackToSelectionList}
              >
                INDIETRO
              </Button>
            </div>
            <div
              style={{
                textAlign: "left",
                paddingLeft: "1%",
                display: "inline-block",
                width: "30%",
                color: "#1976d2",
              }}
            >
              <h3>{specTEMP[0].descrizione_gruppo_ospedaliero}</h3>
            </div>
            <div
              style={{
                textAlign: "left",
                paddingLeft: "1%",
                display: "inline-block",
                width: "55%",
                color: "#1976d2",
              }}
            >
              <h4>{abbinatoDownloaded?.nome_acquistato}</h4>
            </div>
          </div>
          <Box
            sx={{
              width: "100%",
              paddingTop: "0.5%",
              paddingLeft: "4%",
              paddingRight: "4%",

              "& .theme_header": {
                backgroundColor: "#1976d2",
                color: "#fff",
                fontFamily: "Calibri",
                fontSize: "16px",
              },
              "& .theme_cell": {
                fontFamily: "Calibri",
                fontSize: "17px",
                border: "1px solid #000",
              },
            }}
          >
            <DataGridPro
              columns={ABB_SELECTION_ACQ_COLUMNS}
              rows={specTEMP}
              // getRowId={(row) => row._id}
              getRowId={(row) => row._id}
              headerHeight={40}
              rowHeight={33}
              autoHeight
              {...specTEMP}
              disableColumnMenu
              hideFooter
            />
          </Box>
          <div
            style={{
              fontFamily: "Calibri",
              color: "#1976d2",
              display: "flex",
              justifyContent: "space-between",
              padding: "0 5%",
              marginTop: "1%",
            }}
          >
            <h3
              style={{
                fontFamily: "Calibri",
                color: "#1976d2",
                margin: 0,
                padding: 10,
                border: "2px solid",
                borderColor: "#1976d2",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              SPESA TOTALE:{" "}
              <span
                style={{
                  fontSize: "25px",
                }}
              >
                {RapprPrezzo(spesaTOT_SPEC)}
              </span>
            </h3>
            <h3
              style={{
                fontFamily: "Calibri",
                color: "#1976d2",
                margin: 0,
                padding: 10,
                border: "2px solid",
                borderColor: "#1976d2",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              QUANTITA' TOTALE:{" "}
              <span
                style={{
                  fontSize: "25px",
                }}
              >
                {quantitaTOT_SPEC}
              </span>
            </h3>
          </div>
          <h4 style={{ color: "#1976d2", paddingTop: "2%" }}>
            {abbinatoDownloaded?.nome_listino}
          </h4>
          <Box
            sx={{
              height: "200px",
              width: "100%",
              paddingLeft: "4%",
              paddingRight: "4%",
              paddingTop: "1%",
              "& .theme_header": {
                backgroundColor: "#1976d2",
                color: "#fff",
                fontFamily: "Calibri",
                fontSize: "16px",
              },
              "& .theme_cell": {
                fontFamily: "Calibri",
                fontSize: "17px",
                border: "1px solid #000",
              },
              "& .theme_cell_selected": {
                fontFamily: "Calibri",
                fontSize: "17px",
                backgroundColor: "#CAFEBA",
                border: "1px solid #000",
              },
            }}
          >
            <DataGridPro
              columns={ABB_SELECTION_OFFERS_COLUMNS}
              rows={specTEMP[0].cod_osp_list}
              getRowId={(row) => row.id_subTable}
              // headerHeight={0}
              headerHeight={40}
              rowHeight={33}
              onRowDoubleClick={handlePopUpChangeSelectionOpen}
              disableColumnMenu
              hideFooter
            />
          </Box>
          {specGP_TEMP && (
            <>
              <h4 style={{ color: "#1976d2", paddingTop: "2%" }}>
                GARE PUBBLICHE
              </h4>
              <Box
                sx={{
                  height: "200px",
                  width: "100%",
                  paddingTop: "1%",
                  paddingLeft: "4%",
                  paddingRight: "4%",
                  "& .theme_header": {
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    fontFamily: "Calibri",
                    fontSize: "16px",
                  },
                  "& .theme_cell_gp": {
                    fontFamily: "Calibri",
                    fontSize: "17px",
                    border: "1px solid #000",
                  },
                }}
              >
                <DataGridPro
                  columns={ABB_SELECTION_GAREPUB_COLUMNS}
                  rows={specGP_TEMP}
                  getRowId={(row) => row.id}
                  // headerHeight={0}
                  headerHeight={40}
                  rowHeight={33}
                  onRowDoubleClick={handleGPPopUp}
                  disableColumnMenu
                  hideFooter
                />
              </Box>
            </>
          )}
        </>
      )}

      {dettaglioFlag && gareSingleDetail === null && (
        <>
          <div>
            <div
              style={{
                textAlign: "left",
                paddingLeft: "0.3%",
                display: "inline-block",
                width: "10%",
              }}
            >
              <Button
                variant="contained"
                color="redtest"
                startIcon={<ArrowBackIosNewOutlinedIcon />}
                onClick={handleReturnToResumePage}
              >
                INDIETRO
              </Button>
            </div>
            <div
              style={{
                textAlign: "left",
                paddingLeft: "0.3%",
                display: "inline-block",
                width: "90%",
              }}
            ></div>
          </div>
          <div>
            <div
              style={{
                display: "inline-block",
                width: "50%",
                textAlign: "center",
              }}
            >
              {" "}
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Switch
                        checked={arrayGrupedFlag}
                        onChange={() => setArrayGrupedFlag(!arrayGrupedFlag)}
                      />
                    }
                    label={
                      <h4
                        style={{
                          fontFamily: "Calibri",
                          color: arrayGrupedFlag ? "#1976d2" : "#ccc",
                        }}
                      >
                        DETTAGLIO TIPOLOGIA
                      </h4>
                    }
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <div
              style={{
                display: "inline-block",
                width: "50%",
                textAlign: "center",
              }}
            >
              <EXP_DETTAGLIO_GP_BIS
                data={gareSelected}
                file_name={rowNameSEL}
              />
            </div>
          </div>

          {!arrayGrupedFlag ? (
            <>
              <Box
                sx={{
                  width: "100%",
                  height: "500px",
                  paddingTop: "0.5%",
                  paddingLeft: "4%",
                  paddingRight: "4%",

                  "& .theme_header": {
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    fontFamily: "Calibri",
                    fontSize: "16px",
                  },
                  "& .theme_cell": {
                    fontFamily: "Calibri",
                    fontSize: "17px",
                    border: "1px solid #000",
                  },
                  "& .theme_cell_lost": {
                    fontFamily: "Calibri",
                    backgroundColor: "#FFC7CE",
                    color: "#9C0006",
                    fontSize: "17px",
                    border: "0.5px solid rgba(0, 0, 0, 0.5)",
                  },
                  "& .theme_cell_gain": {
                    fontFamily: "Calibri",
                    backgroundColor: "#C6EFCE",
                    color: "#006100",
                    fontSize: "17px",
                    border: "0.5px solid rgba(0, 0, 0, 0.5)",
                  },
                }}
              >
                <DataGridPro
                  columns={ABB_SELECTION_ACQ_COLUMNS_BIS}
                  rows={gareSelected}
                  getRowId={(row) => row._id}
                  headerHeight={40}
                  rowHeight={33}
                  // autoHeight
                  // {...gareSelected}
                  onRowDoubleClick={handleSingleDetail}
                  disableColumnMenu
                  hideFooter
                />
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  width: "100%",
                  height: "500px",
                  paddingTop: "0.5%",
                  paddingLeft: "4%",
                  paddingRight: "4%",

                  "& .theme_header": {
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    fontFamily: "Calibri",
                    fontSize: "16px",
                  },
                  "& .theme_cell": {
                    fontFamily: "Calibri",
                    fontSize: "17px",
                    border: "1px solid #000",
                  },
                  "& .theme_cell_lost": {
                    fontFamily: "Calibri",
                    backgroundColor: "#FFC7CE",
                    color: "#9C0006",
                    fontSize: "17px",
                    border: "0.5px solid rgba(0, 0, 0, 0.5)",
                  },
                  "& .theme_cell_gain": {
                    fontFamily: "Calibri",
                    backgroundColor: "#C6EFCE",
                    color: "#006100",
                    fontSize: "17px",
                    border: "0.5px solid rgba(0, 0, 0, 0.5)",
                  },
                }}
              >
                <DataGridPro
                  columns={GARE_GROUPED_ACQ_COLUMNS_BIS}
                  rows={arrayGrupedTEMP}
                  getRowId={(row) => row.id_temp}
                  headerHeight={40}
                  rowHeight={33}
                  // autoHeight
                  // {...arrayGrupedTEMP}
                  onRowDoubleClick={handleSingleDetailGrouped}
                  disableColumnMenu
                  hideFooter
                />
              </Box>
            </>
          )}
        </>
      )}
      {dettaglioFlag && gareSingleDetail !== null && (
        <>
          <div>
            <div
              style={{
                textAlign: "left",
                paddingLeft: "0.3%",
                display: "inline-block",
                width: "10%",
              }}
            >
              <Button
                variant="contained"
                color="redtest"
                startIcon={<ArrowBackIosNewOutlinedIcon />}
                onClick={handleReturnToGareDetails}
              >
                INDIETRO
              </Button>
            </div>
            <div
              style={{
                textAlign: "left",
                paddingLeft: "0.3%",
                display: "inline-block",
                width: "90%",
              }}
            ></div>
          </div>

          <Box
            sx={{
              width: "100%",
              paddingTop: "0.5%",
              paddingLeft: "4%",
              paddingRight: "4%",

              "& .theme_header": {
                backgroundColor: "#1976d2",
                color: "#fff",
                fontFamily: "Calibri",
                fontSize: "16px",
              },
              "& .theme_cell": {
                fontFamily: "Calibri",
                fontSize: "17px",
                border: "1px solid #000",
              },
              "& .theme_cell_lost": {
                fontFamily: "Calibri",
                backgroundColor: "#FFC7CE",
                color: "#9C0006",
                fontSize: "17px",
                border: "0.5px solid rgba(0, 0, 0, 0.5)",
              },
              "& .theme_cell_gain": {
                fontFamily: "Calibri",
                backgroundColor: "#C6EFCE",
                color: "#006100",
                fontSize: "17px",
                border: "0.5px solid rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <DataGridPro
              columns={ABB_SELECTION_ACQ_COLUMNS_NODIFF_BIS}
              rows={gareSingleDetail}
              getRowId={(row) => row._id}
              headerHeight={40}
              rowHeight={33}
              autoHeight
              {...gareSingleDetail}
              onRowDoubleClick={handleSingleDetail}
              disableColumnMenu
              hideFooter
            />
          </Box>
          <div
            style={{
              fontFamily: "Calibri",
              color: "#1976d2",
              display: "flex",
              justifyContent: "space-between",
              padding: "0 5%",
              marginTop: "1%",
            }}
          >
            <h3
              style={{
                fontFamily: "Calibri",
                color: "#1976d2",
                margin: 0,
                padding: 10,
                border: "2px solid",
                borderColor: "#1976d2",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              SPESA TOTALE:{" "}
              <span
                style={{
                  fontSize: "25px",
                }}
              >
                {RapprPrezzo(spesaTOT)}
              </span>
            </h3>
            <h3
              style={{
                fontFamily: "Calibri",
                color: "#1976d2",
                margin: 0,
                padding: 10,
                border: "2px solid",
                borderColor: "#1976d2",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              QUANTITA' TOTALE:{" "}
              <span
                style={{
                  fontSize: "25px",
                }}
              >
                {quantitaTOT}
              </span>
            </h3>
          </div>

          <Box
            sx={{
              width: "100%",
              height: "350px",
              marginTop: "2%",
              paddingLeft: "4%",
              paddingRight: "4%",

              "& .theme_header": {
                backgroundColor: "#1976d2",
                color: "#fff",
                fontFamily: "Calibri",
                fontSize: "16px",
              },
              "& .theme_cell": {
                fontFamily: "Calibri",
                fontSize: "17px",
                border: "1px solid #000",
              },
              "& .theme_cell_lost": {
                fontFamily: "Calibri",
                backgroundColor: "#FFC7CE",
                color: "#9C0006",
                fontSize: "17px",
                border: "0.5px solid rgba(0, 0, 0, 0.5)",
              },
              "& .theme_cell_gain": {
                fontFamily: "Calibri",
                backgroundColor: "#C6EFCE",
                color: "#006100",
                fontSize: "17px",
                border: "0.5px solid rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <DataGridPro
              columns={GARE_SELECTION_ACQ_COLUMNS}
              rows={acqSelGareList}
              getRowId={(row) => row.id}
              headerHeight={40}
              rowHeight={33}
              // autoHeight
              // {...acqSelGareList}
              onRowDoubleClick={handleGPPopUp}
              disableColumnMenu
              hideFooter
            />
          </Box>
        </>
      )}
      <EXP_MULTIPLI_POPUP
        popUpExpMultipliOpen={popUpExpMultipliOpen}
        setPopUpExpMultipliOpen={setPopUpExpMultipliOpen}
        specMatchedTOT={specMatchedTOT}
        abb_name={abbinatoDownloaded?.nome_abbinato}
      />
      <GP_DETAILS_POPUP
        popUpGaraPubOpen={popUpGaraPubOpen}
        dettaglioGaraPub={dettaglioGaraPub}
        handlePopUpGaraPubClose={handlePopUpGaraPubClose}
      />
      <CHANGE_SELECTION_POPUP
        popUpChangeSelectionOpen={popUpChangeSelectionOpen}
        handlePopUpChangeSelectionClose={handlePopUpChangeSelectionClose}
        handleDoubleClickSpecSelection={handleDoubleClickSpecSelection}
      />
      <SAVE_UPDATE_POPUP
        popUpUpdateABBOpen={popUpUpdateABBOpen}
        setPopUpUpdateABBOpen={setPopUpUpdateABBOpen}
        loadingUpdateABB={loadingUpdateABB}
        handleUpdateNewABB={handleUpdateNewABB}
      />

      <EXPS_RESUME_PRESAVE_POPUP
        expsVariPreSavePopUpOpen={expsVariPreSavePopUpOpen}
        setExpsVariPreSavePopUpOpen={setExpsVariPreSavePopUpOpen}
        abbinatoDownloaded={abbinatoDownloaded}
        specMatchedTOT={specMatchedTOT}
        VAL_OBJ={garePubBench.BENCH_TAB}
        EXP_ABB_SELECTED_TAB={EXP_ABB_SELECTED_TAB}
        EXP_ABB_CONFRONTO={EXP_ABB_CONFRONTO}
        EXP_ABB_STRUTTURE_RESUME={EXP_ABB_STRUTTURE_RESUME}
        EXP_BENCHMARKING_RESUME={EXP_BENCHMARKING_RESUME}
      />
      <EXPS_RESUME_POPUP
        expsVariPopUpOpen={expsVariPopUpOpen}
        setExpsVariPopUpOpen={setExpsVariPopUpOpen}
        abbinatoDownloaded={abbinatoDownloaded}
        specMatchedTOT={specMatchedTOT}
        VAL_OBJ={garePubBench.BENCH_TAB}
        EXP_ABB_SELECTED_TAB={EXP_ABB_SELECTED_TAB}
        EXP_ABB_CONFRONTO={EXP_ABB_CONFRONTO}
        EXP_ABB_STRUTTURE_RESUME={EXP_ABB_STRUTTURE_RESUME}
        EXP_BENCHMARKING_RESUME={EXP_BENCHMARKING_RESUME}
      />
      <BENCHMARKING_DETAILS_POPUP
        garaDetailsOpen={garaDetailsOpen}
        data={gareSelected}
        handleGaraDetailsClose={handleGaraDetailsClose}
      />
    </>
  );
};

export default SelectionMain;
