import React, { useState, useRef } from "react";

// COMPONENTS
import { boxStyles } from "../../../boxStyles/ACQUISTATO/FILEF_BOXES";

// FUNCTIONS
import { handleFile } from "../../../functions/IMPORT/FILEF_IMPORT";

// COLUMNS
import {
  FILEF_INSERTED_COLUMNS,
  FILEF_ERRORS_COLUMNS,
} from "../../../colums/FILEF_COLUMNS";

// EXPS
import EXP_FILEF_EMPTY from "../../../exports/EMPTY_FILES/EXP_FILEF_EMPTY";

// MUI ELEMENTS
import Button from "@mui/material/Button";

import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// ICONS
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

// LOADERS
import BeatLoader from "react-spinners/BeatLoader";

// OPTIONS
import filef_years from "../../../utils/filef_years.json";

const FileFInsert = ({
  yearSel,
  fileFList,
  setYearSel,
  handleRemoveFile,
  importErrors,
  setImportErrors,
  noMatch,
  setNoMatch,
  confirmedFile,
  setConfirmedFile,
  fileValutation,
  setFileValutation,
  setSavePopUpOpen,
}) => {
  const fileRef = useRef();

  const [loadingFileUpload, setLoadingFileUpload] = useState(false);
  const [noMatchFlag, setNoMatchFlag] = useState(false);

  const [controlListFLAG, setControlListFLAG] = useState(false);

  const checkYearsList = ({ nome }) =>
    nome != null && fileFList.some(({ anno }) => anno === nome);

  const handleYearSelection = (newValue) => {
    if (newValue == null) {
      setYearSel(null);
      setControlListFLAG(false);
    } else {
      setYearSel(newValue);
      setControlListFLAG(checkYearsList(newValue));
    }
  };

  return (
    <>
      <>
        {!confirmedFile && importErrors === null && (
          <>
            <div
              style={{
                marginTop: "2%",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  width: "51%",
                  textAlign: "right",
                }}
              >
                <EXP_FILEF_EMPTY />
              </div>
              <div
                style={{
                  display: "inline-block",
                  width: "2%",
                }}
              ></div>
              <div
                style={{
                  display: "inline-block",
                  width: "47%",
                  textAlign: "left",
                }}
              >
                <Button
                  variant="contained"
                  color="bluetest"
                  component="label"
                  startIcon={<BackupOutlinedIcon />}
                  disabled={yearSel === null}
                >
                  UPLOAD
                  <input
                    type="file"
                    hidden
                    accept="xlsx, xls"
                    multiple={false}
                    onChange={(e) =>
                      handleFile(
                        e,
                        setImportErrors,
                        setFileValutation,
                        setNoMatchFlag,
                        setNoMatch,
                        setConfirmedFile,
                        setLoadingFileUpload
                      )
                    }
                    ref={fileRef}
                  />
                </Button>
              </div>
            </div>

            <Autocomplete
              disablePortal
              id="region"
              options={filef_years}
              getOptionLabel={(option) => option.nome}
              sx={{
                mx: "auto",
                width: "100%",
                marginTop: "3%",
                paddingRight: "40%",
                paddingLeft: "40%",
              }}
              renderOption={(props, option, index) => {
                const key = `listItem-${index}-${option.id}`;
                return (
                  <li {...props} key={key}>
                    {option.nome}
                  </li>
                );
              }}
              renderInput={(params) => <TextField {...params} label="ANNO" />}
              value={yearSel}
              onChange={(e, newValue) => {
                handleYearSelection(newValue);
              }}
            />
            {controlListFLAG && (
              <div
                style={{
                  textAlign: "center",
                  display: "inline-block",
                  width: "100%",
                  color: "#1976d2",
                  fontSize: "1.5vw",
                  fontFamily: "Calibri",
                  marginTop: "2%",
                }}
              >
                E' già stato inserito un file con i codici File F{" "}
                <b>{yearSel?.nome}</b>
                <br />
                <b>
                  {" "}
                  L'inserimento di un nuovo file sovrascriverà quello
                  precedente!
                </b>
              </div>
            )}
            {loadingFileUpload && (
              <div style={{ textAlign: "center", paddingTop: "3%" }}>
                <BeatLoader
                  color={"#1976d2"}
                  loading={loadingFileUpload}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                  CARICAMENTO...
                </h6>
              </div>
            )}
          </>
        )}
        {!confirmedFile && importErrors !== null && (
          <>
            <div
              style={{
                display: "inline-block",
                width: "100%",
                textAlign: "left",
              }}
            >
              <Button
                variant="contained"
                color="redtest"
                onClick={handleRemoveFile}
                startIcon={<ArrowBackIosNewOutlinedIcon />}
              >
                INDIETRO
              </Button>
            </div>
            <h2 className="error-title">
              INDIVIDUATI ERRORI NEL FILE INSERITO:
            </h2>

            <Box sx={boxStyles}>
              <DataGridPro
                columns={FILEF_ERRORS_COLUMNS}
                rows={importErrors}
                getRowId={(row) => row.id_temp}
                headerHeight={40}
                rowHeight={33}
                disableColumnMenu
                hideFooter
              />
            </Box>
          </>
        )}
        {confirmedFile && importErrors === null && (
          <>
            <Box sx={boxStyles}>
              <div>
                <div
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "left",
                  }}
                >
                  <Button
                    variant="contained"
                    color="redtest"
                    onClick={handleRemoveFile}
                    startIcon={<ArrowBackIosNewOutlinedIcon />}
                  >
                    INDIETRO
                  </Button>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "right",
                  }}
                >
                  <Button
                    variant="contained"
                    color="bluetest"
                    onClick={() => setSavePopUpOpen(true)}
                    startIcon={<SaveOutlinedIcon />}
                  >
                    SALVA
                  </Button>
                </div>
              </div>
              {noMatch.length > 0 ? (
                <>
                  <div
                    style={{
                      textAlign: "left",
                      marginTop: "2%",
                    }}
                  >
                    <FormControl component="fieldset">
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="bottom"
                          control={
                            <Switch
                              checked={noMatchFlag}
                              onChange={() => setNoMatchFlag(!noMatchFlag)}
                            />
                          }
                          label={
                            <h4
                              style={{
                                fontFamily: "Calibri",
                                color: noMatchFlag ? "#1976d2" : "#ccc",
                              }}
                            >
                              NO MATCH
                            </h4>
                          }
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: "inline-block",
                      width: "50%",
                      textAlign: "right",
                    }}
                  ></div>
                </>
              )}

              {!noMatchFlag ? (
                <>
                  <div
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      width: "100%",
                      color: "#1976d2",
                      fontSize: "1.35vw",
                      fontFamily: "Calibri",
                      textShadow: "2px 2px 8px rgba(0,0,0,0.2)",
                    }}
                  >
                    Elementi Importati: <strong>{fileValutation.length}</strong>
                  </div>
                  <DataGridPro
                    columns={FILEF_INSERTED_COLUMNS}
                    rows={fileValutation}
                    getRowId={(row) => row.id_temp}
                    headerHeight={40}
                    rowHeight={33}
                    disableColumnMenu
                    hideFooter
                  />
                </>
              ) : (
                <>
                  <div
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      width: "100%",
                      color: "#1976d2",
                      fontSize: "1.35vw",
                      fontFamily: "Calibri",
                      textShadow: "2px 2px 8px rgba(0,0,0,0.2)",
                    }}
                  >
                    NO MATCH: <strong>{noMatch.length}</strong>
                  </div>
                  <DataGridPro
                    columns={FILEF_INSERTED_COLUMNS}
                    rows={noMatch}
                    getRowId={(row) => row.id_temp}
                    headerHeight={40}
                    rowHeight={33}
                    disableColumnMenu
                    hideFooter
                  />
                </>
              )}
            </Box>
          </>
        )}
      </>
    </>
  );
};

export default FileFInsert;
