import React, { useEffect, useState } from "react";
import "../../App.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoginService from "../../services/login";
import { useNavigate } from "react-router-dom";

//todo: ricontrollare grafica errori

const Login = ({ changeUserState }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({}); // Errori specifici (email/password)
  const [errorMessage, setErrorMessage] = useState(""); // Messaggio di errore generale

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const formErrors = validateLoginForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const userLog = await LoginService.login({ email, password });

      console.log("📌 UTENTE DOPO LOGIN:", userLog); // Debug

      if (!userLog || !userLog.id) {
        console.error("❌ Errore: L'oggetto userLog non contiene dati validi!");
        setErrorMessage("Errore nel login. Riprova.");
        return;
      }

      // Imposta il timer per il logout automatico dopo 12 ore
      setTimeout(() => {
        LoginService.logout();
        changeUserState(null);
      }, 1000 * 60 * 60 * 12);

      changeUserState(userLog); // Aggiorna lo stato dell'utente
      setEmail("");
      setPassword("");
      setErrors({});
      setErrorMessage("");

      console.log("✅ Utente loggato, reindirizzamento alla homepage...");
      navigate("/"); // Reindirizza alla home
    } catch (error) {
      console.error("❌ Errore durante il login:", error);

      if (error.response) {
        // Errore restituito dal backend
        if (error.response.status === 401) {
          if (error.response.data.error === "Email Non Registrata") {
            setErrors({ email: "Email non registrata" });
          } else if (error.response.data.error === "Password Errata") {
            setErrors({ password: "Password errata" });
          } else {
            setErrorMessage("Email o password errata. Riprova.");
          }
        } else {
          setErrorMessage("Errore di connessione. Riprova più tardi.");
        }
      } else {
        setErrorMessage("Errore sconosciuto. Contatta l'assistenza.");
      }
    }
  };

  useEffect(() => {
    const loggedUser = LoginService.getUser();
    if (loggedUser) {
      changeUserState(loggedUser);
    }
  }, []);

  const validateLoginForm = () => {
    const newErrors = {};
    if (!email) newErrors.email = "Inserisci la tua email";
    if (!password) newErrors.password = "Inserisci la tua password";
    return newErrors;
  };

  return (
    <div className="login">
      <Box component="form" className="formContainer" onSubmit={handleLogin}>
        <p className="loginHeader">LOG IN</p>
        <div className="inputField">
          <TextField
            id="email"
            label="Email"
            placeholder="Inserisci Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
        </div>
        <div className="inputField">
          <TextField
            id="password"
            label="Password"
            placeholder="Inserisci Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!errors.password}
            helperText={errors.password}
          />
        </div>
        <div className="submitButton">
          <Button variant="contained" color="success" type="submit">
            Conferma
          </Button>
        </div>
      </Box>

      {errorMessage && (
        <p
          style={{
            paddingTop: "2%",
            fontFamily: "Calibri",
            fontWeight: "bold",
            color: "red",
            fontSize: 23,
            textAlign: "center",
          }}
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default Login;
