import React, { useEffect, useState } from "react";
import "../../../App.css";

// SERVICES
import filefService from "../../../services/filef";
import acquistatoService from "../../../services/acquistato";

// COMPONENTS
import FileFAnalysis from "./FileFAnalysis";
import FileFInsert from "./FileFInsert";

// FUNCTIONS
// COLUMNS
// EXPS

// POPUPS
import { FILEF_INSTR_POPUP } from "../../../popup/INSTRUCTION_POPUP/FILEF_INSTR_POPUP";
import { FILEF_SAVE_CONFIRM_POPUP } from "../../../popup/ACQ_POPUP/FILEF_SAVE_CONFIRM_POPUP";

// MUI ELEMENTS
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// ICONS
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";

// LOADERS
import BeatLoader from "react-spinners/BeatLoader";

// OPTIONS

import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";

dayjs.extend(dayjsPluginUTC);

const FileFMain = ({ user }) => {
  const [confirmedFile, setConfirmedFile] = useState(false);

  const [noMatch, setNoMatch] = useState([]);

  const [fileValutation, setFileValutation] = useState([]);

  const [yearSel, setYearSel] = useState(null);

  const [instructionOpen, setInstructionOpen] = useState(false);
  const [savePopUpOpen, setSavePopUpOpen] = useState(false);
  const [saveErrorFLAG, setSaveErrorFLAG] = useState(false);

  const [loadingFinalSave, setLoadingFinalSave] = useState(false);

  const [importErrors, setImportErrors] = useState(null);

  const [insertFLAG, setInsertFLAG] = useState(true);

  const [fileFList, setFileFList] = useState([]);

  const [loadingFileFList, setLoadingFileFList] = useState(true);

  const [purchasedList, setPurchasedList] = useState([]);

  const [loadingPurchasedsList, setLoadingPurchasedsList] = useState(true);
  const [ACQ_Selected, setACQ_Selected] = useState(null);
  const [SingleACQ_Downloaded, setSingleACQ_Downloaded] = useState(null);

  const [loadingSinglePurchased, setLoadingSinglePurchased] = useState(false);

  useEffect(() => {
    if (!user.meshRoom) return;

    filefService
      .getFileFByMeshRoomId(user.meshRoom)
      .then((res) => {
        setFileFList(res.data);
        setLoadingFileFList(false);
      })
      .catch((error) => {
        console.error("Errore durante il recupero degli acquistati:", error);
      });

    acquistatoService
      .getAcquistatoByMeshRoomId(user.meshRoom)
      .then((res) => {
        setPurchasedList(res.data);
        setLoadingPurchasedsList(false);
      })
      .catch((error) => {
        console.error("Errore durante il recupero degli acquistati:", error);
      });
  }, [user.meshRoom]);

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  const handleSavePopUpClose = () => {
    setSavePopUpOpen(false);
    setLoadingFinalSave(false);
    setSaveErrorFLAG(false);
  };

  const createArrayAICFileF = (OBJ_TEMP) => {
    const uniqueAicFarmadati = [
      ...new Set(OBJ_TEMP.map((item) => item?.aic_farmadati).filter(Boolean)),
    ];

    return uniqueAicFarmadati;
  };

  const handleConfirmSave = async () => {
    setLoadingFinalSave(true);

    if (!user.meshRoom) {
      console.error("❌ Nessuna MeshRoom associata all'utente!");
      setSaveErrorFLAG(true);
      setLoadingFinalSave(false);
      return;
    }

    const FILE_VAL_TEMP = [...fileValutation];

    const noMatchRemoved = await FILE_VAL_TEMP.filter(
      (SEL) => SEL?.aic_farmadati !== "NO MATCH"
    );

    const array_aic_filef = await createArrayAICFileF(noMatchRemoved);

    let finalToSave = {
      anno: yearSel.nome,
      obj_file: array_aic_filef,
      data_salvataggio: dayjs()
        .utc()
        .add(1, "hour")
        .local()
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    };

    try {
      filefService.setToken(user.token);

      await filefService.salvaFileF(user.meshRoom, finalToSave); // 🔹 Ora passiamo la MeshRoom!

      setFileValutation([]);
      setNoMatch([]);
      setConfirmedFile(false);
      setSaveErrorFLAG(false);
      handleSavePopUpClose();
      setYearSel(null);
    } catch (error) {
      console.error("❌ Errore durante il salvataggio del file:", error);
      setSaveErrorFLAG(true);
    } finally {
      setLoadingFinalSave(false);
    }
  };

  const handleRemoveFile = () => {
    setYearSel(null);
    setFileValutation([]);
    setNoMatch([]);
    setSaveErrorFLAG(false);
    setConfirmedFile(false);
    setImportErrors(null);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="acqPage">
          {loadingFileFList ? (
            <div style={{ textAlign: "center", paddingTop: "3%" }}>
              <BeatLoader
                color={"#1976d2"}
                loading={loadingFileFList}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                CARICAMENTO...
              </h6>
            </div>
          ) : (
            <>
              <div
                style={{
                  marginBottom: "1%",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    width: "100%",
                    textAlign: "right",
                  }}
                >
                  <Button
                    variant="contained"
                    color="bluetest"
                    onClick={() => setInsertFLAG(!insertFLAG)}
                    startIcon={insertFLAG ? <ListAltIcon /> : <AddCircleIcon />}
                  >
                    {insertFLAG ? "ANALISI FILE F" : "INSERIMENTO FILE F"}
                  </Button>
                </div>
              </div>

              {insertFLAG ? (
                <>
                  <FileFInsert
                    yearSel={yearSel}
                    fileFList={fileFList}
                    setYearSel={setYearSel}
                    handleRemoveFile={handleRemoveFile}
                    importErrors={importErrors}
                    setImportErrors={setImportErrors}
                    noMatch={noMatch}
                    setNoMatch={setNoMatch}
                    confirmedFile={confirmedFile}
                    setConfirmedFile={setConfirmedFile}
                    fileValutation={fileValutation}
                    setFileValutation={setFileValutation}
                    setSavePopUpOpen={setSavePopUpOpen}
                  />
                </>
              ) : (
                <>
                  <FileFAnalysis
                    purchasedList={purchasedList}
                    loadingPurchasedsList={loadingPurchasedsList}
                    setACQ_Selected={setACQ_Selected}
                    ACQ_Selected={ACQ_Selected}
                    setSingleACQ_Downloaded={setSingleACQ_Downloaded}
                    SingleACQ_Downloaded={SingleACQ_Downloaded}
                    loadingSinglePurchased={loadingSinglePurchased}
                    setLoadingSinglePurchased={setLoadingSinglePurchased}
                    fileFList={fileFList}
                  />
                </>
              )}
            </>
          )}
        </div>

        <FILEF_INSTR_POPUP
          instructionOpen={instructionOpen}
          setInstructionOpen={setInstructionOpen}
        />

        <FILEF_SAVE_CONFIRM_POPUP
          savePopUpOpen={savePopUpOpen}
          handleSavePopUpClose={handleSavePopUpClose}
          yearSel={yearSel}
          loadingFinalSave={loadingFinalSave}
          handleConfirmSave={handleConfirmSave}
          saveErrorFLAG={saveErrorFLAG}
        />
      </ThemeProvider>
    </>
  );
};

export default FileFMain;
