export const roundTwo = (value) => Math.round(value * 100) / 100;

//*CALOLCO COSTO (2 cifre significative)*//
export const calcoloCosto = (Prezzo, Quantita) => {
  if (Prezzo == null || Quantita == null) return 0; // Controllo null e undefined
  const costo = Prezzo * Quantita;
  return roundTwo(costo);
};

export const calcoloCostoconfezione = (Prezzo, N_Unita) => {
  if (Prezzo == null || N_Unita == null) return 0; // Controllo null e undefined
  const costo = Prezzo * N_Unita;
  return roundTwo(costo);
};

export const calcoloDifferenzaCosto = (CostoACQ, CostoOffSel) => {
  const diff_temp = CostoOffSel - CostoACQ;
  return roundTwo(diff_temp);
};

export const calcoloPercentuale = (NumPerc, Totale) => {
  const PERC_TEMP = (NumPerc / Totale) * 100;
  return roundTwo(PERC_TEMP);
};

export const calcoloDifferenzaPerc = (Costo, Differenza) => {
  if (Costo === 0) return 0;
  const diff_temp = (Differenza / Costo) * 100;
  return roundTwo(diff_temp);
};

//*RAPPRESENTA PREZZO ("." per le migliaia e "," per i decimali + "€")*//
export const RapprPrezzo = (PrezzoDaRappresentare) => {
  if (PrezzoDaRappresentare === "") {
    return PrezzoDaRappresentare;
  } else {
    let IntDec = PrezzoDaRappresentare.toString();
    IntDec = IntDec.split(".");

    let nuovoVal = "";
    let vecchioVal = IntDec[0];

    while (vecchioVal?.length > 3) {
      nuovoVal = "." + vecchioVal.substr(vecchioVal?.length - 3) + nuovoVal;
      vecchioVal = vecchioVal.substr(0, vecchioVal?.length - 3);
    }
    if (IntDec[1] !== undefined) {
      if (IntDec[1].length === 1) {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1] + "0";
      } else {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1];
      }
    } else {
      nuovoVal = vecchioVal + nuovoVal + ",00";
    }

    let finalVal = nuovoVal;

    if (finalVal.startsWith("-.")) {
      finalVal = "- " + finalVal.substr(2);
    } else if (finalVal.startsWith(".")) {
      finalVal = finalVal.substr(1);
    }

    return finalVal + " €";
  }
};

//*RAPPRESENTA QUANTITA ("." per le migliaia*//
//TODO creata al volo, da sistemare
export const RapprQuantita = (PrezzoDaRappresentare) => {
  if (PrezzoDaRappresentare === "") {
    return PrezzoDaRappresentare;
  } else {
    let IntDec = PrezzoDaRappresentare.toString();
    IntDec = IntDec.split(".");

    let nuovoVal = "";
    let vecchioVal = IntDec[0];

    while (vecchioVal?.length > 3) {
      nuovoVal = "." + vecchioVal.substr(vecchioVal?.length - 3) + nuovoVal;
      vecchioVal = vecchioVal.substr(0, vecchioVal?.length - 3);
    }
    if (IntDec[1] !== undefined) {
      if (IntDec[1].length === 1) {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1] + "0";
      } else {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1];
      }
    } else {
      // nuovoVal = vecchioVal + nuovoVal + ",00";
      nuovoVal = vecchioVal + nuovoVal;
    }

    let finalVal = nuovoVal;

    if (finalVal.startsWith("-.")) {
      finalVal = "- " + finalVal.substr(2);
    } else if (finalVal.startsWith(".")) {
      finalVal = finalVal.substr(1);
    }

    // return finalVal + " €";
    return finalVal;
  }
};
