import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ABBINATO_SEARCH_POPUP = (props) => {
  const {
    searchPopupOpen,
    setSearchPopupOpen,
    abb_search_options,
    searchValue,
    setSearchValue,
    handleSearch,
    handleSearchPrezziMultipli,
    notFoundFlag,
    selectedField,
    fieldSuggestions,
    handleFieldChange,
    searchedFlag,
    handleResetSearch,
    suppListNEW,
    selectSuppTEMP,
    handleSuppTEMPChange,
    filterDataBySelectedSupplier,
  } = props;

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="acqPage">
          <Dialog
            open={searchPopupOpen}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="md"
            keepMounted
            onClose={() => setSearchPopupOpen(false)}
            PaperProps={{
              style: {
                backgroundColor: "#f4f4f4",
                border: "1px solid #ccc",
                borderRadius: "10px",
                minHeight: "450px",
              },
            }}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <Autocomplete
                  disablePortal
                  id="search-field"
                  options={abb_search_options}
                  getOptionLabel={(option) => option.name}
                  sx={{
                    mx: "auto",
                    width: "100%",
                    paddingTop: "10%",
                    paddingRight: "30%",
                    paddingLeft: "30%",
                  }}
                  renderOption={(props, option, index) => {
                    const key = `listItem-${index}-${option.id}`;
                    return (
                      <li {...props} key={key}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="CAMPO" />
                  )}
                  value={selectedField}
                  onChange={handleFieldChange}
                />

                <Autocomplete
                  disablePortal
                  id="search-value"
                  options={fieldSuggestions}
                  getOptionLabel={(option) => option.toString()}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  sx={{
                    mx: "auto",
                    width: "100%",
                    paddingTop: "2%",
                    paddingRight: "30%",
                    paddingLeft: "30%",
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Cerca..." />
                  )}
                  value={searchValue}
                  onChange={(e, newValue) => setSearchValue(newValue)}
                />
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "1%",
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<SearchIcon />}
                  onClick={handleSearch}
                >
                  CERCA
                </Button>
              </div>
              {searchedFlag && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "1%",
                  }}
                >
                  <Button
                    variant="contained"
                    color="redtest"
                    startIcon={<RestartAltIcon />}
                    onClick={handleResetSearch}
                  >
                    RESET CERCA
                  </Button>
                </div>
              )}

              {notFoundFlag && (
                <>
                  <div
                    style={{
                      fontFamily: "Calibri",
                      fontSize: 22,
                      color: "red",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    NESSUN ELEMENTO TROVATO
                  </div>
                </>
              )}
              <div
                style={{
                  textAlign: "center",
                  marginTop: "2%",
                }}
              >
                <Button
                  variant="contained"
                  color="bluetest"
                  onClick={handleSearchPrezziMultipli}
                >
                  MOSTRA SOLO PREZZI MULTILPLI
                </Button>
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "2%",
                }}
              >
                <Autocomplete
                  disablePortal
                  id="search-field"
                  options={suppListNEW}
                  getOptionLabel={(option) => option?.name}
                  sx={{
                    mx: "auto",
                    width: "100%",
                    marginTop: "5%",
                    marginBottom: "1%",

                    paddingRight: "30%",
                    paddingLeft: "30%",
                  }}
                  renderOption={(props, option, index) => {
                    const key = `listItem-${index}-${option.id}`;
                    return (
                      <li {...props} key={key}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="FORNITORE" />
                  )}
                  value={selectSuppTEMP}
                  onChange={handleSuppTEMPChange}
                />
                <Button
                  variant="contained"
                  color="bluetest"
                  onClick={() => filterDataBySelectedSupplier(selectSuppTEMP)}
                  disabled={!selectSuppTEMP}
                >
                  FILTRO FORNITORI
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </ThemeProvider>
    </>
  );
};

export default ABBINATO_SEARCH_POPUP;
