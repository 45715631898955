import React, { useEffect, useState } from "react";
import "../App.css";
import { Routes, Route, useNavigate } from "react-router-dom";

import LayoutUser from "./Users/LayoutUser";
import LayoutLogin from "./Login/LayoutLogin";
import LoginService from "../services/login"; // Servizio per autenticazione

// Pagine principali
import Login from "./MainPages/Login";
import ChangePassword from "./MainPages/ChangePassword";
import HomePageLogin from "./MainPages/HomePageLogin";
import HomePage from "./MainPages/HomePage";
import InfoPage from "./MainPages/InfoPage";
import InfoContributoPage from "./MainPages/InfoContributoPage";

//*** SEZIONE ACQUISTATO ***//
import PurchasedMain from "../components/ACQUISTATO_NEW/GESTIONE_ACQUISTATI/PurchasedMain";
import FabbMain from "../components/ACQUISTATO_NEW/CALCOLO_FABBISOGNO/FabbMain";
import PurchasedBenchmarking from "../components/ACQUISTATO_NEW/BENCHMARKING_ACQ/PurchasedBenchmarking";
import FileFMain from "../components/ACQUISTATO_NEW/FILE_F_INSERIMENTO/FileFMain";

//*** SEZIONE OFFERTE ***//
import OfferInsertMain from "../components/OFFERTE/OfferInsertMain";
import OffersListMain from "../components/LISTINO/OffersListMain";
import OffersAnalysisMain from "../components/OFFERTE/ANALISI_OFF/OffersAnalysisMain";

//*** SEZIONE ABBINATO / ENRICO ***//
import AbbinatoMain from "../components/ENRICO/ABBINATO/AbbinatoMain";
// import AbbinatoMain_Minimo from "../components/ENRICO_MIN/ABBINATO/AbbinatoMain_Minimo";
// import IpotesiRisp from "../components/ENRICO/IpotesiRisp";
// import EnricoFinal from "../components/ENRICO/EnricoFinal";
// import FILEF_TEMP from "../components/ENRICO/FILEF_TEMP";

// *** SEZIONE ADMIN (DISATTIVATA AL MOMENTO) ***//
// import GPImport from "../components/GARE_PUB/GPImport";

const MenuBar = () => {
  const [user, setUser] = useState(LoginService.getUser()); // Recupera utente loggato al caricamento
  const navigate = useNavigate();

  // Funzione per aggiornare lo stato dell'utente dopo login/logout
  const changeUserState = (newUser) => {
    console.log("🔄 Cambiando stato utente:", newUser); // Debug
    if (newUser) {
      setUser(newUser);
      localStorage.setItem("user", JSON.stringify(newUser)); // 🔹 Salviamo anche qui per sicurezza
    }
  };

  // Funzione di logout
  const handleLogout = () => {
    LoginService.logout(); // Rimuove token e dati utente
    setUser(null);
    navigate("/login"); // Reindirizza alla login
  };

  // Controlla se l'utente è loggato al caricamento della pagina
  useEffect(() => {
    const storedUser = LoginService.getUser();
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  return (
    <>
      <Routes>
        {/* Se l'utente è loggato */}
        {user ? (
          <>
            <Route
              path="/"
              element={<LayoutUser user={user} handleLogout={handleLogout} />}
            >
              <Route index element={<HomePage user={user} />} />
              <Route path="info" element={<InfoPage user={user} />} />
              <Route path="cambio-password" element={<ChangePassword />} />

              {/* *** SEZIONE ACQUISTATO *** */}
              <Route
                path="acquistato"
                element={<PurchasedMain user={user} />}
              />
              <Route
                path="acquistato-fabbisogno"
                element={<FabbMain user={user} />}
              />
              <Route
                path="acquistato-benchmarking"
                element={<PurchasedBenchmarking user={user} />}
              />
              <Route
                path="acquistato-file-f"
                element={<FileFMain user={user} />}
              />

              {/* *** SEZIONE OFFERTE *** */}
              <Route
                path="offerta-inserimento-nuovo"
                element={<OfferInsertMain user={user} />}
              />
              <Route
                path="offerte-visualizza-nuovo"
                element={<OffersListMain user={user} />}
              />
              <Route
                path="offerte-controllo-nuovo"
                element={<OffersAnalysisMain user={user} />}
              />

              {/* *** SEZIONE ABBINATO / ENRICO *** */}
              <Route
                path="abbinamento-main"
                element={<AbbinatoMain user={user} />}
              />
              {/* <Route
                path="abbinamento-main-minimo"
                element={<AbbinatoMain_Minimo user={user} />}
              /> */}
              {/* <Route
                path="ipotesi-risparmio"
                element={<IpotesiRisp user={user} />}
              /> */}
              {/* <Route path="enrico" element={<EnricoFinal user={user} />} /> */}
              {/* <Route path="filef" element={<FILEF_TEMP user={user} />} /> */}

              {/* *** ADMIN (DISATTIVATA) *** */}
              {/* <Route path="gare-pubbliche" element={<GPImport />} /> */}

              {/* Pagina 404 */}
              <Route path="*" element={<p>Pagina non trovata!</p>} />
            </Route>
          </>
        ) : (
          <>
            {/* Se l'utente NON è loggato */}
            <Route path="/" element={<LayoutLogin />}>
              <Route index element={<HomePageLogin user={user} />} />
              <Route path="info" element={<InfoContributoPage user={user} />} />
              <Route
                path="login"
                element={<Login changeUserState={changeUserState} />}
              />
              <Route path="*" element={<p>Pagina non trovata!</p>} />
            </Route>
          </>
        )}
      </Routes>
    </>
  );
};

export default MenuBar;
