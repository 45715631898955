import axios from "axios";

// Se sviluppo
// const urlAcquistato = "http://localhost:3001/api/purchased";
// Se produzione
const urlAcquistato = "/api/purchased";

let token = null;

const setToken = (newToken) => {
  token = "bearer " + newToken;
  console.log("TOKEN SETTATO:", token); // 📌 Verifica se il token viene impostato correttamente
};

// 📌 Modificato: ora recupera gli acquistati dalla MeshRoom
const getAcquistatoByMeshRoomId = (meshRoomId) => {
  return axios.get(`${urlAcquistato}/meshroom/${meshRoomId}`);
};

const getAcquistatoByPurchasedId = (purchasedId) => {
  return axios.get(`${urlAcquistato}/singlepurchased/${purchasedId}`);
};

// 📌 Modificato: ora salva l'acquistato nella MeshRoom
const salvaAcquistato = async (meshRoomId, newACQ) => {
  try {
    const config = {
      headers: { Authorization: token },
    };
    const res = await axios.post(
      `${urlAcquistato}/${meshRoomId}`,
      newACQ,
      config
    );
    return res;
  } catch (error) {
    console.error(
      "Errore nella richiesta POST:",
      error.response?.data || error.message
    );
    return "error";
  }
};

const acquistatoService = {
  getAcquistatoByMeshRoomId,
  getAcquistatoByPurchasedId,
  salvaAcquistato,
  setToken,
};

export default acquistatoService;
