import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const EXP_OFF_CONFRONTO_DATI = ({ data, fornitore }) => {
  const [loadingEXPData, setLoadingEXPData] = useState(false);

  const createDownLoadData = async () => {
    setLoadingEXPData(true);
    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "CONFRONTO FARMADATI - " + fornitore.ragione_sociale + ".xlsx"
      );
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      setLoadingEXPData(false);
    } catch (error) {
      // console.log("Si è verificato un errore durante l'esportazione: ", error);
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = () => {
    let table1 = [
      {
        A: "AIC",
        B: "NOME COMMERCIALE",
        C: "NOME COMMERCIALE FARMADATI",
        D: "ATC",
        E: "ATC FARMADATI",
        F: "ATC COMP.",
        G: "ATC COMP. FARMADATI",
        H: "DESCRIZIONE ATC",
        I: "DESCRIZIONE ATC FARMADATI",
        J: "PREZZO UNITARIO",
      },
    ];

    let valNOMATCH = [];
    let rowNum = 0;

    data.forEach((row) => {
      if (row?.aic_farmadati === "NO MATCH") {
        valNOMATCH.push(rowNum + 2);
      }

      table1.push({
        A: row?.aic,
        B: row?.nome_commerciale,
        C: row?.nome_commerciale_farmadati,
        D: row?.atc,
        E: row?.atc_farmadati,
        F: row?.atc_complementare,
        G: row?.atc_complementare_farmadati,
        H: row?.descrizione_atc,
        I: row?.descrizione_atc_farmadati,
        J: row?.prezzo_unitario,
      });

      rowNum = rowNum + 1;
    });

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "CONFRONTO FARMADATI");

    const workbookBlob = workbook2blob(wb);

    const dataInfo = {
      theadRange: `A1:J1`,
      valNOMATCH: valNOMATCH,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Calibri",
          horizontalAlignment: "center",
          border: true,
        });

        sheet.column("A").width(14);
        sheet.column("B").width(40).style({ horizontalAlignment: "left" });
        sheet.column("C").width(40).style({ horizontalAlignment: "left" });
        sheet.column("D").width(14);
        sheet.column("E").width(16);
        sheet.column("F").width(16);
        sheet.column("G").width(22);
        sheet.column("H").width(30).style({ horizontalAlignment: "left" });
        sheet.column("I").width(35).style({ horizontalAlignment: "left" });
        sheet
          .column("J")
          .width(20)
          .style({ horizontalAlignment: "right", numberFormat: "#,##0.00000" });

        dataInfo.valNOMATCH.forEach((VAL) => {
          sheet.cell(VAL, 3).style({
            fontColor: "ff0000",
            bold: false,
          });
          sheet.cell(VAL, 5).style({
            fontColor: "ff0000",
            bold: false,
          });
          sheet.cell(VAL, 7).style({
            fontColor: "ff0000",
            bold: false,
          });
          sheet.cell(VAL, 9).style({
            fontColor: "ff0000",
            bold: false,
          });
        });

        if (dataInfo.theadRange) {
          sheet.range(dataInfo.theadRange).style({
            fill: "1976d2",
            fontColor: "ffffff",
            bold: false,
            horizontalAlignment: "center",
          });
        }
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
  return (
    <>
      <LoadingButton
        variant="contained"
        color="bluetest"
        loading={loadingEXPData}
        loadingPosition="start"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        EXP CONFRONTO FARMADATI
      </LoadingButton>
    </>
  );
};

export default EXP_OFF_CONFRONTO_DATI;
