import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

//Funzioni
import { calcoloCosto } from "../../functions/FUN_CALCOLI";

const EXP_ABB_STRUTTURE_RESUME = ({ data }) => {
  const [loadingEXPData, setLoadingEXPData] = useState(false);
  const [totaleStrutture, setTotaleStrutture] = useState(null);

  const calcoloDifferenzaCosto = (CostoACQ, CostoOffSel) => {
    let diff_temp = CostoOffSel - CostoACQ;
    return Math.round(diff_temp * 100) / 100;
  };

  const calcoloDifferenzaPerc = (Costo, Differenza) => {
    if (Costo === 0) return 0;
    let diff_temp = (Differenza / Costo) * 100;
    return Math.round(diff_temp * 100) / 100;
  };

  const createDownLoadData = async () => {
    setLoadingEXPData(true);

    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "RIASSUNTO STRUTTURE - " + data.nome_abbinato + ".xlsx"
      );
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      setLoadingEXPData(false);
    } catch (error) {
      console.log("Si è verificato un errore durante l'esportazione: ", error);
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const separaPerStruttura = (array) => {
    return array.reduce((acc, item) => {
      const key = item.struttura || "null_or_empty"; // Usare 'null_or_empty' per valori nulli o stringhe vuote
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  };

  const calcolaCostoACQTOT = (array) => {
    let TOT_ACQ = 0;
    array.forEach((SEL) => {
      if (SEL.flag_match) {
        TOT_ACQ = TOT_ACQ + calcoloCosto(SEL.prezzo_unitario, SEL.quantita);
      }
    });
    return Math.round(TOT_ACQ * 100) / 100;
  };

  const calcolaCostoABBTOT = (array) => {
    let TOT_ABB = 0;
    array.forEach((SEL) => {
      if (SEL.flag_match) {
        TOT_ABB =
          TOT_ABB +
          calcoloCosto(SEL.cod_osp_list[0].prezzo_unitario, SEL.quantita);
      }
    });
    return Math.round(TOT_ABB * 100) / 100;
  };

  const calcolaCostoSELTOT = (array) => {
    let TOT_SEL = 0;
    array.forEach((SEL) => {
      if (SEL.flag_match) {
        TOT_SEL =
          TOT_SEL +
          calcoloCosto(SEL.spec_checked.prezzo_unitario, SEL.quantita);
      }
    });
    return Math.round(TOT_SEL * 100) / 100;
  };

  const calcolaCostoGARE_ARRAY = (array) => {
    let SEL_GareSI = [];
    let SEL_GareNO = [];

    array.forEach((SEL) => {
      if (SEL.flag_match) {
        if (SEL.flag_match) {
          if (SEL?.garePubList?.length > 0) {
            SEL_GareSI.push(SEL);
          } else {
            SEL_GareNO.push(SEL);
          }
        }
      }
    });

    let GP_MIN_spesaGP = 0;
    let GP_MAG_spesaGP = 0;
    let GP_EQ_spesaGP = 0;

    let GP_MIN_spesaABB = 0;
    let GP_MAG_spesaABB = 0;
    let GP_EQ_spesaABB = 0;

    let spesa_TOT = 0;
    let risparmio_TOT = 0;

    SEL_GareSI.map((SEL_SI) => {
      let spesaGP_TEMP = calcoloCosto(
        SEL_SI?.garePubList[0]?.prezzo_unitario,
        SEL_SI?.quantita
      );

      let spesaABB_TEMP = calcoloCosto(
        SEL_SI?.spec_checked?.prezzo_unitario,
        SEL_SI?.quantita
      );

      let diffSpesa_TEMP = calcoloDifferenzaCosto(spesaABB_TEMP, spesaGP_TEMP);

      if (diffSpesa_TEMP > 0) {
        GP_MAG_spesaGP = GP_MAG_spesaGP + spesaGP_TEMP;
        GP_MAG_spesaABB = GP_MAG_spesaABB + spesaABB_TEMP;
        spesa_TOT = spesa_TOT + diffSpesa_TEMP;
      } else if (diffSpesa_TEMP < 0) {
        GP_MIN_spesaGP = GP_MIN_spesaGP + spesaGP_TEMP;
        GP_MIN_spesaABB = GP_MIN_spesaABB + spesaABB_TEMP;
        risparmio_TOT = risparmio_TOT + diffSpesa_TEMP;
      } else if (diffSpesa_TEMP === 0) {
        GP_EQ_spesaGP = GP_EQ_spesaGP + spesaGP_TEMP;
        GP_EQ_spesaABB = GP_EQ_spesaABB + spesaABB_TEMP;
      }

      return SEL_GareSI;
    });

    let spesaABB_TEMP = 0;

    SEL_GareNO.map((SEL_NO) => {
      spesaABB_TEMP =
        spesaABB_TEMP +
        calcoloCosto(SEL_NO?.spec_checked?.prezzo_unitario, SEL_NO?.quantita);

      return SEL_GareNO;
    });

    return {
      GARE_PUB_MIN: risparmio_TOT,
      GARE_PUB_MAGG: spesa_TOT,
      TOT_GARE: GP_MIN_spesaGP + GP_MAG_spesaGP + GP_EQ_spesaGP,
      TOT_ABB_GARE: GP_MIN_spesaABB + GP_MAG_spesaABB + GP_EQ_spesaABB,
    };
  };

  const createTOTFinal = (OBJ) => {
    let costoACQ_TOT = 0;
    let costoABB_TOT = 0;
    let costoSEL_TOT = 0;
    let TOT_ABB_GARE_TOT = 0;
    let TOT_GARE_TOT = 0;
    let GP_MAG_TOT = 0;
    let GP_MIN_TOT = 0;

    OBJ.forEach((SEL) => {
      costoACQ_TOT = costoACQ_TOT + SEL.costoACQ;
      costoABB_TOT = costoABB_TOT + SEL.costoABB;
      costoSEL_TOT = costoSEL_TOT + SEL.costoSEL;
      TOT_ABB_GARE_TOT = TOT_ABB_GARE_TOT + SEL.TOT_ABB_GARE;
      TOT_GARE_TOT = TOT_GARE_TOT + SEL.TOT_GARE;
      GP_MAG_TOT = GP_MAG_TOT + SEL.GP_MAG;
      GP_MIN_TOT = GP_MIN_TOT + SEL.GP_MIN;
    });

    let temp = {
      A: "TOTALE",
      B: costoACQ_TOT,
      C: costoABB_TOT,
      D: calcoloDifferenzaCosto(costoACQ_TOT, costoABB_TOT),
      E: calcoloDifferenzaPerc(
        costoABB_TOT,
        calcoloDifferenzaCosto(costoACQ_TOT, costoABB_TOT)
      ),
      F: costoSEL_TOT,
      G: calcoloDifferenzaCosto(costoACQ_TOT, costoSEL_TOT),
      H: calcoloDifferenzaPerc(
        costoSEL_TOT,
        calcoloDifferenzaCosto(costoACQ_TOT, costoSEL_TOT)
      ),
      I: TOT_ABB_GARE_TOT,
      J: TOT_GARE_TOT,
      K: calcoloDifferenzaCosto(TOT_ABB_GARE_TOT, TOT_GARE_TOT),
      L: calcoloDifferenzaPerc(
        TOT_GARE_TOT,
        calcoloDifferenzaCosto(TOT_ABB_GARE_TOT, TOT_GARE_TOT)
      ),
      M: GP_MIN_TOT,
      N: GP_MAG_TOT,
    };

    setTotaleStrutture(temp);
  };

  const handleFinalOBJ = async (specABB) => {
    const specTEMP = [...specABB];
    const StrtutureSeparate = await separaPerStruttura(specTEMP);

    const promises = Object.keys(StrtutureSeparate).map(async (key) => {
      const costoACQ_TEMP = await calcolaCostoACQTOT(StrtutureSeparate[key]);
      const costoABB_TEMP = await calcolaCostoABBTOT(StrtutureSeparate[key]);
      const costoSEL_TEMP = await calcolaCostoSELTOT(StrtutureSeparate[key]);
      const costoGARE_TEMP = await calcolaCostoGARE_ARRAY(
        StrtutureSeparate[key]
      );
      return {
        struttura: key,
        costoACQ: costoACQ_TEMP,
        costoABB: costoABB_TEMP,
        costoSEL: costoSEL_TEMP,
        //?...
        GP_MIN: costoGARE_TEMP.GARE_PUB_MIN,
        GP_MAG: costoGARE_TEMP.GARE_PUB_MAGG,
        TOT_GARE: costoGARE_TEMP.TOT_GARE,
        TOT_ABB_GARE: costoGARE_TEMP.TOT_ABB_GARE,
      };
    });

    const finalOBJTEMP = await Promise.all(promises);

    await createTOTFinal(finalOBJTEMP);

    return finalOBJTEMP;
  };

  const handleExport = async () => {
    const finalOBJ = await handleFinalOBJ(data.obj_file);

    console.log("finalOBJ", finalOBJ);

    let table1 = [
      {
        A: "STRUTTURA",
        B: "COSTO FABBISOGNO MESSO A GARA " + data?.nome_acquistato,
        C: "COSTO ESITO GARA",
        D: "DIFFERENZA",
        E: "DIFFERENZA %",
        F: "COSTO ESITO ASSEGNAZIONI REALI",
        G: "DIFFERENZA",
        H: "DIFFERENZA %",
        I: "COSTO ASSEGNAZIONI MOLECOLE INCROCIATE",
        J: "COSTO GARE PUBBLICHE",
        K: "DIFFERENZA",
        L: "DIFFERENZA %",
        M: "DIFFERENZA MOLECOLE CON PREZZI MAGGIORI",
        N: "DIFFERENZA MOLECOLE CON PREZZI MINORI",
      },
    ];
    let RispABB = [];
    let RispSEL = [];
    let RispGARE = [];
    let rispGARE_MIN = [];
    let rispGARE_MAG = [];

    let rowNum = 0;

    let tableTEMP = [];

    finalOBJ.forEach((row) => {
      tableTEMP.push({
        A: row?.struttura,
        B: row?.costoACQ,
        C: row?.costoABB,
        D: calcoloDifferenzaCosto(row?.costoACQ, row?.costoABB),
        E: calcoloDifferenzaPerc(
          row?.costoABB,
          calcoloDifferenzaCosto(row?.costoACQ, row?.costoABB)
        ),
        F: row?.costoSEL,
        G: calcoloDifferenzaCosto(row?.costoACQ, row?.costoSEL),
        H: calcoloDifferenzaPerc(
          row?.costoSEL,
          calcoloDifferenzaCosto(row?.costoACQ, row?.costoSEL)
        ),
        I: row?.TOT_ABB_GARE,
        J: row?.TOT_GARE,
        K: calcoloDifferenzaCosto(row?.TOT_ABB_GARE, row?.TOT_GARE),
        L: calcoloDifferenzaPerc(
          row?.TOT_GARE,
          calcoloDifferenzaCosto(row?.TOT_ABB_GARE, row?.TOT_GARE)
        ),
        M: row?.GP_MIN,
        N: row?.GP_MAG,
      });

      if (calcoloDifferenzaCosto(row?.costoACQ, row?.costoABB)) {
        RispABB.push(rowNum + 2);
      }
      if (calcoloDifferenzaCosto(row?.costoACQ, row?.costoSEL)) {
        RispSEL.push(rowNum + 2);
      }
      if (calcoloDifferenzaCosto(row?.TOT_ABB_GARE, row?.TOT_GARE)) {
        RispGARE.push(rowNum + 2);
      }

      rispGARE_MIN.push(rowNum + 2);
      rispGARE_MAG.push(rowNum + 2);

      rowNum = rowNum + 1;
    });

    tableTEMP.push(totaleStrutture);

    await tableTEMP.sort((a, b) => {
      let x = a.A;
      let y = b.A;

      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    table1 = table1.concat(tableTEMP);
    const wb = XLSX.utils.book_new();

    const sheet1 = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet1, "RIASSUNTO STRUTTURE");

    const workbookBlob = workbook2blob(wb);
    const dataInfo = {
      theadRange1: `A1:N1`,

      RispABB: RispABB,
      RispSEL: RispSEL,
      RispGARE: RispGARE,

      rispGARE_MIN: rispGARE_MIN,
      rispGARE_MAG: rispGARE_MAG,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets("RIASSUNTO STRUTTURE").forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Calibri",
          horizontalAlignment: "right",
          border: true,
        });

        sheet.column("A").width(18).style({ horizontalAlignment: "center" });
        sheet.column("B").width(18).style({ numberFormat: "#,##0.00" });
        sheet.column("C").width(18).style({ numberFormat: "#,##0.00" });
        sheet.column("D").width(18).style({ numberFormat: "#,##0.00" });
        sheet.column("E").width(18).style({ numberFormat: "#,##0.00" });
        sheet.column("F").width(18).style({ numberFormat: "#,##0.00" });
        sheet.column("G").width(18).style({ numberFormat: "#,##0.00" });
        sheet.column("H").width(18).style({ numberFormat: "#,##0.00" });
        sheet.column("I").width(18).style({ numberFormat: "#,##0.00" });
        sheet.column("J").width(18).style({ numberFormat: "#,##0.00" });
        sheet.column("K").width(18).style({ numberFormat: "#,##0.00" });
        sheet.column("L").width(18).style({ numberFormat: "#,##0.00" });
        sheet.column("M").width(18).style({ numberFormat: "#,##0.00" });
        sheet.column("N").width(18).style({ numberFormat: "#,##0.00" });

        dataInfo.RispABB.forEach((VAL) => {
          sheet.cell(VAL, 4).style({
            fill: "c6efce",
            fontColor: "006100",
            bold: false,
            border: true,
          });
          sheet.cell(VAL, 5).style({
            fill: "c6efce",
            fontColor: "006100",
            bold: false,
            border: true,
          });
        });

        dataInfo.RispSEL.forEach((VAL) => {
          sheet.cell(VAL, 7).style({
            fill: "c6efce",
            fontColor: "006100",
            bold: false,
            border: true,
          });
          sheet.cell(VAL, 8).style({
            fill: "c6efce",
            fontColor: "006100",
            bold: false,
            border: true,
          });
        });

        dataInfo.RispGARE.forEach((VAL) => {
          sheet.cell(VAL, 11).style({
            fill: "c6efce",
            fontColor: "006100",
            bold: false,
            border: true,
          });
          sheet.cell(VAL, 12).style({
            fill: "c6efce",
            fontColor: "006100",
            bold: false,
            border: true,
          });
        });

        dataInfo.rispGARE_MIN.forEach((VAL) => {
          sheet.cell(VAL, 13).style({
            fill: "c6efce",
            fontColor: "006100",
            bold: false,
            border: true,
          });
          // sheet.cell(VAL, 12).style({
          //   fill: "c6efce",
          //   fontColor: "006100",
          //   bold: false,
          //   border: true,
          // });
        });

        dataInfo.rispGARE_MAG.forEach((VAL) => {
          sheet.cell(VAL, 14).style({
            fill: "ffc7ce",
            fontColor: "9c0006",
            bold: false,
            border: true,
          });
          // sheet.cell(VAL, 12).style({
          //   fill: "c6efce",
          //   fontColor: "006100",
          //   bold: false,
          //   border: true,
          // });
        });

        if (dataInfo.theadRange1) {
          sheet.range(dataInfo.theadRange1).style({
            fill: "1976d2",
            fontColor: "ffffff",
            bold: false,
            horizontalAlignment: "center",
          });
        }
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <>
      <LoadingButton
        variant="contained"
        color="bluetest"
        loading={loadingEXPData}
        loadingPosition="start"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        EXP DETTAGLIO STRUTTURE
      </LoadingButton>
    </>
  );
};

export default EXP_ABB_STRUTTURE_RESUME;
