import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import User from "./User";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const HeaderNEW = (props) => {
  const { user, handleLogout } = props;
  const [userLog, setUserLog] = useState({});

  const theme = createTheme({
    palette: {
      redtest: {
        main: "#c00000",
        contrastText: "#fff",
      },
    },
  });

  const handleChangeDates = () => {
    let newUser = {
      title: user.name.toUpperCase(),
      submenu: [
        { title: "INFO", url: "info" },
        { title: "CAMBIO PASSWORD", url: "cambio-password" },
      ],
    };
    setUserLog(newUser);
  };

  useEffect(() => {
    handleChangeDates();
  }, []);

  // 🎨 STILI INLINE 🎨
  const styles = {
    navArea: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "10px 20px",
      backgroundColor: "#1976d2", // Blu come il menu
    },
    leftMenu: {
      display: "flex",
      alignItems: "center",
      gap: "20px", // Distanza tra HOME e i menu a tendina
    },
    logo: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#fff",
      textDecoration: "none",
    },
    userContainer: {
      display: "flex",
      alignItems: "center",
      gap: "10px", // Distanza tra utente e logout
    },
    logoutButton: {
      fontSize: "14px",
      fontWeight: "bold",
      padding: "6px 12px",
      textTransform: "uppercase",
    },
  };

  return (
    <header>
      <div style={styles.navArea}>
        <div style={styles.leftMenu}>
          <Link to="/" style={styles.logo}>
            HOME
          </Link>
          <Navbar /> {/* ✅ Ora i menu sono più vicini a HOME */}
        </div>
        <div style={styles.userContainer}>
          {/* ✅ Nome utente con menu dropdown */}
          <User
            items={userLog}
            key={0}
            depthLevel={0}
            user={user}
            handleLogout={handleLogout}
          />
          {/* ✅ Bottone Logout affiancato */}
          <ThemeProvider theme={theme}>
            <Button
              variant="contained"
              color="redtest"
              type="submit"
              onClick={handleLogout}
              style={styles.logoutButton}
            >
              LOG OUT
            </Button>
          </ThemeProvider>
        </div>
      </div>
    </header>
  );
};

export default HeaderNEW;
