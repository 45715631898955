import React, { useState } from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OFFER_INSTR_POPUP = ({ instructionOpen, setInstructionOpen }) => {
  const [secondPage, setSecondPage] = useState(false);

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="acqPage">
          <Dialog
            open={instructionOpen}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="lg"
            keepMounted
            onClose={() => {
              setInstructionOpen(false);
              setSecondPage(false);
            }}
            PaperProps={{
              style: {
                backgroundColor: "#f4f4f4",
                border: "1px solid #ccc",
                borderRadius: "10px",
              },
            }}
            aria-describedby="alert-dialog-slide-description"
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                setInstructionOpen(false);
                setSecondPage(false);
              }}
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                color: "#1976d2",
                zIndex: 1,
                ":hover": {
                  backgroundColor: "#1976d2",
                  color: "#fff",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogTitle
              sx={{
                fontFamily: "Calibri",
                fontWeight: "bold",
                fontSize: 26,
                color: "#1976d2",
              }}
            >
              ISTRUZIONI INSERIMENTO OFFERTA SINGOLA:
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                sx={{
                  fontFamily: "Calibri",
                  fontSize: 28,
                }}
              >
                {!secondPage ? (
                  <>
                    <ol
                      style={{
                        paddingLeft: "2%",
                        color: "#1976d2",
                      }}
                    >
                      <li>
                        Esportare il file Excel vuoto tramite il pulsante in
                        alto e compilare i relativi campi per l’inserimento dei
                        dati. <strong>IMPORTANTE:</strong> non modificare
                        l’intestazione delle colonne! <br /> I campi obbligatori
                        per garantire il corretto inserimento sono:{" "}
                        <strong>
                          “AIC”, “NOME COMMERCIALE” e “PREZZO UNITARIO”
                        </strong>
                        .
                      </li>
                      <li>
                        Compilare il form presente nella pagina con i dati
                        dell’offerta ricevuta. Qualora il fornitore non fosse
                        presente nell’elenco disponibile, segnalarlo a{" "}
                        <em>togni.roberto11@gmail.com</em> per la codifica e
                        l’inserimento nel database.
                      </li>
                      <li>
                        Caricare il file compilato e verificare l’assenza di
                        errori in ogni fase del processo:
                        <ol type="a" style={{ marginLeft: "3%" }}>
                          <li>
                            Corretta compilazione delle celle (assenza di campi
                            vuoti, lunghezza corretta dell’AIC, prezzi diversi
                            da 0, ecc.);
                          </li>
                          <li>
                            Corrispondenza tra il Nome Inserito nel file e il
                            Nome Corretto associato all’AIC indicato;
                          </li>
                          <li>
                            In caso si verificassero dei{" "}
                            <strong>NO MATCH</strong>, controllare la presenza
                            di errori nel campo AIC del file Excell caricato. Se
                            non ci fossero comunicare la lista AIC mancanti a{" "}
                            <em>togni.roberto11@gmail.com</em> per un controllo
                            e l’inserimento nel database.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </>
                ) : (
                  <>
                    <ol
                      start={4}
                      style={{
                        paddingLeft: "2%",
                        color: "#1976d2",
                      }}
                    >
                      <li>
                        <strong>Prezzo Confezione</strong> e{" "}
                        <strong>Numero Pezzi</strong> (se inseriti nel file
                        iniziale): verificare che corrispondano ai dati presenti
                        nel database e che siano coerenti con il{" "}
                        <strong>Prezzo Unitario.</strong>
                      </li>
                      <li>
                        Nella schermata finale sarà possibile confrontare le
                        singole specialità inserite con le specialità di un
                        Listino selezionato (se il fornitore in questione è
                        presente in tale Listino).
                      </li>
                      <li>
                        Al termine dei controlli, sarà possibile visualizzare ed
                        esportare un file con l’offerta corretta e codificata
                        prima del salvataggio.
                      </li>
                      <li>
                        Una volta confermato il salvataggio, sarà possibile
                        creare immediatamente un nuovo listino oppure tornare
                        alla schermata per l’inserimento di nuove offerte.
                      </li>
                    </ol>
                  </>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={secondPage ? { justifyContent: "flex-start" } : {}}
            >
              <Button
                onClick={() => setSecondPage(!secondPage)}
                sx={{
                  color: "#1976d2",
                  fontWeight: "bold",
                  ":hover": {
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    fontWeight: "bold",
                  },
                }}
              >
                {!secondPage ? <ArrowForwardIcon /> : <ArrowBackIcon />}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </ThemeProvider>
    </>
  );
};

export default OFFER_INSTR_POPUP;
