import axios from "axios";

// URL di base per il login
// Se sviluppo
// const urlLogin = "http://localhost:3001/api/login";
// Se produzione
const urlLogin = "/api/login";

const login = async (credentials) => {
  try {
    const res = await axios.post(urlLogin, credentials);

    console.log("📌 RISPOSTA DAL SERVER:", res.data); // 📌 Debug

    if (res.data.token) {
      const userData = {
        id: res.data.id,
        name: res.data.name,
        email: res.data.email,
        meshRoom: res.data.meshRoom || null, // 📌 Salviamo anche la MeshRoom
      };

      localStorage.setItem("token", res.data.token);
      localStorage.setItem("user", JSON.stringify(userData));

      console.log(
        "✅ UTENTE SALVATO IN LOCALSTORAGE:",
        localStorage.getItem("user")
      ); // 📌 Debug
    } else {
      console.error("❌ Nessun token ricevuto dal server!");
    }

    return res.data;
  } catch (error) {
    console.error(
      "❌ Errore durante il login:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// ✅ Recupera il token JWT
const getToken = () => {
  return localStorage.getItem("token");
};

// ✅ Recupera i dati dell'utente loggato
const getUser = () => {
  try {
    const user = localStorage.getItem("user");
    if (!user) {
      console.warn("⚠️ Nessun utente salvato in localStorage");
      return null;
    }
    return JSON.parse(user);
  } catch (error) {
    console.error("❌ Errore nel parsing dell'utente dal localStorage:", error);
    localStorage.removeItem("user"); // Rimuoviamo il valore corrotto
    return null;
  }
};

// ✅ Logout: Rimuove il token e i dati utente
const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  window.location.href = "/login"; // Reindirizza alla pagina di login
};

const LoginService = {
  login,
  getToken,
  getUser,
  logout,
};

export default LoginService;
