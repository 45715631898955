import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import { calcoloCosto } from "../../functions/FUN_CALCOLI";

const EXP_FILEF_FIRSTMATCH = ({ data }) => {
  console.log("data", data);

  const [loadingEXPData, setLoadingEXPData] = useState(false);

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
    },
  });

  const createDownLoadData = async () => {
    setLoadingEXPData(true);

    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", "FILE F MATCH FIRST.xlsx");
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      setLoadingEXPData(false);
    } catch (error) {
      console.log("Si è verificato un errore durante l'esportazione: ", error);
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = () => {
    let table1 = [
      {
        A: "STRUTTURA",
        B: "PRINCIPIO ATTIVO",
        C: "TIPOLOGIA",
        D: "AIC",
        E: "NOME COMMERCIALE",
        F: "ATC COMPLEMENTARE",
        G: "ATC",
        H: "FORNITORE",
        I: "P. IVA",
        J: "PREZZO",
        K: "QUANTITA'",
        L: "SPESA",
      },
    ];

    let table2 = [
      {
        A: "STRUTTURA",
        B: "PRINCIPIO ATTIVO",
        C: "TIPOLOGIA",
        D: "AIC",
        E: "NOME COMMERCIALE",
        F: "ATC COMPLEMENTARE",
        G: "ATC",
        H: "FORNITORE",
        I: "P. IVA",
        J: "PREZZO",
        K: "QUANTITA'",
        L: "SPESA",
      },
    ];

    data?.FILEF.forEach((row) => {
      table1.push({
        A: row?.struttura,
        B: row?.principio_attivo,
        C: row?.descrizione_gruppo_ospedaliero,
        D: row?.aic,
        E: row?.nome_commerciale,
        F: row?.atc_complementare,
        G: row?.atc,
        H: row?.fornitore.ragione_sociale,
        I: row?.fornitore.cf_piva,
        J: row?.prezzo_unitario,
        K: row?.quantita,
        L: calcoloCosto(row?.prezzo_unitario, row?.quantita),
      });
    });

    data?.NO_FILEF.forEach((row) => {
      table2.push({
        A: row?.struttura,
        B: row?.principio_attivo,
        C: row?.descrizione_gruppo_ospedaliero,
        D: row?.aic,
        E: row?.nome_commerciale,
        F: row?.atc_complementare,
        G: row?.atc,
        H: row?.fornitore.ragione_sociale,
        I: row?.fornitore.cf_piva,
        J: row?.prezzo_unitario,
        K: row?.quantita,
        L: calcoloCosto(row?.prezzo_unitario, row?.quantita),
      });
    });

    const wb = XLSX.utils.book_new();

    const sheet1 = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    const sheet2 = XLSX.utils.json_to_sheet(table2, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet1, "FILE F");
    XLSX.utils.book_append_sheet(wb, sheet2, "NO FILE F");

    const workbookBlob = workbook2blob(wb);

    const dataInfo = {
      theadRange1: `A1:L1`,
      theadRange2: `A1:L1`,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      let sheetsCounter = 1;
      workbook.sheets("FILE F").forEach((sheet) => {
        if (sheetsCounter === 1) {
          sheet.usedRange().style({
            fontFamily: "Calibri",
            horizontalAlignment: "left",
            border: true,
          });
          sheet.column("A").width(35);
          sheet.column("B").width(35);
          sheet.column("C").width(35);
          sheet.column("D").width(14).style({ horizontalAlignment: "center" });
          sheet.column("E").width(30);
          sheet.column("F").width(21).style({ horizontalAlignment: "center" });
          sheet.column("G").width(12).style({ horizontalAlignment: "center" });
          sheet.column("H").width(35);
          sheet.column("I").width(18).style({ horizontalAlignment: "center" });
          sheet.column("J").width(22).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });
          sheet.column("K").width(16).style({ horizontalAlignment: "right" });
          sheet
            .column("L")
            .width(14)
            .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });

          if (dataInfo.theadRange1) {
            sheet.range(dataInfo.theadRange1).style({
              fill: "1976d2",
              bold: false,
              horizontalAlignment: "center",
              fontColor: "ffffff",
            });
          }
        } else if (sheetsCounter === 2) {
          sheet.usedRange().style({
            fontFamily: "Calibri",
            horizontalAlignment: "left",
            border: true,
          });

          sheet.column("A").width(35);
          sheet.column("B").width(35);
          sheet.column("C").width(35);
          sheet.column("D").width(14).style({ horizontalAlignment: "center" });
          sheet.column("E").width(30);
          sheet.column("F").width(21).style({ horizontalAlignment: "center" });
          sheet.column("G").width(12).style({ horizontalAlignment: "center" });
          sheet.column("H").width(35);
          sheet.column("I").width(18).style({ horizontalAlignment: "center" });
          sheet.column("J").width(22).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });
          sheet.column("K").width(16).style({ horizontalAlignment: "right" });
          sheet
            .column("L")
            .width(14)
            .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });

          if (dataInfo.theadRange2) {
            sheet.range(dataInfo.theadRange2).style({
              fill: "1976d2",
              bold: false,
              horizontalAlignment: "center",
              fontColor: "ffffff",
            });
          }
        }
        sheetsCounter = sheetsCounter + 1;
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <LoadingButton
          sx={{
            marginTop: "2%",
          }}
          variant="contained"
          color="bluetest"
          loading={loadingEXPData}
          loadingPosition="start"
          startIcon={<FileDownloadOutlinedIcon />}
          onClick={() => createDownLoadData()}
        >
          EXP FILE F MATCH
        </LoadingButton>
      </ThemeProvider>
    </>
  );
};

export default EXP_FILEF_FIRSTMATCH;
