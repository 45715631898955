import garepubService from "../../services/garapub";

import {
  calcoloDifferenzaPerc,
  calcoloDifferenzaCosto,
  calcoloPercentuale,
} from "../CALCULATION/ACQ_BENCH_CALC";

import { calcoloCosto } from "../FUN_CALCOLI";

const sortByPrice = (a, b) => a.prezzo_unitario - b.prezzo_unitario;

const handleCreaFileStruttreSelezionate = (
  ACQ_LIST_TEMP,
  struttureSelected
) => {
  const nomiStrutture = new Set(
    struttureSelected.map((item) => item.nome_struttura)
  );

  return ACQ_LIST_TEMP.filter((obj) => nomiStrutture.has(obj.struttura));
};

const handleGarePubCalcolo = (Array) => {
  // Raggruppa gli elementi con e senza gare pubbliche
  const { SEL_GareSI, SEL_GareNO } = Array.reduce(
    (acc, item) => {
      if (item.garePubList?.length > 0) {
        acc.SEL_GareSI.push(item);
      } else {
        acc.SEL_GareNO.push(item);
      }
      return acc;
    },
    { SEL_GareSI: [], SEL_GareNO: [] }
  );

  // console.log("SEL_GareSI", SEL_GareSI);
  // console.log("SEL_GareNO", SEL_GareNO);

  // Calcolo metriche per Gare SI
  const {
    GP_MIN_array,
    GP_MAG_array,
    GP_EQ_array,
    GP_MIN_count,
    GP_MAG_count,
    GP_EQ_count,
    GP_MIN_spesaGP,
    GP_MAG_spesaGP,
    GP_EQ_spesaGP,
    GP_MIN_spesaACQ,
    GP_MAG_spesaACQ,
    GP_EQ_spesaACQ,
  } = SEL_GareSI.reduce(
    (acc, SEL_SI) => {
      const spesaGP_TEMP = calcoloCosto(
        SEL_SI?.garePubList[0]?.prezzo_unitario,
        SEL_SI?.quantita
      );
      const spesaACQ_TEMP = calcoloCosto(
        SEL_SI?.prezzo_unitario,
        SEL_SI?.quantita
      );
      const diffSpesa_TEMP = calcoloDifferenzaCosto(
        spesaACQ_TEMP,
        spesaGP_TEMP
      );

      if (diffSpesa_TEMP > 0) {
        acc.GP_MAG_count++;
        acc.GP_MAG_spesaGP += spesaGP_TEMP;
        acc.GP_MAG_spesaACQ += spesaACQ_TEMP;
        acc.GP_MAG_array.push(SEL_SI);
      } else if (diffSpesa_TEMP === 0) {
        acc.GP_EQ_count++;
        acc.GP_EQ_spesaGP += spesaGP_TEMP;
        acc.GP_EQ_spesaACQ += spesaACQ_TEMP;
        acc.GP_EQ_array.push(SEL_SI);
      } else {
        acc.GP_MIN_count++;
        acc.GP_MIN_spesaGP += spesaGP_TEMP;
        acc.GP_MIN_spesaACQ += spesaACQ_TEMP;
        acc.GP_MIN_array.push(SEL_SI);
      }

      return acc;
    },
    {
      GP_MIN_array: [],
      GP_MAG_array: [],
      GP_EQ_array: [],
      GP_MIN_count: 0,
      GP_MAG_count: 0,
      GP_EQ_count: 0,
      GP_MIN_spesaGP: 0,
      GP_MAG_spesaGP: 0,
      GP_EQ_spesaGP: 0,
      GP_MIN_spesaACQ: 0,
      GP_MAG_spesaACQ: 0,
      GP_EQ_spesaACQ: 0,
    }
  );

  // Calcolo spesa per Gare NO
  const spesaACQ_TEMP = SEL_GareNO.reduce(
    (total, SEL_NO) =>
      total + calcoloCosto(SEL_NO?.prezzo_unitario, SEL_NO?.quantita),
    0
  );

  // Calcolo Totali
  const TOT_SPESA_GP = GP_MIN_spesaGP + GP_MAG_spesaGP + GP_EQ_spesaGP;
  const TOT_SPESA_ACQ = GP_MIN_spesaACQ + GP_MAG_spesaACQ + GP_EQ_spesaACQ;

  // Creazione tabella finale
  const VAL_OBJ_TEMP = [
    {
      title: "PREZZI MAGGIORI",
      n_molecole: GP_MIN_count,
      spesaACQ: GP_MIN_spesaACQ.toFixed(2),
      perc_spesaACQ: calcoloPercentuale(
        GP_MIN_spesaACQ,
        spesaACQ_TEMP + TOT_SPESA_ACQ
      ),
      spesaGarePub: GP_MIN_spesaGP.toFixed(2),
      diff_spesa: (GP_MIN_spesaGP - GP_MIN_spesaACQ).toFixed(2),
      perc_diff_spesa: calcoloDifferenzaPerc(
        GP_MIN_spesaACQ,
        GP_MIN_spesaGP - GP_MIN_spesaACQ
      ),
      array_gare: GP_MIN_array,
    },
    {
      title: "PREZZI MINORI",
      n_molecole: GP_MAG_count,
      spesaACQ: GP_MAG_spesaACQ.toFixed(2),
      perc_spesaACQ: calcoloPercentuale(
        GP_MAG_spesaACQ,
        spesaACQ_TEMP + TOT_SPESA_ACQ
      ),
      spesaGarePub: GP_MAG_spesaGP.toFixed(2),
      diff_spesa: (GP_MAG_spesaGP - GP_MAG_spesaACQ).toFixed(2),
      perc_diff_spesa: calcoloDifferenzaPerc(
        GP_MAG_spesaACQ,
        GP_MAG_spesaGP - GP_MAG_spesaACQ
      ),
      array_gare: GP_MAG_array,
    },
    {
      title: "PREZZI ALLINEATI",
      n_molecole: GP_EQ_count,
      spesaACQ: GP_EQ_spesaACQ.toFixed(2),
      perc_spesaACQ: calcoloPercentuale(
        GP_EQ_spesaACQ,
        spesaACQ_TEMP + TOT_SPESA_ACQ
      ),
      spesaGarePub: GP_EQ_spesaGP.toFixed(2),
      diff_spesa: 0,
      perc_diff_spesa: 0,
      array_gare: GP_EQ_array,
    },
    {
      title: "TOTALE MOLECOLE INCROCIATE",
      n_molecole: SEL_GareSI.length,
      spesaACQ: TOT_SPESA_ACQ.toFixed(2),
      perc_spesaACQ: calcoloPercentuale(
        TOT_SPESA_ACQ,
        spesaACQ_TEMP + TOT_SPESA_ACQ
      ),
      spesaGarePub: TOT_SPESA_GP.toFixed(2),
      diff_spesa: (TOT_SPESA_GP - TOT_SPESA_ACQ).toFixed(2),
      perc_diff_spesa: calcoloDifferenzaPerc(
        TOT_SPESA_ACQ,
        TOT_SPESA_GP - TOT_SPESA_ACQ
      ),
      array_gare: [],
    },
    {
      title: "MOLECOLE NON INCROCIATE",
      n_molecole: SEL_GareNO.length,
      spesaACQ: spesaACQ_TEMP.toFixed(2),
      perc_spesaACQ: calcoloPercentuale(
        spesaACQ_TEMP,
        spesaACQ_TEMP + TOT_SPESA_ACQ
      ),
      spesaGarePub: "",
      diff_spesa: "",
      perc_diff_spesa: "",
      array_gare: [],
    },
    {
      title: "TOTALE MOLECOLE ANALIZZATE",
      n_molecole: SEL_GareSI.length + SEL_GareNO.length,
      spesaACQ: (spesaACQ_TEMP + TOT_SPESA_ACQ).toFixed(2),
      perc_spesaACQ: 100,
      spesaGarePub: "",
      diff_spesa: "",
      perc_diff_spesa: "",
      array_gare: SEL_GareNO,
    },
  ];

  return { BENCH_TAB: VAL_OBJ_TEMP };
};

const handleBenchmarkingValutation = async (
  setLoadingBenchmarkingVAL,
  SingleACQ_Downloaded,
  struttureSelected,
  setGareBenchmarking
) => {
  try {
    setLoadingBenchmarkingVAL(true);

    // Filtra le strutture selezionate
    const ACQ_LIST_SEL_TEMP = handleCreaFileStruttreSelezionate(
      SingleACQ_Downloaded.acquistato_inserito,
      struttureSelected
    );

    // Estrai i codici gruppo ospedaliero unici
    const uniqueGPOSPList = [
      ...new Set(
        ACQ_LIST_SEL_TEMP.filter(
          (item) => item.codice_gruppo_ospedaliero !== ""
        ).map((item) => item.codice_gruppo_ospedaliero)
      ),
    ];

    // Recupera le gare pubbliche corrispondenti ai codici gruppo ospedaliero
    const foundedGPOPS = await garepubService.getGPOSP(uniqueGPOSPList);

    // Crea una mappa per un accesso rapido ai dati
    const foundedGPOPSMap = Object.fromEntries(
      foundedGPOPS.map((element) => [
        element.data[0]?.codice_gruppo_ospedaliero,
        element.data.sort(sortByPrice),
      ])
    );

    // Aggiungi le gare pubbliche corrispondenti a ogni elemento selezionato
    const updatedACQ_LIST_TEMP = ACQ_LIST_SEL_TEMP.map((selected) => ({
      ...selected,
      garePubList: foundedGPOPSMap[selected.codice_gruppo_ospedaliero] || [],
    }));

    // Calcola il benchmarking delle gare pubbliche
    const GARE_PUB_BENCHMARKING = handleGarePubCalcolo(updatedACQ_LIST_TEMP);

    // Aggiorna lo stato con i dati di benchmarking
    setGareBenchmarking(GARE_PUB_BENCHMARKING);
  } catch (error) {
    console.error("Errore durante il benchmarking:", error);
  } finally {
    setLoadingBenchmarkingVAL(false);
  }
};

const handleCreateGareDetails = (ACQ_LIST, setArrayGrupedTEMP) => {
  // Ordina la lista basandosi sulla differenza di costo
  const sortedList = [...ACQ_LIST].sort((a, b) => {
    const diffA =
      calcoloCosto(a.garePubList[0]?.prezzo_unitario, a.quantita) -
      calcoloCosto(a.prezzo_unitario, a.quantita);
    const diffB =
      calcoloCosto(b.garePubList[0]?.prezzo_unitario, b.quantita) -
      calcoloCosto(b.prezzo_unitario, b.quantita);
    return diffA - diffB;
  });

  // Raggruppa i dati per descrizione_gruppo_ospedaliero
  const groupedData = sortedList.reduce((acc, current) => {
    const { descrizione_gruppo_ospedaliero } = current;
    if (!acc[descrizione_gruppo_ospedaliero]) {
      acc[descrizione_gruppo_ospedaliero] = [];
    }
    acc[descrizione_gruppo_ospedaliero].push(current);
    return acc;
  }, {});

  // Prepara l'array raggruppato con le metriche calcolate
  const ArrayGruped = Object.values(groupedData).map((group, index) => {
    const { spesaTotale, quantitaTotale } = group.reduce(
      (totals, item) => {
        totals.spesaTotale += calcoloCosto(item.prezzo_unitario, item.quantita);
        totals.quantitaTotale += item.quantita;
        return totals;
      },
      { spesaTotale: 0, quantitaTotale: 0 }
    );

    return {
      id_temp: index,
      spesa_tot: Math.round(spesaTotale * 100) / 100,
      quantita_tot: quantitaTotale,
      tipologia: group[0]?.descrizione_gruppo_ospedaliero,
      garePubList: group[0]?.garePubList,
    };
  });

  // Aggiorna lo stato con l'array raggruppato
  setArrayGrupedTEMP(ArrayGruped);

  return sortedList;
};

const handleViewDetailsTab = async (
  params,
  setArrayGrupedTEMP,
  setGareSelected,
  setRowNameSEL,
  setDettaglioFlag
) => {
  // console.log(
  //   "params",
  //   setArrayGrupedTEMP,
  //   setGareSelected,
  //   setRowNameSEL,
  //   setDettaglioFlag
  // );

  const validTitles = ["PREZZI MAGGIORI", "PREZZI MINORI", "PREZZI ALLINEATI"];

  if (validTitles.includes(params.row?.title)) {
    const NEW_OBJ = await handleCreateGareDetails(
      params.row.array_gare,
      setArrayGrupedTEMP
    );
    // console.log("NEW_OBJ", NEW_OBJ);
    setGareSelected(NEW_OBJ);
    setRowNameSEL(params.row?.title);
    setDettaglioFlag(true);
  } else if (params.row?.title === "MOLECOLE NON INCROCIATE") {
    // TODO: ADESSO NEL CAMPO array_gare è presente l'array delle molecole senza gara
    // Bisogna creare una funzione che crei una tabella corretta rispetto alle specifiche e che non mandi
    // in crash la pagina o creare una apgina nuova apposta

    console.log("CASO PARTICOLARE DA IMPLEMENTARE");
    console.log("params.row", params.row);
  } else {
    // console.log("CASO DIVERSO");
  }
};

export { handleBenchmarkingValutation, handleViewDetailsTab };
