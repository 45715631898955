import React from "react";
import "../../../App.css";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Button from "@mui/material/Button";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import AddchartIcon from "@mui/icons-material/Addchart";

import BeatLoader from "react-spinners/BeatLoader";

const AbbinatoGenerate = ({
  setFlagConfermaStrutture,
  loadingOfferList,
  offerList,
  Off_List_Selected,
  setOff_List_Selected,
  loadingFileFList,
  fileFList,
  FileF_List_Selected,
  setFileF_List_Selected,
  handleCreaAbbinato,
  loadingABBValutation,
}) => {
  return (
    <>
      <div
        style={{
          textAlign: "left",
          marginBottom: "1%",
        }}
      >
        <Button
          variant="contained"
          component="label"
          color="redtest"
          startIcon={<ArrowBackIosNewOutlinedIcon />}
          onClick={() => setFlagConfermaStrutture(false)}
        >
          INDIETRO
        </Button>
      </div>
      <div
        style={{
          textAlign: "center",
          paddingTop: "3%",
          display: "inline-block",
          width: "50%",
        }}
      >
        {loadingOfferList ? (
          <div>
            <BeatLoader
              color={"#1976d2"}
              loading={loadingOfferList}
              size={40}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <h6
              style={{
                fontFamily: "Calibri",
                color: "#1976d2",
              }}
            >
              CARICAMENTO...
            </h6>
          </div>
        ) : (
          <>
            <p
              style={{
                fontFamily: "Calibri",
                fontWeight: "bold",
                color: "#1976d2",
                fontSize: 25,
              }}
            >
              OFFERTE INSERITE
            </p>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={offerList}
              getOptionLabel={(option) => option.nome_listino}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                width: "100%",
                paddingTop: "2%",
                paddingLeft: "20%",
                paddingRight: "20%",
              }}
              renderInput={(params) => (
                <TextField {...params} label="OFFERTE" />
              )}
              value={Off_List_Selected}
              onChange={(e, newValue) => {
                setOff_List_Selected(newValue);
              }}
            />
            {loadingFileFList ? (
              <div>
                <BeatLoader
                  color={"#1976d2"}
                  loading={loadingFileFList}
                  size={40}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <h6
                  style={{
                    fontFamily: "Calibri",
                    color: "#1976d2",
                  }}
                >
                  CARICAMENTO...
                </h6>
              </div>
            ) : (
              <>
                <p
                  style={{
                    fontFamily: "Calibri",
                    fontWeight: "bold",
                    color: "#1976d2",
                    fontSize: 25,
                  }}
                >
                  FILE F
                </p>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={fileFList}
                  getOptionLabel={(option) => option.anno}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  sx={{
                    width: "100%",
                    paddingTop: "2%",
                    paddingLeft: "20%",
                    paddingRight: "20%",
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="FILE F" />
                  )}
                  value={FileF_List_Selected}
                  onChange={(e, newValue) => {
                    setFileF_List_Selected(newValue);
                  }}
                />
              </>
            )}
            <div
              style={{
                textAlign: "center",
                marginTop: "5%",
                marginBottom: "5%",
              }}
            >
              <Button
                variant="contained"
                component="label"
                color="bluetest"
                disabled={Off_List_Selected === null}
                startIcon={<AddchartIcon />}
                onClick={() => handleCreaAbbinato()}
              >
                CREA ABBINATO
              </Button>
            </div>
            {loadingABBValutation && (
              <div>
                <BeatLoader
                  color={"#1976d2"}
                  loading={loadingABBValutation}
                  size={40}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <h6
                  style={{
                    fontFamily: "Calibri",
                    color: "#1976d2",
                  }}
                >
                  CARICAMENTO...
                </h6>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AbbinatoGenerate;
