import React, { useState, useEffect } from "react";
import "../../../App.css";

// SERVICES
import acquistatoService from "../../../services/acquistato";

// COMPONENTS

// FUNCTIONS
import {
  handleStruttureChange,
  handlePurchasedDownload,
  handleResetACQDownload,
} from "../../../functions/DOWNLOAD/ACQ_BENCH_DWN";

import {
  handleBenchmarkingValutation,
  handleViewDetailsTab,
} from "../../../functions/VALUTATION/ACQ_BENCH_VAL";

import {
  handleSingleDetail,
  handleSingleDetailGrouped,
} from "../../../functions/VALUTATION/ACQ_BENCH_RESUME";

import { RapprPrezzo, RapprQuantita } from "../../../functions/FUN_CALCOLI";

import {
  handleResetSearchSEL,
  handleSearchSEL,
  createSuggestionsListSEL,
  handleFieldChangeSEL,
} from "../../../functions/TAB_SEARCH/GP_SEL_SEARCH";

import {
  handleResetSearchGRP,
  handleSearchGRP,
  createSuggestionsListGRP,
  handleFieldChangeGRP,
} from "../../../functions/TAB_SEARCH/GP_GRP_SEARCH";

import {
  handleResetSearchDTL,
  handleSearchDTL,
  createSuggestionsListDTL,
  handleFieldChangeDTL,
} from "../../../functions/TAB_SEARCH/GP_DTL_SEARCH";

import { handleColumnOrder } from "../../../functions/TAB_SORT/GP_SEL_SORT";

// COLUMNS
import {
  BENCH_ACQ_RESUME_COLUMNS,
  ABB_SELECTION_ACQ_COLUMNS,
  ABB_SELECTION_ACQ_COLUMNS_NODIFF,
  GARE_SELECTION_ACQ_COLUMNS,
  GARE_GROUPED_ACQ_COLUMNS,
} from "../../../colums/BENCHMARKING_COLUMNS";

//EXPS
import EXP_BENCHMARKING_RESUME_BIS from "../../../exports/ACQ/EXP_BENCHMARKING_RESUME_BIS";
import EXP_DETTAGLIO_GP from "../../../exports/ACQ/EXP_DETTAGLIO_GP";

// POPUPS
import GP_DETAILS_POPUP from "../../../popup/ACQ_POPUP/GP_DETAILS_POPUP";
import GP_SEL_SEARCH_POPUP from "../../../popup/SEARCH_POPUP/GP_SEL_SEARCH_POPUP";
import GP_GRP_SEARCH_POPUP from "../../../popup/SEARCH_POPUP/GP_GRP_SEARCH_POPUP";
import GP_DTL_SEARCH_POPUP from "../../../popup/SEARCH_POPUP/GP_DTL_SEARCH_POPUP";

// MUI ELEMENTS
import { createTheme, ThemeProvider } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Button from "@mui/material/Button";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

// ICONS
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

// LOADERS
import BeatLoader from "react-spinners/BeatLoader";

//OPTIONS
import gp_sel_search_options from "../../../utils/SEARCH_OPTIONS/gp_sel_search_options.json";
import gp_grp_search_options from "../../../utils/SEARCH_OPTIONS/gp_grp_search_options.json";
import gp_dtl_search_options from "../../../utils/SEARCH_OPTIONS/gp_dtl_search_options.json";

const theme = createTheme({
  palette: {
    bluetest: {
      main: "#1976d2",
      light: "#328ce7",
      dark: "#1566b7",
      contrastText: "#fff",
    },
    redtest: {
      main: "#ff0000",
      light: "#ff3333",
      dark: "#cc0000",
      contrastText: "#fff",
    },
  },
});

const PurchasedBenchmarking = ({ user }) => {
  const [purchasedList, setPurchasedList] = useState([]);
  const [ACQ_Selected, setACQ_Selected] = useState(null);
  const [SingleACQ_Downloaded, setSingleACQ_Downloaded] = useState(null);

  const [flagACQDownloadError, setFlagACQDownloadError] = useState(false);

  const [loadingPurchaseds, setLoadingPurchaseds] = useState(true);
  const [loadingSinglePurchased, setLoadingSinglePurchased] = useState(false);

  const [loadingBenchmarkingVAL, setLoadingBenchmarkingVAL] = useState(false);

  const [gareBenchmarking, setGareBenchmarking] = useState(null);

  const [listaStrutture, setListaStrutture] = useState(null);

  const [struttureSelected, setStruttureSelected] = useState(null);

  const [dettaglioFlag, setDettaglioFlag] = useState(false);

  const [quantitaTOT, setQuantitaTOT] = useState(null);
  const [spesaTOT, setSpesaTOT] = useState(null);

  const [acqSelGareList, setAcqSelGareList] = useState(null);

  const [gareSingleDetail, setGareSingleDetail] = useState(null);

  const [gareSelected, setGareSelected] = useState(null);

  const [rowNameSEL, setRowNameSEL] = useState("");

  const [arrayGrupedTEMP, setArrayGrupedTEMP] = useState(null);

  const [arrayGrupedFlag, setArrayGrupedFlag] = useState(false);

  const [popUpGaraPubOpen, setPopUpGaraPubOpen] = useState(false);
  const [dettaglioGaraPub, setDettaglioGaraPub] = useState(null);

  //*STATI PER SEARCH (SERVE PER REPLICARE SULLE ALTRE TABELLE)

  const [searchValue, setSearchValue] = useState(null);
  const [filteredResults, setFilteredResults] = useState(null);

  const [fieldSuggestions, setFieldSuggestions] = useState([]);

  const [searchedFlag, setSearchedFlag] = useState(false);
  const [notFoundFlag, setNotFoundFlag] = useState(false);

  const [selectedField, setSelectedField] = useState(null);

  //************** */
  const [searchSelPopupOpen, setSearchSelPopupOpen] = useState(false);

  const [searchGrpPopupOpen, setSearchGrpPopupOpen] = useState(false);

  const [searchDtlPopupOpen, setSearchDtlPopupOpen] = useState(false);

  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    if (!user.meshRoom) return;

    acquistatoService
      .getAcquistatoByMeshRoomId(user.meshRoom)
      .then((res) => {
        setPurchasedList(res.data);
        setLoadingPurchaseds(false);
      })
      .catch((error) => {
        console.error("Errore durante il recupero degli acquistati:", error);
      });
  }, [user.meshRoom]);

  const handleGPPopUp = (params) => {
    setDettaglioGaraPub(params.row);
    setPopUpGaraPubOpen(true);
  };

  const handlePopUpGaraPubClose = () => {
    setPopUpGaraPubOpen(false);
    setDettaglioGaraPub(null);
  };

  const handleBackToResume = () => {
    setGareSelected(null);
    setRowNameSEL([]);
    setDettaglioFlag(false);
    setGareSingleDetail(null);
    setAcqSelGareList(null);
    setQuantitaTOT(null);
    setSpesaTOT(null);
    setArrayGrupedTEMP(null);
    setArrayGrupedFlag(false);
    setSelectedField(null);
    setSearchedFlag(false);
    setSearchValue(null);
    setFieldSuggestions([]);
  };

  const handleBackTodetails = () => {
    setGareSingleDetail(null);
    setAcqSelGareList(null);
    setQuantitaTOT(null);
    setSpesaTOT(null);
    setSelectedField(null);
    setSearchedFlag(false);
    setSearchValue(null);
    setFieldSuggestions([]);
  };

  const handleTestCreateDetails = async (params, fileData) => {
    await handleResetClickSEL();

    await handleSingleDetail(
      params,
      fileData,
      setQuantitaTOT,
      setSpesaTOT,
      setAcqSelGareList,
      setGareSingleDetail
    );
  };

  const handleTestCreateDetailsBIS = async (params, fileData) => {
    await handleResetClickGRP();

    await handleSingleDetailGrouped(
      params,
      fileData,
      setQuantitaTOT,
      setSpesaTOT,
      setAcqSelGareList,
      setGareSingleDetail
    );
  };

  //*IMPORT PER SEARCH (SERVE PER REPLICARE SULLE ALTRE TABELLE)
  const handleSearchClickSEL = () => {
    handleSearchSEL(
      searchValue,
      gareSelected,
      selectedField,
      setFilteredResults,
      setNotFoundFlag,
      setSearchSelPopupOpen,

      setSearchedFlag,
      () =>
        handleResetSearchSEL(
          setFilteredResults,
          setSearchedFlag,
          setNotFoundFlag,
          setSearchSelPopupOpen
        )
    );
  };

  const handleFieldChangeWrapperSEL = (e, newValue) => {
    handleFieldChangeSEL(
      newValue,
      gareSelected,
      setSelectedField,
      setFieldSuggestions,
      createSuggestionsListSEL
    );
  };

  const handleResetClickSEL = () => {
    handleResetSearchSEL(
      setFilteredResults,
      setSearchedFlag,
      setNotFoundFlag,
      setSearchSelPopupOpen
    );
  };

  /*------*/

  const handleSearchClickGRP = () => {
    handleSearchGRP(
      searchValue,
      arrayGrupedTEMP,
      selectedField,
      setFilteredResults,
      setNotFoundFlag,
      setSearchGrpPopupOpen,

      setSearchedFlag,
      () =>
        handleResetSearchGRP(
          setFilteredResults,
          setSearchedFlag,
          setNotFoundFlag,
          setSearchGrpPopupOpen
        )
    );
  };

  const handleFieldChangeWrapperGRP = (e, newValue) => {
    handleFieldChangeGRP(
      newValue,
      arrayGrupedTEMP,
      setSelectedField,
      setFieldSuggestions,
      createSuggestionsListGRP
    );
  };

  const handleResetClickGRP = () => {
    handleResetSearchGRP(
      setFilteredResults,
      setSearchedFlag,
      setNotFoundFlag,
      setSearchGrpPopupOpen
    );
  };

  /*------*/

  const handleSearchClickDTL = () => {
    handleSearchDTL(
      searchValue,
      acqSelGareList,
      selectedField,
      setFilteredResults,
      setNotFoundFlag,
      setSearchDtlPopupOpen,

      setSearchedFlag,
      () =>
        handleResetSearchDTL(
          setFilteredResults,
          setSearchedFlag,
          setNotFoundFlag,
          setSearchDtlPopupOpen
        )
    );
  };

  const handleFieldChangeWrapperDTL = (e, newValue) => {
    handleFieldChangeDTL(
      newValue,
      acqSelGareList,
      setSelectedField,
      setFieldSuggestions,
      createSuggestionsListDTL
    );
  };

  const handleResetClickDTL = () => {
    handleResetSearchDTL(
      setFilteredResults,
      setSearchedFlag,
      setNotFoundFlag,
      setSearchDtlPopupOpen
    );
  };

  const handleColumnOrderWrapperSEL = (columnSEL) => {
    handleColumnOrder(
      columnSEL,
      searchedFlag,
      filteredResults,
      gareSelected,
      sortOrder,
      setFilteredResults,
      setGareSelected,
      setSortOrder
    );
  };

  // const handleColumnOrderWrapperGRP = (columnSEL) => {
  //   handleColumnOrder(
  //     columnSEL,
  //     searchedFlag,
  //     filteredResults,
  //     arrayGrupedTEMP,
  //     sortOrder,
  //     setArrayGrupedTEMP,
  //     setGareSelected,
  //     setSortOrder
  //   );
  // };

  //************** */

  return (
    <ThemeProvider theme={theme}>
      <div className="acqPage">
        <>
          {" "}
          {loadingPurchaseds ? (
            <div style={{ paddingTop: "10%" }}>
              <BeatLoader
                color={"#1976d2"}
                loading={loadingPurchaseds}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <>
              {SingleACQ_Downloaded === null && (
                <>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={purchasedList}
                    getOptionLabel={(option) => option.nome_salvataggio}
                    sx={{
                      width: "100%",
                      paddingTop: "10%",
                      paddingLeft: "38%",
                      paddingRight: "38%",
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="LISTA ACQUISTATI" />
                    )}
                    value={ACQ_Selected}
                    onChange={(e, newValue) => {
                      setACQ_Selected(newValue);
                    }}
                  />
                  {ACQ_Selected !== null && (
                    <div style={{ paddingTop: "3%" }}>
                      <Button
                        variant="contained"
                        color="bluetest"
                        startIcon={<FileDownloadOutlinedIcon />}
                        onClick={() =>
                          handlePurchasedDownload(
                            ACQ_Selected,
                            setLoadingSinglePurchased,
                            setFlagACQDownloadError,
                            setSingleACQ_Downloaded,
                            setListaStrutture
                          )
                        }
                      >
                        CARICA SELEZIONATO
                      </Button>
                    </div>
                  )}
                  {loadingSinglePurchased && (
                    <div style={{ paddingTop: "2%" }}>
                      <BeatLoader
                        color={"#1976d2"}
                        loading={loadingSinglePurchased}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  )}
                  {flagACQDownloadError && (
                    <div
                      style={{
                        color: "#ff0000",
                        textAlign: "center",
                        paddingTop: "2%",
                      }}
                    >
                      <b>ERRORE NEL CARICAMENTO DELL'ACQUISTATO</b>
                    </div>
                  )}
                </>
              )}
              {SingleACQ_Downloaded !== null && (
                <>
                  {!dettaglioFlag && gareSingleDetail === null && (
                    <>
                      <div>
                        <div
                          style={{
                            display: "inline-block",
                            width: "15%",
                            textAlign: "left",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="redtest"
                            startIcon={<ArrowBackIosNewOutlinedIcon />}
                            onClick={() =>
                              handleResetACQDownload(
                                setSingleACQ_Downloaded,
                                setACQ_Selected,
                                setGareBenchmarking,
                                setListaStrutture,
                                setStruttureSelected,
                                setArrayGrupedTEMP,
                                setArrayGrupedFlag
                              )
                            }
                          >
                            INDIETRO
                          </Button>
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            width: "85%",
                            textAlign: "right",
                          }}
                        ></div>
                      </div>
                      <div
                        style={{
                          marginTop: "2%",
                        }}
                      >
                        {gareBenchmarking === null && (
                          <>
                            <Autocomplete
                              multiple
                              disablePortal
                              id="combo-box-multiple"
                              options={listaStrutture ?? []}
                              getOptionLabel={(option) =>
                                option?.nome_struttura || ""
                              }
                              isOptionEqualToValue={(option, value) =>
                                option?.id === value?.id
                              }
                              sx={{
                                width: "100%",
                                marginTop: "2%",
                                paddingLeft: "30%",
                                paddingRight: "30%",
                                marginBottom: "2%",
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="STRUTTURE" />
                              )}
                              value={struttureSelected || []}
                              onChange={(e, newValue) =>
                                handleStruttureChange(
                                  e,
                                  newValue,
                                  listaStrutture,
                                  setStruttureSelected
                                )
                              }
                            />

                            <Button
                              variant="contained"
                              color="bluetest"
                              disabled={
                                struttureSelected === null ||
                                struttureSelected?.length === 0
                              }
                              startIcon={<QueryStatsOutlinedIcon />}
                              onClick={() =>
                                handleBenchmarkingValutation(
                                  setLoadingBenchmarkingVAL,
                                  SingleACQ_Downloaded,
                                  struttureSelected,
                                  setGareBenchmarking
                                )
                              }
                            >
                              BENCHMARKING
                            </Button>
                            {loadingBenchmarkingVAL && (
                              <div style={{ paddingTop: "2%" }}>
                                <BeatLoader
                                  color={"#1976d2"}
                                  loading={loadingBenchmarkingVAL}
                                  size={30}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {gareBenchmarking !== null && (
                        <>
                          <div>
                            <EXP_BENCHMARKING_RESUME_BIS
                              data={gareBenchmarking.BENCH_TAB}
                              fileName={
                                "BENCHMARKING SU " +
                                ACQ_Selected?.nome_salvataggio
                              }
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "2%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "inline-block",
                                width: "100%",
                                marginLeft: "20%",
                                marginRight: "20%",
                                paddingBottom: "1%",

                                "& .theme_header": {
                                  backgroundColor: "#1976d2",
                                  color: "#fff",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                },
                                "& .theme_cell": {
                                  fontFamily: "Calibri",
                                  fontSize: "17px",
                                  border: "0.5px solid rgba(0, 0, 0, 0.5)",
                                },
                                "& .theme_cell_lost": {
                                  fontFamily: "Calibri",
                                  backgroundColor: "#FFC7CE",
                                  color: "#9C0006",
                                  fontSize: "17px",
                                  border: "0.5px solid rgba(0, 0, 0, 0.5)",
                                },
                                "& .theme_cell_gain": {
                                  fontFamily: "Calibri",
                                  backgroundColor: "#C6EFCE",
                                  color: "#006100",
                                  fontSize: "17px",
                                  border: "0.5px solid rgba(0, 0, 0, 0.5)",
                                },
                              }}
                            >
                              <DataGridPro
                                columns={BENCH_ACQ_RESUME_COLUMNS}
                                rows={gareBenchmarking.BENCH_TAB}
                                getRowId={(row) => row.title}
                                headerHeight={40}
                                rowHeight={33}
                                autoHeight
                                {...gareBenchmarking.BENCH_TAB}
                                onRowDoubleClick={(params) =>
                                  handleViewDetailsTab(
                                    params,
                                    setArrayGrupedTEMP,
                                    setGareSelected,
                                    setRowNameSEL,
                                    setDettaglioFlag
                                  )
                                }
                                disableColumnMenu
                                hideFooter
                              />
                            </Box>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {dettaglioFlag && gareSingleDetail === null && (
                    <>
                      <div
                        style={{
                          marginLeft: "1%",
                          marginRight: "1%",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: "inline-block",
                              width: "10%",
                              textAlign: "left",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="redtest"
                              startIcon={<ArrowBackIosNewOutlinedIcon />}
                              onClick={handleBackToResume}
                            >
                              INDIETRO
                            </Button>
                          </div>

                          {!arrayGrupedFlag ? (
                            <>
                              <div
                                style={{
                                  display: "inline-block",
                                  width: "10%",
                                  textAlign: "left",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  color="bluetest"
                                  startIcon={<SearchIcon />}
                                  onClick={() => setSearchSelPopupOpen(true)}
                                >
                                  CERCA
                                </Button>
                              </div>
                              {!searchedFlag ? (
                                <div
                                  style={{
                                    display: "inline-block",
                                    width: "60%",
                                    textAlign: "left",
                                  }}
                                ></div>
                              ) : (
                                <div
                                  style={{
                                    display: "inline-block",
                                    width: "60%",
                                    textAlign: "left",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="redtest"
                                    startIcon={<RestartAltIcon />}
                                    onClick={handleResetClickSEL}
                                  >
                                    RESET CERCA
                                  </Button>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  display: "inline-block",
                                  width: "10%",
                                  textAlign: "left",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  color="bluetest"
                                  startIcon={<SearchIcon />}
                                  onClick={() => setSearchGrpPopupOpen(true)}
                                >
                                  CERCA
                                </Button>
                              </div>
                              {!searchedFlag ? (
                                <div
                                  style={{
                                    display: "inline-block",
                                    width: "60%",
                                    textAlign: "left",
                                  }}
                                ></div>
                              ) : (
                                <div
                                  style={{
                                    display: "inline-block",
                                    width: "60%",
                                    textAlign: "left",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="redtest"
                                    startIcon={<RestartAltIcon />}
                                    onClick={handleResetClickGRP}
                                  >
                                    RESET CERCA
                                  </Button>
                                </div>
                              )}
                            </>
                          )}

                          <div
                            style={{
                              display: "inline-block",
                              width: "20%",
                              textAlign: "right",
                            }}
                          >
                            <EXP_DETTAGLIO_GP
                              data={gareSelected}
                              file_name={rowNameSEL}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            paddingTop: "1%",
                          }}
                        >
                          {" "}
                          <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                              <FormControlLabel
                                value="bottom"
                                control={
                                  <Switch
                                    checked={arrayGrupedFlag}
                                    onChange={() => {
                                      setArrayGrupedFlag(!arrayGrupedFlag);
                                      setSelectedField(null);
                                      setSearchedFlag(false);
                                      setSearchValue(null);
                                      setFieldSuggestions([]);
                                    }}
                                  />
                                }
                                label={
                                  <h4
                                    style={{
                                      fontFamily: "Calibri",
                                      color: arrayGrupedFlag
                                        ? "#1976d2"
                                        : "#ccc",
                                    }}
                                  >
                                    DETTAGLIO TIPOLOGIA
                                  </h4>
                                }
                                labelPlacement="end"
                              />
                            </FormGroup>
                          </FormControl>
                        </div>

                        {!arrayGrupedFlag ? (
                          <>
                            <Box
                              sx={{
                                width: "100%",
                                height: "500px",
                                paddingLeft: "0%",
                                paddingRight: "0%",

                                "& .theme_header": {
                                  backgroundColor: "#1976d2",
                                  color: "#fff",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                },
                                "& .theme_cell": {
                                  fontFamily: "Calibri",
                                  fontSize: "17px",
                                  border: "1px solid #000",
                                },
                                "& .theme_cell_lost": {
                                  fontFamily: "Calibri",
                                  backgroundColor: "#FFC7CE",
                                  color: "#9C0006",
                                  fontSize: "17px",
                                  border: "0.5px solid rgba(0, 0, 0, 0.5)",
                                },
                                "& .theme_cell_gain": {
                                  fontFamily: "Calibri",
                                  backgroundColor: "#C6EFCE",
                                  color: "#006100",
                                  fontSize: "17px",
                                  border: "0.5px solid rgba(0, 0, 0, 0.5)",
                                },
                              }}
                            >
                              {!searchedFlag ? (
                                <DataGridPro
                                  columns={ABB_SELECTION_ACQ_COLUMNS}
                                  rows={gareSelected}
                                  getRowId={(row) => row._id}
                                  headerHeight={40}
                                  rowHeight={33}
                                  onRowDoubleClick={(params) =>
                                    handleTestCreateDetails(
                                      params,
                                      gareSelected
                                    )
                                  }
                                  onColumnHeaderDoubleClick={
                                    handleColumnOrderWrapperSEL
                                  }
                                  disableColumnMenu
                                  hideFooter
                                />
                              ) : (
                                <DataGridPro
                                  columns={ABB_SELECTION_ACQ_COLUMNS}
                                  rows={filteredResults}
                                  getRowId={(row) => row._id}
                                  headerHeight={40}
                                  rowHeight={33}
                                  onRowDoubleClick={(params) =>
                                    handleTestCreateDetails(
                                      params,
                                      gareSelected
                                    )
                                  }
                                  onColumnHeaderDoubleClick={
                                    handleColumnOrderWrapperSEL
                                  }
                                  disableColumnMenu
                                  hideFooter
                                />
                              )}
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box
                              sx={{
                                width: "100%",
                                height: "500px",
                                paddingTop: "0.5%",
                                paddingLeft: "0%",
                                paddingRight: "0%",

                                "& .theme_header": {
                                  backgroundColor: "#1976d2",
                                  color: "#fff",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                },
                                "& .theme_cell": {
                                  fontFamily: "Calibri",
                                  fontSize: "17px",
                                  border: "1px solid #000",
                                },
                                "& .theme_cell_lost": {
                                  fontFamily: "Calibri",
                                  backgroundColor: "#FFC7CE",
                                  color: "#9C0006",
                                  fontSize: "17px",
                                  border: "0.5px solid rgba(0, 0, 0, 0.5)",
                                },
                                "& .theme_cell_gain": {
                                  fontFamily: "Calibri",
                                  backgroundColor: "#C6EFCE",
                                  color: "#006100",
                                  fontSize: "17px",
                                  border: "0.5px solid rgba(0, 0, 0, 0.5)",
                                },
                              }}
                            >
                              {!searchedFlag ? (
                                <DataGridPro
                                  columns={GARE_GROUPED_ACQ_COLUMNS}
                                  rows={arrayGrupedTEMP}
                                  getRowId={(row) => row.id_temp}
                                  headerHeight={40}
                                  rowHeight={33}
                                  onRowDoubleClick={(params) =>
                                    handleTestCreateDetailsBIS(
                                      params,
                                      gareSelected
                                    )
                                  }
                                  // onColumnHeaderDoubleClick={
                                  //   handleColumnOrderWrapperGRP
                                  // }
                                  disableColumnMenu
                                  hideFooter
                                />
                              ) : (
                                <DataGridPro
                                  columns={GARE_GROUPED_ACQ_COLUMNS}
                                  rows={filteredResults}
                                  getRowId={(row) => row.id_temp}
                                  headerHeight={40}
                                  rowHeight={33}
                                  onRowDoubleClick={(params) =>
                                    handleTestCreateDetailsBIS(
                                      params,
                                      gareSelected
                                    )
                                  }
                                  // onColumnHeaderDoubleClick={
                                  //   handleColumnOrderWrapperGRP
                                  // }
                                  disableColumnMenu
                                  hideFooter
                                />
                              )}
                            </Box>
                          </>
                        )}
                      </div>
                    </>
                  )}
                  {dettaglioFlag && gareSingleDetail !== null && (
                    <>
                      <div
                        style={{
                          marginLeft: "1%",
                          marginRight: "1%",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: "inline-block",
                              width: "15%",
                              textAlign: "left",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="redtest"
                              startIcon={<ArrowBackIosNewOutlinedIcon />}
                              onClick={handleBackTodetails}
                            >
                              INDIETRO
                            </Button>
                          </div>
                          <div
                            style={{
                              display: "inline-block",
                              width: "85%",
                              textAlign: "left",
                            }}
                          ></div>
                        </div>

                        <Box
                          sx={{
                            width: "100%",
                            paddingTop: "0.5%",
                            // paddingLeft: "4%",
                            // paddingRight: "4%",

                            "& .theme_header": {
                              backgroundColor: "#1976d2",
                              color: "#fff",
                              fontFamily: "Calibri",
                              fontSize: "16px",
                            },
                            "& .theme_cell": {
                              fontFamily: "Calibri",
                              fontSize: "17px",
                              border: "1px solid #000",
                            },
                            "& .theme_cell_lost": {
                              fontFamily: "Calibri",
                              backgroundColor: "#FFC7CE",
                              color: "#9C0006",
                              fontSize: "17px",
                              border: "0.5px solid rgba(0, 0, 0, 0.5)",
                            },
                            "& .theme_cell_gain": {
                              fontFamily: "Calibri",
                              backgroundColor: "#C6EFCE",
                              color: "#006100",
                              fontSize: "17px",
                              border: "0.5px solid rgba(0, 0, 0, 0.5)",
                            },
                          }}
                        >
                          <DataGridPro
                            columns={ABB_SELECTION_ACQ_COLUMNS_NODIFF}
                            rows={gareSingleDetail}
                            getRowId={(row) => row._id}
                            headerHeight={40}
                            rowHeight={33}
                            autoHeight
                            {...gareSingleDetail}
                            onRowDoubleClick={(params) =>
                              handleSingleDetail(
                                params,
                                gareSelected,
                                setQuantitaTOT,
                                setSpesaTOT,
                                setAcqSelGareList,
                                setGareSingleDetail
                              )
                            }
                            disableColumnMenu
                            hideFooter
                          />
                        </Box>
                        <div
                          style={{
                            fontFamily: "Calibri",
                            color: "#1976d2",
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "0 5%",
                            marginTop: "1%",
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: "Calibri",
                              color: "#1976d2",
                              margin: 0,
                              padding: 10,
                              border: "2px solid",
                              borderColor: "#1976d2",
                              borderRadius: "10px",
                              textAlign: "center",
                            }}
                          >
                            SPESA TOTALE:{" "}
                            <span
                              style={{
                                fontSize: "25px",
                              }}
                            >
                              {RapprPrezzo(spesaTOT)}
                            </span>
                          </h3>
                          <h3
                            style={{
                              fontFamily: "Calibri",
                              color: "#1976d2",
                              margin: 0,
                              padding: 10,
                              border: "2px solid",
                              borderColor: "#1976d2",
                              borderRadius: "10px",
                              textAlign: "center",
                            }}
                          >
                            QUANTITA' TOTALE:{" "}
                            <span
                              style={{
                                fontSize: "25px",
                              }}
                            >
                              {RapprQuantita(quantitaTOT)}
                            </span>
                          </h3>
                        </div>

                        <div
                          style={{
                            paddingTop: "1%",
                          }}
                        >
                          <div
                            style={{
                              display: "inline-block",
                              width: "10%",
                              textAlign: "left",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="bluetest"
                              startIcon={<SearchIcon />}
                              onClick={() => setSearchDtlPopupOpen(true)}
                            >
                              CERCA
                            </Button>
                          </div>
                          {!searchedFlag ? (
                            <div
                              style={{
                                display: "inline-block",
                                width: "90%",
                                textAlign: "left",
                              }}
                            ></div>
                          ) : (
                            <div
                              style={{
                                display: "inline-block",
                                width: "90%",
                                textAlign: "left",
                              }}
                            >
                              <Button
                                variant="contained"
                                color="redtest"
                                startIcon={<RestartAltIcon />}
                                onClick={handleResetClickDTL}
                              >
                                RESET CERCA
                              </Button>
                            </div>
                          )}
                        </div>

                        <Box
                          sx={{
                            width: "100%",
                            height: "350px",
                            marginTop: "0.5%",
                            // paddingLeft: "4%",
                            // paddingRight: "4%",

                            "& .theme_header": {
                              backgroundColor: "#1976d2",
                              color: "#fff",
                              fontFamily: "Calibri",
                              fontSize: "16px",
                            },
                            "& .theme_cell": {
                              fontFamily: "Calibri",
                              fontSize: "17px",
                              border: "1px solid #000",
                            },
                            "& .theme_cell_lost": {
                              fontFamily: "Calibri",
                              backgroundColor: "#FFC7CE",
                              color: "#9C0006",
                              fontSize: "17px",
                              border: "0.5px solid rgba(0, 0, 0, 0.5)",
                            },
                            "& .theme_cell_gain": {
                              fontFamily: "Calibri",
                              backgroundColor: "#C6EFCE",
                              color: "#006100",
                              fontSize: "17px",
                              border: "0.5px solid rgba(0, 0, 0, 0.5)",
                            },
                          }}
                        >
                          {!searchedFlag ? (
                            <DataGridPro
                              columns={GARE_SELECTION_ACQ_COLUMNS}
                              rows={acqSelGareList}
                              getRowId={(row) => row.id}
                              headerHeight={40}
                              rowHeight={33}
                              onRowDoubleClick={handleGPPopUp}
                              disableColumnMenu
                              hideFooter
                            />
                          ) : (
                            <DataGridPro
                              columns={GARE_SELECTION_ACQ_COLUMNS}
                              rows={filteredResults}
                              getRowId={(row) => row.id}
                              headerHeight={40}
                              rowHeight={33}
                              onRowDoubleClick={handleGPPopUp}
                              disableColumnMenu
                              hideFooter
                            />
                          )}
                        </Box>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
          <GP_DETAILS_POPUP
            popUpGaraPubOpen={popUpGaraPubOpen}
            dettaglioGaraPub={dettaglioGaraPub}
            handlePopUpGaraPubClose={handlePopUpGaraPubClose}
          />
          <GP_SEL_SEARCH_POPUP
            searchSelPopupOpen={searchSelPopupOpen}
            setSearchSelPopupOpen={setSearchSelPopupOpen}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            gp_sel_search_options={gp_sel_search_options}
            handleSearchClickSEL={handleSearchClickSEL}
            notFoundFlag={notFoundFlag}
            selectedField={selectedField}
            fieldSuggestions={fieldSuggestions}
            handleFieldChangeWrapperSEL={handleFieldChangeWrapperSEL}
            searchedFlag={searchedFlag}
            handleResetClickSEL={handleResetClickSEL}
          />
          <GP_GRP_SEARCH_POPUP
            searchGrpPopupOpen={searchGrpPopupOpen}
            setSearchGrpPopupOpen={setSearchGrpPopupOpen}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            gp_grp_search_options={gp_grp_search_options}
            handleSearchClickGRP={handleSearchClickGRP}
            notFoundFlag={notFoundFlag}
            selectedField={selectedField}
            fieldSuggestions={fieldSuggestions}
            handleFieldChangeWrapperGRP={handleFieldChangeWrapperGRP}
            searchedFlag={searchedFlag}
            handleResetClickGRP={handleResetClickGRP}
          />
          <GP_DTL_SEARCH_POPUP
            searchDtlPopupOpen={searchDtlPopupOpen}
            setSearchDtlPopupOpen={setSearchDtlPopupOpen}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            gp_dtl_search_options={gp_dtl_search_options}
            handleSearchClickDTL={handleSearchClickDTL}
            notFoundFlag={notFoundFlag}
            selectedField={selectedField}
            fieldSuggestions={fieldSuggestions}
            handleFieldChangeWrapperDTL={handleFieldChangeWrapperDTL}
            searchedFlag={searchedFlag}
            handleResetClickDTL={handleResetClickDTL}
          />
        </>
      </div>
    </ThemeProvider>
  );
};

export default PurchasedBenchmarking;
