//FUNZIONI
import { calcoloCosto } from "../functions/FUN_CALCOLI";

const RapprPrezzo = (PrezzoDaRappresentare) => {
  if (PrezzoDaRappresentare === "") {
    return PrezzoDaRappresentare;
  } else {
    let IntDec = PrezzoDaRappresentare.toString();
    IntDec = IntDec.split(".");

    let nuovoVal = "";
    let vecchioVal = IntDec[0];

    while (vecchioVal?.length > 3) {
      nuovoVal = "." + vecchioVal.substr(vecchioVal?.length - 3) + nuovoVal;
      vecchioVal = vecchioVal.substr(0, vecchioVal?.length - 3);
    }
    if (IntDec[1] !== undefined) {
      if (IntDec[1].length === 1) {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1] + "0";
      } else {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1];
      }
    } else {
      nuovoVal = vecchioVal + nuovoVal + ",00";
    }

    let finalVal = nuovoVal;

    if (finalVal.startsWith("-.")) {
      finalVal = "- " + finalVal.substr(2);
    } else if (finalVal.startsWith(".")) {
      finalVal = finalVal.substr(1);
    }

    return finalVal + " €";
  }
};

const RapprPerc = (PercDaRappresentare) => {
  if (PercDaRappresentare === "") {
    return PercDaRappresentare;
  } else {
    let IntDec = PercDaRappresentare.toString();
    IntDec = IntDec.split(".");

    let nuovoVal = "";
    let vecchioVal = IntDec[0];

    while (vecchioVal?.length > 3) {
      nuovoVal = "." + vecchioVal.substr(vecchioVal?.length - 3) + nuovoVal;
      vecchioVal = vecchioVal.substr(0, vecchioVal?.length - 3);
    }
    if (IntDec[1] !== undefined) {
      if (IntDec[1].length === 1) {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1] + "0";
      } else {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1];
      }
    } else {
      nuovoVal = vecchioVal + nuovoVal;
    }

    let finalVal = nuovoVal;

    if (finalVal.startsWith("-.")) {
      finalVal = "- " + finalVal.substr(2);
    } else if (finalVal.startsWith(".")) {
      finalVal = finalVal.substr(1);
    }

    return finalVal + "%";
  }
};

const handleCellClassNamePrize = (Num) => {
  if (Num === "" || Num === 0) {
    return "theme_cell";
  } else if (Num >= 0) {
    return "theme_cell_lost";
  } else if (Num < 0) {
    return "theme_cell_gain";
  }
};

const handleCellClassNameSelection = (flagCheck) => {
  if (flagCheck) {
    return "theme_cell_selected";
  } else {
    return "theme_cell";
  }
};

const handleCellClassNameDiff = (diff) => {
  if (diff < 0) {
    return "theme_cell_gain";
  } else if (diff > 0) {
    return "theme_cell_lost";
  } else {
    return "theme_cell";
  }
};

//COLUMNS

const isTotalRow = (title) =>
  ["TOTALE MOLECOLE INCROCIATE", "TOTALE MOLECOLE ANALIZZATE"].includes(title);

const renderStyledCellABB_BENCH_RESUME = (params, valueFormatter) => (
  <div
    style={{
      fontSize: 16,
      fontWeight: isTotalRow(params.row.title) ? "bold" : "normal",
    }}
  >
    {valueFormatter
      ? valueFormatter(params.row)
      : params.row?.[params.field] ?? ""}
  </div>
);

const BENCH_RESUME_COLUMNS = [
  {
    field: "title",
    headerName: "CONFRONTO GARE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    flex: 1.35,
    sortable: false,
    renderCell: (params) => renderStyledCellABB_BENCH_RESUME(params),
  },
  {
    field: "n_molecole",
    headerName: "N.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    flex: 0.35,
    sortable: false,
    renderCell: (params) => renderStyledCellABB_BENCH_RESUME(params),
  },
  {
    field: "spesaABB",
    headerName: "PREV. SEPSA 2025",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    flex: 0.8,
    sortable: false,
    renderCell: (params) =>
      renderStyledCellABB_BENCH_RESUME(
        params,
        (row) => RapprPrezzo(row?.spesaABB) || row?.spesaABB
      ),
  },
  {
    field: "perc_spesaABB",
    headerName: "%",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    flex: 0.5,
    sortable: false,
    renderCell: (params) =>
      renderStyledCellABB_BENCH_RESUME(params, (row) =>
        RapprPerc(row?.perc_spesaABB)
      ),
  },
  {
    field: "spesaGarePub",
    headerName: "GARE PUBBLICHE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    flex: 0.8,
    sortable: false,
    renderCell: (params) =>
      renderStyledCellABB_BENCH_RESUME(
        params,
        (row) => RapprPrezzo(row?.spesaGarePub) || row?.spesaGarePub
      ),
  },
  {
    field: "diff_spesa",
    headerName: "DIFFERENZA",
    headerAlign: "center",
    headerClassName: "theme_header",
    align: "right",
    flex: 0.7,
    sortable: false,
    cellClassName: (params) => handleCellClassNamePrize(params.row.diff_spesa),
    renderCell: (params) =>
      renderStyledCellABB_BENCH_RESUME(params, (row) =>
        RapprPrezzo(row?.diff_spesa)
      ),
  },
  {
    field: "perc_diff_spesa",
    headerName: "DIFF. %",
    headerAlign: "center",
    headerClassName: "theme_header",
    align: "center",
    flex: 0.5,
    sortable: false,
    cellClassName: (params) => handleCellClassNamePrize(params.row.diff_spesa),
    renderCell: (params) =>
      renderStyledCellABB_BENCH_RESUME(params, (row) =>
        RapprPerc(row?.perc_diff_spesa)
      ),
  },
];

const BENCH_ACQ_RESUME_COLUMNS = [
  {
    field: "title",
    headerName: "DETTAGLIO",
    headerAlign: "center",
    align: "left",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1.5,
    sortable: false,
    renderCell: (params) => (
      <div
        style={
          params.row.title === "TOTALE MOLECOLE INCROCIATE" ||
          params.row.title === "TOTALE MOLECOLE ANALIZZATE"
            ? { fontWeight: "bold", fontSize: 14 }
            : { fontSize: 14 }
        }
      >
        {params.row.title}
      </div>
    ),
  },
  {
    field: "n_molecole",
    headerName: "N.",
    headerAlign: "center",
    align: "right",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.35,
    sortable: false,
    renderCell: (params) => (
      <div
        style={
          params.row.title === "TOTALE MOLECOLE INCROCIATE" ||
          params.row.title === "TOTALE MOLECOLE ANALIZZATE"
            ? { fontWeight: "bold", fontSize: 16 }
            : { fontSize: 16 }
        }
      >
        {params.row.n_molecole}
      </div>
    ),
  },
  {
    field: "spesaACQ",
    headerName: "ESITI GARA 2025",
    headerAlign: "center",
    align: "right",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.8,
    sortable: false,
    renderCell: (params) => {
      if (RapprPrezzo(params.row.spesaACQ) !== "") {
        return (
          <div
            style={
              params.row.title === "TOTALE MOLECOLE INCROCIATE" ||
              params.row.title === "TOTALE MOLECOLE ANALIZZATE"
                ? { fontWeight: "bold", fontSize: 16 }
                : { fontSize: 16 }
            }
          >
            {RapprPrezzo(params.row.spesaACQ)}
          </div>
        );
      }
      return <div>{params.row.spesaACQ}</div>;
    },
  },
  {
    field: "perc_spesaACQ",
    headerName: "%",
    headerAlign: "center",
    align: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.5,
    sortable: false,
    renderCell: (params) => {
      if (params.row?.perc_spesaACQ !== "") {
        return (
          <div
            style={
              params.row.title === "TOTALE MOLECOLE INCROCIATE" ||
              params.row.title === "TOTALE MOLECOLE ANALIZZATE"
                ? { fontWeight: "bold", fontSize: 16 }
                : { fontSize: 16 }
            }
          >
            {RapprPerc(params.row?.perc_spesaACQ)}
          </div>
        );
      }
      return <div></div>;
    },
  },
  {
    field: "spesaGarePub",
    headerName: "GARE PUBBLICHE",
    headerAlign: "center",
    align: "right",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.8,
    sortable: false,
    renderCell: (params) => {
      if (RapprPrezzo(params.row.spesaGarePub) !== "") {
        return (
          <div
            style={
              params.row.title === "TOTALE MOLECOLE INCROCIATE" ||
              params.row.title === "TOTALE MOLECOLE ANALIZZATE"
                ? { fontWeight: "bold", fontSize: 16 }
                : { fontSize: 16 }
            }
          >
            {RapprPrezzo(params.row.spesaGarePub)}
          </div>
        );
      }
      return <div>{params.row.spesaGarePub}</div>;
    },
  },
  {
    field: "diff_spesa",
    headerName: "DIFFERENZA",
    headerAlign: "center",
    align: "right",
    headerClassName: "theme_header",
    cellClassName: (params) => handleCellClassNamePrize(params.row.diff_spesa),
    flex: 0.7,
    sortable: false,
    renderCell: (params) => {
      if (params.row.diff_spesa !== "") {
        return (
          <div
            style={
              params.row.title === "TOTALE MOLECOLE INCROCIATE" ||
              params.row.title === "TOTALE MOLECOLE ANALIZZATE"
                ? { fontWeight: "bold", fontSize: 16 }
                : { fontSize: 16 }
            }
          >
            {RapprPrezzo(params.row.diff_spesa)}
          </div>
        );
      }
      return <div>{params.row.diff_spesa}</div>;
    },
  },
  {
    field: "perc_diff_spesa",
    headerName: "DIFF. %",
    headerAlign: "center",
    align: "center",
    headerClassName: "theme_header",
    flex: 0.5,
    cellClassName: (params) => handleCellClassNamePrize(params.row.diff_spesa),
    sortable: false,
    renderCell: (params) => {
      if (params.row?.perc_diff_spesa !== "") {
        return (
          <div
            style={
              params.row.title === "TOTALE MOLECOLE INCROCIATE" ||
              params.row.title === "TOTALE MOLECOLE ANALIZZATE"
                ? { fontWeight: "bold", fontSize: 16 }
                : { fontSize: 16 }
            }
          >
            {RapprPerc(params.row?.perc_diff_spesa)}
          </div>
        );
      }
      return <div></div>;
    },
  },
];

const ABB_SELECTION_ACQ_COLUMNS = [
  {
    field: "prezzo_unitario",
    headerName: "PREZZO",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => (
      <div>{params.row?.prezzo_unitario.toFixed(5)} €</div>
    ),
  },
  {
    field: "spesa",
    headerName: "SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          calcoloCosto(params.row?.prezzo_unitario, params.row?.quantita)
        )}
      </div>
    ),
  },
  {
    field: "differenze",
    headerName: "DIFF. SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,

    cellClassName: (params) =>
      handleCellClassNameDiff(
        calcoloCosto(
          params.row.garePubList[0].prezzo_unitario,
          params.row.quantita
        ) - calcoloCosto(params.row.prezzo_unitario, params.row.quantita)
      ),
    flex: 0.5,
    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          (Math.round(
            calcoloCosto(
              params.row.garePubList[0].prezzo_unitario,
              params.row.quantita
            ) - calcoloCosto(params.row.prezzo_unitario, params.row.quantita)
          ) *
            100) /
            100
        )}
      </div>
    ),
  },
  {
    field: "descrizione_gruppo_ospedaliero",
    headerName: "TIPOLOGIA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 1,
    renderCell: (params) => (
      <div>{params.row?.descrizione_gruppo_ospedaliero}</div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.35,
    renderCell: (params) => <div>{params.row?.aic}</div>,
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerClassName: "theme_header",
    headerAlign: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.8,
    renderCell: (params) => <div>{params.row?.nome_commerciale}</div>,
  },
  {
    field: "fornitore",
    headerName: "FORNITORE",
    headerClassName: "theme_header",
    headerAlign: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.fornitore.ragione_sociale}</div>,
  },
  {
    field: "atc_comp",
    headerName: "ATC COMP.",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.4,
    renderCell: (params) => <div>{params.row?.atc_complementare}</div>,
  },
  {
    field: "quantita",
    headerName: "QUANTITA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.35,
    renderCell: (params) => <div>{params.row?.quantita}</div>,
  },
  {
    field: "filef_flag",
    headerName: "F",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.2,
    renderCell: (params) => <div>{params.row?.filef_flag ? "F" : ""}</div>,
  },
];

const ABB_SELECTION_ACQ_COLUMNS_NODIFF = [
  {
    field: "prezzo_unitario",
    headerName: "PREZZO",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => (
      <div>{params.row?.prezzo_unitario.toFixed(5)} €</div>
    ),
  },
  {
    field: "spesa",
    headerName: "SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          calcoloCosto(params.row?.prezzo_unitario, params.row?.quantita)
        )}
      </div>
    ),
  },
  {
    field: "differenze",
    headerName: "DIFF. SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),

    flex: 0.5,
    renderCell: (params) => <div>-</div>,
  },
  {
    field: "descrizione_gruppo_ospedaliero",
    headerName: "TIPOLOGIA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 1,
    renderCell: (params) => (
      <div>{params.row?.descrizione_gruppo_ospedaliero}</div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.35,
    renderCell: (params) => <div>{params.row?.aic}</div>,
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerClassName: "theme_header",
    headerAlign: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.8,
    renderCell: (params) => <div>{params.row?.nome_commerciale}</div>,
  },
  {
    field: "fornitore",
    headerName: "FORNITORE",
    headerClassName: "theme_header",
    headerAlign: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.fornitore.ragione_sociale}</div>,
  },
  {
    field: "atc_comp",
    headerName: "ATC COMP.",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.4,
    renderCell: (params) => <div>{params.row?.atc_complementare}</div>,
  },
  {
    field: "quantita",
    headerName: "QUANTITA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.35,
    renderCell: (params) => <div>{params.row?.quantita}</div>,
  },
];

const GARE_SELECTION_ACQ_COLUMNS = [
  {
    field: "prezzo_unitario",
    headerName: "PREZZO",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.4,
    renderCell: (params) => (
      <div>{params.row?.prezzo_unitario.toFixed(5)} €</div>
    ),
  },
  {
    field: "spesa",
    headerName: "SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.4,
    renderCell: (params) => <div>{RapprPrezzo(params.row?.spesa_temp)}</div>,
  },
  {
    field: "differenze",
    headerName: "DIFF. SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,

    cellClassName: (params) =>
      handleCellClassNameDiff(
        params.row?.spesa_temp - params.row?.spesa_totale
      ),
    flex: 0.5,
    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          (Math.round(params.row?.spesa_temp - params.row?.spesa_totale) *
            100) /
            100
        )}
      </div>
    ),
  },
  {
    field: "descrizione_gruppo_ospedaliero",
    headerName: "TIPOLOGIA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 1,
    renderCell: (params) => (
      <div>{params.row?.descrizione_gruppo_ospedaliero}</div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.35,
    renderCell: (params) => <div>{params.row?.aic}</div>,
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerClassName: "theme_header",
    headerAlign: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.8,
    renderCell: (params) => <div>{params.row?.nome_commerciale}</div>,
  },
  {
    field: "fornitore",
    headerName: "FORNITORE",
    headerClassName: "theme_header",
    headerAlign: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.ragione_sociale}</div>,
  },
  {
    field: "regione",
    headerName: "REGIONE",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.35,
    renderCell: (params) => <div>{params.row?.regione}</div>,
  },
  {
    field: "atc_comp",
    headerName: "ATC COMP.",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.4,
    renderCell: (params) => <div>{params.row?.atc_complementare}</div>,
  },
];

const GARE_GROUPED_ACQ_COLUMNS = [
  {
    field: "spesa_tot",
    headerName: "SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{RapprPrezzo(params.row?.spesa_tot)}</div>,
  },
  {
    field: "differenze",
    headerName: "DIFFERENZA SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,

    cellClassName: (params) =>
      handleCellClassNameDiff(
        calcoloCosto(
          params.row?.garePubList[0].prezzo_unitario,
          params.row?.quantita_tot
        ) - params.row?.spesa_tot
      ),
    flex: 0.5,
    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          (Math.round(
            calcoloCosto(
              params.row?.garePubList[0].prezzo_unitario,
              params.row?.quantita_tot
            ) - params.row?.spesa_tot
          ) *
            100) /
            100
        )}
      </div>
    ),
  },
  {
    field: "tipologia",
    headerName: "TIPOLOGIA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 1.3,
    renderCell: (params) => <div>{params.row?.tipologia}</div>,
  },
  {
    field: "quantita_tot",
    headerName: "QUANTITA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.35,
    renderCell: (params) => <div>{params.row?.quantita_tot}</div>,
  },
];

const GARE_GROUPED_ACQ_COLUMNS_TEST = [
  {
    field: "spesa_tot",
    headerName: "SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{RapprPrezzo(params.row?.spesa_tot)}</div>,
  },
  {
    field: "differenze",
    headerName: "DIFFERENZA SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,

    cellClassName: (params) =>
      handleCellClassNameDiff(
        calcoloCosto(
          params.row?.cod_osp_list[0].prezzo_unitario,
          params.row?.quantita_tot
        ) - params.row?.spesa_tot
      ),
    flex: 0.5,
    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          (Math.round(
            calcoloCosto(
              params.row?.cod_osp_list[0].prezzo_unitario,
              params.row?.quantita_tot
            ) - params.row?.spesa_tot
          ) *
            100) /
            100
        )}
      </div>
    ),
  },
  {
    field: "tipologia",
    headerName: "TIPOLOGIA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 1.3,
    renderCell: (params) => <div>{params.row?.tipologia}</div>,
  },

  {
    field: "quantita_tot",
    headerName: "QUANTITA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.35,
    renderCell: (params) => <div>{params.row?.quantita_tot}</div>,
  },
];

//-------
const ABB_SELECTION_ACQ_COLUMNS_BIS = [
  {
    field: "prezzo_unitario",
    headerName: "PREZZO",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => (
      <div>{params.row?.prezzo_unitario.toFixed(5)} €</div>
    ),
  },
  {
    field: "spesa",
    headerName: "SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          calcoloCosto(params.row?.prezzo_unitario, params.row?.quantita)
        )}
      </div>
    ),
  },
  {
    field: "differenze",
    headerName: "DIFF. SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,

    cellClassName: (params) =>
      handleCellClassNameDiff(
        calcoloCosto(
          params.row.garePubList[0].prezzo_unitario,
          params.row.quantita
        ) - calcoloCosto(params.row.prezzo_unitario, params.row.quantita)
      ),
    flex: 0.5,
    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          (Math.round(
            calcoloCosto(
              params.row.garePubList[0].prezzo_unitario,
              params.row.quantita
            ) - calcoloCosto(params.row.prezzo_unitario, params.row.quantita)
          ) *
            100) /
            100
        )}
      </div>
    ),
  },
  {
    field: "descrizione_gruppo_ospedaliero",
    headerName: "TIPOLOGIA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 1,
    renderCell: (params) => (
      <div>{params.row?.descrizione_gruppo_ospedaliero}</div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.35,
    renderCell: (params) => <div>{params.row?.aic}</div>,
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerClassName: "theme_header",
    headerAlign: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.8,
    renderCell: (params) => <div>{params.row?.nome_commerciale}</div>,
  },
  {
    field: "fornitore",
    headerName: "FORNITORE",
    headerClassName: "theme_header",
    headerAlign: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.fornitore.ragione_sociale}</div>,
  },
  {
    field: "atc_comp",
    headerName: "ATC COMP.",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.4,
    renderCell: (params) => <div>{params.row?.atc_complementare}</div>,
  },
  {
    field: "quantita",
    headerName: "QUANTITA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.35,
    renderCell: (params) => <div>{params.row?.quantita}</div>,
  },
  {
    field: "filef_flag",
    headerName: "F",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.2,
    renderCell: (params) => <div>{params.row?.filef_flag ? "F" : ""}</div>,
  },
];

const ABB_SELECTION_ACQ_COLUMNS_NODIFF_BIS = [
  {
    field: "prezzo_unitario",
    headerName: "PREZZO ABBINATO",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => (
      <div>{params.row?.spec_checked.prezzo_unitario.toFixed(5)} €</div>
    ),
  },
  {
    field: "spesa",
    headerName: "SPESA ABBINATO",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          calcoloCosto(
            params.row?.spec_checked.prezzo_unitario,
            params.row?.spec_checked.quantita
          )
        )}
      </div>
    ),
  },
  {
    field: "differenze",
    headerName: "DIFF. SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>-</div>,
  },
  {
    field: "descrizione_gruppo_ospedaliero",
    headerName: "TIPOLOGIA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 1,
    renderCell: (params) => (
      <div>{params.row?.spec_checked.descrizione_gruppo_ospedaliero}</div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.35,
    renderCell: (params) => <div>{params.row?.spec_checked.aic}</div>,
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerClassName: "theme_header",
    headerAlign: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.8,
    renderCell: (params) => (
      <div>{params.row?.spec_checked.nome_commerciale}</div>
    ),
  },
  {
    field: "fornitore",
    headerName: "FORNITORE",
    headerClassName: "theme_header",
    headerAlign: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => (
      <div>{params.row?.spec_checked.ragione_sociale}</div>
    ),
  },
  {
    field: "atc_comp",
    headerName: "ATC COMP.",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.4,
    renderCell: (params) => (
      <div>{params.row?.spec_checked.atc_complementare}</div>
    ),
  },
  {
    field: "quantita",
    headerName: "QUANTITA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.35,
    renderCell: (params) => <div>{params.row?.spec_checked.quantita}</div>,
  },
];

const GARE_SELECTION_ACQ_COLUMNS_BIS = [
  {
    field: "prezzo_unitario",
    headerName: "PREZZO",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => (
      <div>{params.row?.prezzo_unitario.toFixed(5)} €</div>
    ),
  },
  {
    field: "spesa",
    headerName: "SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{RapprPrezzo(params.row?.spesa_temp)}</div>,
  },
  {
    field: "differenze",
    headerName: "DIFF. SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,

    cellClassName: (params) =>
      handleCellClassNameDiff(
        params.row?.spesa_temp - params.row?.spesa_totale
      ),
    flex: 0.5,
    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          (Math.round(params.row?.spesa_temp - params.row?.spesa_totale) *
            100) /
            100
        )}
      </div>
    ),
  },
  {
    field: "descrizione_gruppo_ospedaliero",
    headerName: "TIPOLOGIA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 1,
    renderCell: (params) => (
      <div>{params.row?.descrizione_gruppo_ospedaliero}</div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.35,
    renderCell: (params) => <div>{params.row?.aic}</div>,
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerClassName: "theme_header",
    headerAlign: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.8,
    renderCell: (params) => <div>{params.row?.nome_commerciale}</div>,
  },
  {
    field: "fornitore",
    headerName: "FORNITORE",
    headerClassName: "theme_header",
    headerAlign: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.ragione_sociale}</div>,
  },
  {
    field: "atc_comp",
    headerName: "ATC COMP.",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.4,
    renderCell: (params) => <div>{params.row?.atc_complementare}</div>,
  },
  {
    field: "regione",
    headerName: "REGIONE",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.35,
    renderCell: (params) => <div>{params.row?.regione}</div>,
  },
];

const GARE_GROUPED_ACQ_COLUMNS_BIS = [
  {
    field: "spesa_tot",
    headerName: "SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{RapprPrezzo(params.row?.spesa_tot)}</div>,
  },
  {
    field: "differenze",
    headerName: "DIFFERENZA SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameDiff(
        calcoloCosto(
          params.row?.garePubList[0].prezzo_unitario,
          params.row?.quantita_tot
        ) - params.row?.spesa_tot
      ),
    flex: 0.5,
    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          (Math.round(
            calcoloCosto(
              params.row?.garePubList[0].prezzo_unitario,
              params.row?.quantita_tot
            ) - params.row?.spesa_tot
          ) *
            100) /
            100
        )}
      </div>
    ),
  },
  {
    field: "tipologia",
    headerName: "TIPOLOGIA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 1.3,
    renderCell: (params) => <div>{params.row?.tipologia}</div>,
  },
  {
    field: "quantita_tot",
    headerName: "QUANTITA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.35,
    renderCell: (params) => <div>{params.row?.quantita_tot}</div>,
  },
];
//---------

export {
  BENCH_RESUME_COLUMNS,
  BENCH_ACQ_RESUME_COLUMNS,
  ABB_SELECTION_ACQ_COLUMNS,
  ABB_SELECTION_ACQ_COLUMNS_NODIFF,
  GARE_SELECTION_ACQ_COLUMNS,
  GARE_GROUPED_ACQ_COLUMNS,
  ABB_SELECTION_ACQ_COLUMNS_BIS,
  ABB_SELECTION_ACQ_COLUMNS_NODIFF_BIS,
  GARE_SELECTION_ACQ_COLUMNS_BIS,
  GARE_GROUPED_ACQ_COLUMNS_BIS,
  GARE_GROUPED_ACQ_COLUMNS_TEST,
};
