//FUNZIONI
import { calcoloCosto } from "../functions/FUN_CALCOLI";

const RapprPrezzo = (PrezzoDaRappresentare) => {
  if (PrezzoDaRappresentare === "" || PrezzoDaRappresentare === undefined) {
    return PrezzoDaRappresentare;
  } else {
    let IntDec = PrezzoDaRappresentare.toString();
    IntDec = IntDec.split(".");

    let nuovoVal = "";
    let vecchioVal = IntDec[0];

    while (vecchioVal?.length > 3) {
      nuovoVal = "." + vecchioVal.substr(vecchioVal?.length - 3) + nuovoVal;
      vecchioVal = vecchioVal.substr(0, vecchioVal?.length - 3);
    }
    if (IntDec[1] !== undefined) {
      if (IntDec[1].length === 1) {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1] + "0";
      } else {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1];
      }
    } else {
      nuovoVal = vecchioVal + nuovoVal + ",00";
    }

    let finalVal = nuovoVal;

    if (finalVal.startsWith("-.")) {
      finalVal = "- " + finalVal.substr(2);
    } else if (finalVal.startsWith(".")) {
      finalVal = finalVal.substr(1);
    }

    return finalVal + " €";
  }
};

const handleCellClassNamePrize = (Num) => {
  if (Num === "" || Num === 0) {
    return "theme_cell";
  } else if (Num >= 0) {
    return "theme_cell_lost";
  } else if (Num < 0) {
    return "theme_cell_gain";
  }
};

// const handleCellClassNameDiff = (diff) => {
//   if (diff < 0) {
//     return "theme_cell_gain";
//   } else if (diff > 0) {
//     return "theme_cell_lost";
//   } else {
//     return "theme_cell";
//   }
// };

const RapprPerc = (PercDaRappresentare) => {
  if (PercDaRappresentare === "") {
    return PercDaRappresentare;
  } else {
    let IntDec = PercDaRappresentare.toString();
    IntDec = IntDec.split(".");

    let nuovoVal = "";
    let vecchioVal = IntDec[0];

    while (vecchioVal?.length > 3) {
      nuovoVal = "." + vecchioVal.substr(vecchioVal?.length - 3) + nuovoVal;
      vecchioVal = vecchioVal.substr(0, vecchioVal?.length - 3);
    }
    if (IntDec[1] !== undefined) {
      if (IntDec[1].length === 1) {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1] + "0";
      } else {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1];
      }
    } else {
      nuovoVal = vecchioVal + nuovoVal;
    }

    let finalVal = nuovoVal;

    if (finalVal.startsWith("-.")) {
      finalVal = "- " + finalVal.substr(2);
    } else if (finalVal.startsWith(".")) {
      finalVal = finalVal.substr(1);
    }

    return finalVal + "%";
  }
};

const calcoloDifferenzaCosto = (CostoACQ, CostoOffSel) => {
  let diff_temp = CostoOffSel - CostoACQ;
  return Math.round(diff_temp * 100) / 100;
};

const handleCellClassNameSelectionPrinc = (
  flagChecked,
  flagCollectivePrize
) => {
  if (flagChecked) {
    return "theme_cell_selected";
  } else if (!flagChecked && !flagCollectivePrize) {
    return "theme_cell";
  } else if (!flagChecked && flagCollectivePrize) {
    return "theme_cell_collectivePrize";
  }
};

const handleCellClassNamePrizeColor = (Num) => {
  if (Num > 0) {
    return "theme_cell_lost";
  } else if (Num < 0) {
    return "theme_cell_gain";
  } else if (Num === 0) {
    return "theme_cell";
  }
};

const handleCellClassNameSelection = (flagCheck) => {
  if (flagCheck) {
    return "theme_cell_selected";
  } else {
    return "theme_cell";
  }
};

//COLUMNS

//*TAB RESUME PRINCIPALE
const isTotalRow = (title) =>
  ["TOTALE GENERALE", "TOTALE ABBINAMENTI", "TOTALE NON ABBINATI"].includes(
    title
  );

const renderStyledCellABB_RESUME = (params, valueFormatter) => (
  <div
    style={{
      fontSize: 16,
      fontWeight: isTotalRow(params.row.title) ? "bold" : "normal",
    }}
  >
    {valueFormatter
      ? valueFormatter(params.row)
      : params.row?.[params.field] ?? ""}
  </div>
);

const ABB_RESUME_COLUMNS = [
  {
    field: "title",
    headerName: "ABBINATI",
    headerAlign: "center",
    headerClassName: "theme_header_1",
    cellClassName: "theme_cell",
    align: "left",
    width: 190,
    sortable: false,
    renderCell: (params) => renderStyledCellABB_RESUME(params),
  },
  {
    field: "n_molecole",
    headerName: "N.",
    headerAlign: "center",
    headerClassName: "theme_header_1",
    cellClassName: "theme_cell",
    align: "right",
    flex: 0.3,
    sortable: false,
    renderCell: (params) => renderStyledCellABB_RESUME(params),
  },
  {
    field: "costo_acq",
    headerName: "ACQUISTATO 2024",
    headerAlign: "center",
    headerClassName: "theme_header_1",
    cellClassName: "theme_cell",
    align: "right",
    flex: 1,
    sortable: false,
    renderCell: (params) =>
      renderStyledCellABB_RESUME(
        params,
        (row) => RapprPrezzo(row?.costo_acq) || row?.costo_acq
      ),
  },
  {
    field: "costo_minimo",
    headerName: "PREV. SPESA 2025",
    headerAlign: "center",
    headerClassName: "theme_header_1",
    cellClassName: "theme_cell_2",
    align: "right",
    flex: 1,
    sortable: false,
    renderCell: (params) =>
      renderStyledCellABB_RESUME(
        params,
        (row) => RapprPrezzo(row?.costo_minimo) || row?.costo_minimo
      ),
  },
  {
    field: "diff_costo_acq_minimo",
    headerName: "DIFFERENZA",
    headerAlign: "center",
    headerClassName: "theme_header_1",
    align: "right",
    flex: 0.7,
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNamePrize(params.row.diff_costo_acq_minimo),
    renderCell: (params) =>
      renderStyledCellABB_RESUME(params, (row) =>
        RapprPrezzo(row?.diff_costo_acq_minimo)
      ),
  },
  {
    field: "diff_perc_minimo",
    headerName: "DIFF. %",
    headerAlign: "center",
    headerClassName: "theme_header_1",
    align: "center",
    flex: 0.4,
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNamePrize(params.row.diff_perc_minimo),
    renderCell: (params) =>
      renderStyledCellABB_RESUME(params, (row) =>
        RapprPerc(row?.diff_perc_minimo)
      ),
  },
  {
    field: "costo_selezionato",
    headerName: "PREV. SPESA SELEZIONATI",
    headerAlign: "center",
    headerClassName: "theme_header_1",
    cellClassName: "theme_cell_2",
    align: "right",
    flex: 1.2,
    sortable: false,
    renderCell: (params) =>
      renderStyledCellABB_RESUME(
        params,
        (row) => RapprPrezzo(row?.costo_selezionato) || row?.costo_selezionato
      ),
  },
  {
    field: "diff_costo_acq_selezionato",
    headerName: "DIFFERENZA",
    headerAlign: "center",
    headerClassName: "theme_header_1",
    align: "right",
    flex: 0.7,
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNamePrize(params.row.diff_costo_acq_selezionato),
    renderCell: (params) =>
      renderStyledCellABB_RESUME(params, (row) =>
        RapprPrezzo(row?.diff_costo_acq_selezionato)
      ),
  },
  {
    field: "diff_perc_selezionato",
    headerName: "DIFF. %",
    headerAlign: "center",
    headerClassName: "theme_header_1",
    align: "center",
    flex: 0.4,
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNamePrize(params.row.diff_perc_selezionato),
    renderCell: (params) =>
      renderStyledCellABB_RESUME(params, (row) =>
        RapprPerc(row?.diff_perc_selezionato)
      ),
  },
  // {
  //   field: "costo_garepub",
  //   headerName: "PREV. GARE PUB.",
  //   headerAlign: "center",
  //   headerClassName: "theme_header",
  //   cellClassName: "theme_cell",
  //   align: "right",
  //   flex: 1,
  //   sortable: false,
  //   renderCell: (params) =>
  //     renderStyledCellABB_RESUME(
  //       params,
  //       (row) => RapprPrezzo(row?.costo_garepub) || row?.costo_garepub
  //     ),
  // },
  // {
  //   field: "diff_costo_acq_garepub",
  //   headerName: "DIFFERENZA",
  //   headerAlign: "center",
  //   headerClassName: "theme_header",
  //   align: "right",
  //   flex: 1,
  //   sortable: false,
  //   cellClassName: (params) =>
  //     handleCellClassNamePrize(params.row.diff_costo_acq_garepub),
  //   renderCell: (params) =>
  //     renderStyledCellABB_RESUME(params, (row) =>
  //       RapprPrezzo(row?.diff_costo_acq_garepub)
  //     ),
  // },
  // {
  //   field: "diff_perc_garepub",
  //   headerName: "DIFF. %",
  //   headerAlign: "center",
  //   headerClassName: "theme_header",
  //   align: "center",
  //   flex: 0.45,
  //   sortable: false,
  //   cellClassName: (params) =>
  //     handleCellClassNamePrize(params.row.diff_perc_garepub),
  //   renderCell: (params) =>
  //     renderStyledCellABB_RESUME(params, (row) =>
  //       RapprPerc(row?.diff_perc_garepub)
  //     ),
  // },
];

//*TAB RESUME DETTAGLIO TIPOLOGIA
const ABB_SELECTED_LIST_COLUMNS = [
  {
    field: "principio_attivo",
    headerName: "PRINCIPIO ATTIVO",
    headerAlign: "center",
    align: "left",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNameSelectionPrinc(
        params.row.flag_assigned,
        params.row.flag_prize_collective
      ),
    width: 200,
    sortable: false,
    renderCell: (params) => <div>{params.row?.principio_attivo}</div>,
  },
  {
    field: "descrizione_gruppo_ospedaliero",
    headerName: "TIPOLOGIA",
    headerAlign: "center",
    align: "left",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNameSelectionPrinc(
        params.row.flag_assigned,
        params.row.flag_prize_collective
      ),
    width: 406,
    sortable: false,
    renderCell: (params) => (
      <div>{params.row?.descrizione_gruppo_ospedaliero}</div>
    ),
  },
  {
    field: "diff_spesa",
    headerName: "DIFF. SPESA",
    headerAlign: "center",
    align: "right",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNamePrizeColor(
        calcoloDifferenzaCosto(
          calcoloCosto(params.row?.prezzo_unitario, params.row?.quantita),
          calcoloCosto(
            params.row?.spec_checked.prezzo_unitario,
            params.row?.spec_checked.quantita
          )
        )
      ),
    width: 114,
    sortable: false,
    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          calcoloDifferenzaCosto(
            calcoloCosto(params.row?.prezzo_unitario, params.row?.quantita),
            calcoloCosto(
              params.row?.spec_checked.prezzo_unitario,
              params.row?.spec_checked.quantita
            )
          )
        )}
      </div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC 25",
    headerAlign: "center",
    align: "center",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNameSelectionPrinc(
        params.row.flag_assigned,
        params.row.flag_prize_collective
      ),
    width: 118,
    sortable: false,
    renderCell: (params) => <div>{params.row?.spec_checked.aic}</div>,
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE 25",
    headerAlign: "center",
    align: "left",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNameSelectionPrinc(
        params.row.flag_assigned,
        params.row.flag_prize_collective
      ),
    width: 280,
    sortable: false,
    renderCell: (params) => (
      <div>{params.row?.spec_checked.nome_commerciale}</div>
    ),
  },
  {
    field: "filef_flag",
    headerName: "FILE F",
    headerAlign: "center",
    align: "center",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNameSelectionPrinc(
        params.row.flag_assigned,
        params.row.flag_prize_collective
      ),
    width: 65,
    sortable: false,
    renderCell: (params) => <div>{params.row?.filef_flag ? "F" : ""}</div>,
  },
  {
    field: "fornitore",
    headerName: "FORNITORE 25",
    headerAlign: "center",
    align: "left",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNameSelectionPrinc(
        params.row.flag_assigned,
        params.row.flag_prize_collective
      ),
    width: 176,
    sortable: false,
    renderCell: (params) => (
      <div>{params.row?.spec_checked.ragione_sociale}</div>
    ),
  },
  {
    field: "prezzo_unitario",
    headerName: "PREZZO 25",
    headerAlign: "center",
    align: "right",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNameSelectionPrinc(
        params.row.flag_assigned,
        params.row.flag_prize_collective
      ),
    width: 120,
    sortable: false,
    renderCell: (params) => (
      <div>{params.row?.spec_checked.prezzo_unitario.toFixed(5)} €</div>
    ),
  },
  {
    field: "spesa",
    headerName: "SPESA 25",
    headerAlign: "center",
    align: "right",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNameSelectionPrinc(
        params.row.flag_assigned,
        params.row.flag_prize_collective
      ),
    width: 116,
    sortable: false,
    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          calcoloCosto(
            params.row?.spec_checked.prezzo_unitario,
            params.row?.spec_checked.quantita
          )
        )}
      </div>
    ),
  },
  {
    field: "aic2",
    headerName: "AIC 24",
    headerAlign: "center",
    align: "center",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNameSelectionPrinc(
        params.row.flag_assigned,
        params.row.flag_prize_collective
      ),
    width: 118,
    sortable: false,
    renderCell: (params) => <div>{params.row?.aic}</div>,
  },
  {
    field: "nome_commerciale2",
    headerName: "NOME COMMERCIALE 24",
    headerAlign: "center",
    align: "left",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNameSelectionPrinc(
        params.row.flag_assigned,
        params.row.flag_prize_collective
      ),
    width: 280,
    sortable: false,
    renderCell: (params) => <div>{params.row?.nome_commerciale}</div>,
  },

  {
    field: "fornitore2",
    headerName: "FORNITORE 24",
    headerAlign: "center",
    align: "left",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNameSelectionPrinc(
        params.row.flag_assigned,
        params.row.flag_prize_collective
      ),
    width: 176,
    sortable: false,
    renderCell: (params) => <div>{params.row?.fornitore.ragione_sociale}</div>,
  },
  {
    field: "quantita",
    headerName: "Q",
    headerAlign: "center",
    align: "right",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNameSelectionPrinc(
        params.row.flag_assigned,
        params.row.flag_prize_collective
      ),
    width: 86,
    sortable: false,
    renderCell: (params) => <div>{params.row?.quantita}</div>,
  },

  {
    field: "spesa2",
    headerName: "SPESA 24",
    headerAlign: "center",
    align: "right",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNameSelectionPrinc(
        params.row.flag_assigned,
        params.row.flag_prize_collective
      ),
    width: 116,
    sortable: false,
    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          calcoloCosto(params.row?.prezzo_unitario, params.row?.quantita)
        )}
      </div>
    ),
  },
];

//****
//*ACQ DETTAGLIO SINGOLA MOLECOLA
const ABB_SELECTION_ACQ_COLUMNS = [
  {
    field: "prezzo_unitario",
    headerName: "PREZZO ACQUISTATO",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => (
      <div>{params.row?.prezzo_unitario.toFixed(5)} €</div>
    ),
  },
  {
    field: "spesa",
    headerName: "SPESA ACQUISTATO",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          calcoloCosto(params.row?.prezzo_unitario, params.row?.quantita)
        )}
      </div>
    ),
  },
  {
    field: "differenze",
    headerName: "DIFF. SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>X</div>,
  },
  {
    field: "aic",
    headerName: "AIC ACQUISTATO",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.aic}</div>,
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerClassName: "theme_header",
    headerAlign: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.8,
    renderCell: (params) => <div>{params.row?.nome_commerciale}</div>,
  },
  {
    field: "fornitore",
    headerName: "FORNITORE",
    headerClassName: "theme_header",
    headerAlign: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.fornitore.ragione_sociale}</div>,
  },
  {
    field: "atc_comp",
    headerName: "ATC COMP.",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.atc_complementare}</div>,
  },
  {
    field: "quantita",
    headerName: "QUANTITA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.quantita}</div>,
  },
  {
    field: "filef_flag",
    headerName: "F",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.2,
    renderCell: (params) => <div>{params.row?.filef_flag ? "F" : ""}</div>,
  },
];

//*???
const ABB_SELECTION_ACQ_COLUMNS_BIS = [
  {
    field: "prezzo_unitario",
    headerName: "PREZZO ABBINATO",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => (
      <div>{params.row?.spec_checked.prezzo_unitario.toFixed(5)} €</div>
    ),
  },
  {
    field: "spesa",
    headerName: "SPESA ABBINATO",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          calcoloCosto(
            params.row?.spec_checked.prezzo_unitario,
            params.row?.spec_checked.quantita
          )
        )}
      </div>
    ),
  },
  {
    field: "differenze",
    headerName: "DIFF. SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    sortable: false,

    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>X</div>,
  },
  // {
  //   field: "differenze",
  //   headerName: "DIFF. SPESA",
  //   headerClassName: "theme_header",
  //   headerAlign: "center",
  //   align: "center",
  //   sortable: false,

  //   cellClassName: (params) =>
  //     handleCellClassNameDiff(
  //       calcoloCosto(params.row?.prezzo_unitario, params.row?.quantita) -
  //         calcoloCosto(
  //           params.row?.spec_checked.prezzo_unitario,
  //           params.row?.spec_checked.quantita
  //         )
  //     ),
  //   flex: 0.5,
  //   renderCell: (params) => (
  //     <div>
  //       {RapprPrezzo(
  //         (Math.round(
  //           calcoloCosto(params.row?.prezzo_unitario, params.row?.quantita) -
  //             calcoloCosto(
  //               params.row?.spec_checked.prezzo_unitario,
  //               params.row?.spec_checked.quantita
  //             )
  //         ) *
  //           100) /
  //           100
  //       )}
  //     </div>
  //   ),
  // },
  {
    field: "aic",
    headerName: "AIC ACQUISTATO",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.spec_checked.aic}</div>,
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerClassName: "theme_header",
    headerAlign: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.8,
    renderCell: (params) => (
      <div>{params.row?.spec_checked.nome_commerciale}</div>
    ),
  },
  {
    field: "fornitore",
    headerName: "FORNITORE",
    headerClassName: "theme_header",
    headerAlign: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => (
      <div>{params.row?.spec_checked.ragione_sociale}</div>
    ),
  },
  {
    field: "atc_comp",
    headerName: "ATC COMP.",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => (
      <div>{params.row?.spec_checked.atc_complementare}</div>
    ),
  },
  {
    field: "quantita",
    headerName: "QUANTITA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.spec_checked.quantita}</div>,
  },
];

//*OFFERTE DETTAGLIO SINGOLA MOLECOLA
const ABB_SELECTION_OFFERS_COLUMNS = [
  {
    field: "prezzo_unitario",
    headerName: "PREZZO OFFERTA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => (
      <div>{params.row?.prezzo_unitario.toFixed(5)} €</div>
    ),
  },
  {
    field: "spesa",
    headerName: "SPESA CALCOLATA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{RapprPrezzo(params.row?.spesa_temp)}</div>,
  },
  {
    field: "differenze",
    headerName: "DIFF. SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,

    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          calcoloDifferenzaCosto(
            params.row?.spesa_totale,
            params.row?.spesa_temp
          )
        )}
      </div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC OFFERTA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.aic}</div>,
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerClassName: "theme_header",
    headerAlign: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.8,
    renderCell: (params) => <div>{params.row?.nome_commerciale}</div>,
  },
  {
    field: "fornitore",
    headerName: "FORNITORE",
    headerClassName: "theme_header",
    headerAlign: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.ragione_sociale}</div>,
  },
  {
    field: "atc_comp",
    headerName: "ATC COMP.",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.atc_complementare}</div>,
  },
  {
    field: "ordine_min",
    headerName: "ORDINE MIN",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: (params) =>
      handleCellClassNameSelection(params.row.flag_is_checked),
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.ordine_min}</div>,
  },
];

//*GARE PUB DETTAGLIO SINGOLA MOLECOLA
const ABB_SELECTION_GAREPUB_COLUMNS = [
  {
    field: "prezzo_unitario",
    headerName: "PREZZO GARA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: "theme_cell_gp",
    flex: 0.5,
    renderCell: (params) => (
      <div>{params.row?.prezzo_unitario.toFixed(5)} €</div>
    ),
  },
  {
    field: "spesa",
    headerName: "SPESA CALCOLATA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: "theme_cell_gp",
    flex: 0.5,
    renderCell: (params) => <div>{RapprPrezzo(params.row?.spesa_temp)}</div>,
  },
  {
    field: "differenze",
    headerName: "DIFF. SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: "theme_cell_gp",
    flex: 0.5,
    renderCell: (params) => (
      <div>
        {RapprPrezzo(
          calcoloDifferenzaCosto(
            params.row?.spesa_totale,
            params.row?.spesa_temp
          )
        )}
      </div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC GARA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: "theme_cell_gp",
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.aic}</div>,
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerClassName: "theme_header",
    headerAlign: "center",
    cellClassName: "theme_cell_gp",
    flex: 0.8,
    renderCell: (params) => <div>{params.row?.nome_commerciale}</div>,
  },
  {
    field: "fornitore",
    headerName: "FORNITORE",
    headerClassName: "theme_header",
    headerAlign: "center",
    cellClassName: "theme_cell_gp",
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.ragione_sociale}</div>,
  },
  {
    field: "atc_comp",
    headerName: "ATC COMP.",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: "theme_cell_gp",
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.atc_complementare}</div>,
  },
  {
    field: "regione",
    headerName: "REGIONE",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    cellClassName: "theme_cell_gp",
    flex: 0.5,
    renderCell: (params) => <div>{params.row?.regione}</div>,
  },
];

//****

//*DETTAGLIO MOLECOLE SENZA ABBINAMENTO
const ABB_TOWAIT_COLUMNS = [
  {
    field: "descrizione_gruppo_ospedaliero",
    headerName: "TIPOLOGIA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    cellClassName: "theme_cell",
    flex: 1.5,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.descrizione_gruppo_ospedaliero}
      </div>
    ),
  },
  {
    field: "principio_attivo",
    headerName: "PRINCIPIO ATTIVO",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    cellClassName: "theme_cell",
    flex: 1.5,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.principio_attivo}</div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: "theme_cell",
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.aic}</div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    cellClassName: "theme_cell",
    flex: 1.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.nome_commerciale}</div>
    ),
  },
  {
    field: "fornitore",
    headerName: "FORNITORE",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    cellClassName: "theme_cell",
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.fornitore.ragione_sociale}
      </div>
    ),
  },

  {
    field: "prezzo_unitario",
    headerName: "PREZZO",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "right",
    cellClassName: "theme_cell",
    flex: 0.5,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {RapprPrezzo(params.row?.prezzo_unitario.toFixed(5))}
      </div>
    ),
  },
  {
    field: "spesa",
    headerName: "SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "right",
    cellClassName: "theme_cell",
    flex: 0.5,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {RapprPrezzo(
          calcoloCosto(params.row?.prezzo_unitario, params.row?.quantita)
        )}
      </div>
    ),
  },
];

//*DETTAGLIO MOLECOLE NON ABBINABILI
const ABB_TOWAIT_ATC_COLUMNS = [
  {
    field: "principio_attivo",
    headerName: "PRINCIPIO ATTIVO",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    cellClassName: "theme_cell",
    flex: 1.5,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.principio_attivo}</div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: "theme_cell",
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.aic}</div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    cellClassName: "theme_cell",
    flex: 1.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.nome_commerciale}</div>
    ),
  },
  {
    field: "fornitore",
    headerName: "FORNITORE",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    cellClassName: "theme_cell",
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.fornitore.ragione_sociale}
      </div>
    ),
  },
  {
    field: "atc",
    headerName: "ATC",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: "theme_cell",
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.atc}</div>
    ),
  },
  {
    field: "atc_comp",
    headerName: "ATC COMP.",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "center",
    cellClassName: "theme_cell",
    flex: 0.6,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.atc_complementare}</div>
    ),
  },
  {
    field: "prezzo_unitario",
    headerName: "PREZZO",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "right",
    cellClassName: "theme_cell",
    flex: 0.6,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.prezzo_unitario.toFixed(5)} €
      </div>
    ),
  },
  {
    field: "spesa",
    headerName: "SPESA",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "right",
    cellClassName: "theme_cell",
    flex: 0.6,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {RapprPrezzo(
          calcoloCosto(params.row?.prezzo_unitario, params.row?.quantita)
        )}
      </div>
    ),
  },
];

//*???
const ABB_SUPP_RESUME_COLUMNS = [
  {
    field: "fornitore",
    headerName: "FORNITORE",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "left",
    cellClassName: "theme_cell",
    sortable: false,
    flex: 0.8,
    renderCell: (params) => <div>{params.row?.fornitore.ragione_sociale}</div>,
  },
  {
    field: "tot_specialita_acq",
    headerName: "N.",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "right",
    cellClassName: "theme_cell",
    sortable: false,
    flex: 0.3,
    renderCell: (params) => <div>{params.row?.spec_acq.length}</div>,
  },
  {
    field: "tot_spesa_acq",
    headerName: "SPESA 2024",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "right",
    cellClassName: "theme_cell",
    sortable: false,
    flex: 0.5,
    renderCell: (params) => (
      <div>{RapprPrezzo(params.row?.tot_spesa_acq.toFixed(2))}</div>
    ),
  },
  {
    field: "tot_specialita_min",
    headerName: "N. ABB.",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "right",
    cellClassName: "theme_cell",
    sortable: false,
    flex: 0.3,
    renderCell: (params) => <div>{params.row?.spec_min.length}</div>,
  },
  {
    field: "tot_spesa_min",
    headerName: "SPESA ABB. 2024",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "right",
    cellClassName: "theme_cell",
    sortable: false,
    flex: 0.5,
    renderCell: (params) => (
      <div>{RapprPrezzo(params.row?.tot_spesa_min.toFixed(2))}</div>
    ),
  },
  {
    field: "tot_specialita_sel",
    headerName: "N. ASS.",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "right",
    cellClassName: "theme_cell",
    sortable: false,
    flex: 0.3,
    renderCell: (params) => <div>{params.row?.spec_checked.length}</div>,
  },
  {
    field: "tot_spesa_sel",
    headerName: "SPESA ASS. 2024",
    headerClassName: "theme_header",
    headerAlign: "center",
    align: "right",
    cellClassName: "theme_cell",
    sortable: false,
    flex: 0.5,
    renderCell: (params) => (
      <div>{RapprPrezzo(params.row?.tot_spesa_checked.toFixed(2))}</div>
    ),
  },
];

//*???
const ABB_RESUME_COLUMNS_IPOTESI = [
  {
    field: "title",
    headerName: "ABBINAMENTI",
    headerAlign: "center",
    align: "left",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    width: 240,
    sortable: false,
    renderCell: (params) => (
      <div
        style={
          params.row.title === "TOTALE GENERALE"
            ? { fontWeight: "bold", fontSize: 16 }
            : { fontSize: 16 }
        }
      >
        {params.row.title}
      </div>
    ),
  },
  {
    field: "n_molecole",
    headerName: "N.",
    headerAlign: "center",
    align: "right",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    width: 70,
    sortable: false,
    renderCell: (params) => (
      <div
        style={
          params.row.title === "TOTALE GENERALE"
            ? { fontWeight: "bold", fontSize: 16 }
            : { fontSize: 16 }
        }
      >
        {params.row.n_molecole}
      </div>
    ),
  },

  {
    field: "costo_acq",
    headerName: "COSTO 24",
    headerAlign: "center",
    align: "right",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      if (RapprPrezzo(params.row.costo_acq) !== "") {
        return (
          <div
            style={
              params.row.title === "TOTALE GENERALE"
                ? { fontWeight: "bold", fontSize: 16 }
                : { fontSize: 16 }
            }
          >
            {RapprPrezzo(params.row.costo_acq)}
          </div>
        );
      }
      return <div>{params.row.costo_acq}</div>;
    },
  },
  {
    field: "costo_minimo",
    headerName: "PREV. SPESA 24",
    headerAlign: "center",
    align: "right",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      if (params.row.costo_minimo !== "") {
        return (
          <div
            style={
              params.row.title === "TOTALE GENERALE"
                ? { fontWeight: "bold", fontSize: 16 }
                : { fontSize: 16 }
            }
          >
            {RapprPrezzo(params.row.costo_minimo)}
          </div>
        );
      }
      return <div>{params.row.costo_minimo}</div>;
    },
  },
  {
    field: "diff_costo_acq_minimo",
    headerName: "DIFFERENZA",
    headerAlign: "center",
    align: "right",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNamePrize(params.row.diff_costo_acq_minimo),
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      if (params.row.diff_costo_acq_minimo !== "") {
        return (
          <div
            style={
              params.row.title === "TOTALE GENERALE"
                ? { fontWeight: "bold", fontSize: 16 }
                : { fontSize: 16 }
            }
          >
            {RapprPrezzo(params.row.diff_costo_acq_minimo)}
          </div>
        );
      }
      return <div>{params.row.diff_costo_acq_minimo}</div>;
    },
  },
  {
    field: "diff_perc_minimo",
    headerName: "DIFF. %",
    headerAlign: "center",
    align: "center",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNamePrize(params.row.diff_perc_minimo),
    flex: 0.5,
    sortable: false,
    renderCell: (params) => {
      if (params.row?.diff_perc_minimo !== "") {
        return (
          <div
            style={
              params.row.title === "TOTALE GENERALE"
                ? { fontWeight: "bold", fontSize: 16 }
                : { fontSize: 16 }
            }
          >
            {RapprPerc(params.row?.diff_perc_minimo)}
          </div>
        );
      }
      return <div></div>;
    },
  },
  {
    field: "costo_selezionato",
    headerName: "PREV. SPESA SEL.",
    headerAlign: "center",
    align: "right",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      if (params.row.costo_selezionato !== "") {
        return (
          <div
            style={
              params.row.title === "TOTALE GENERALE"
                ? { fontWeight: "bold", fontSize: 16 }
                : { fontSize: 16 }
            }
          >
            {RapprPrezzo(params.row.costo_selezionato)}
          </div>
        );
      }
      return <div>{params.row.ccosto_selezionato}</div>;
    },
  },
  {
    field: "diff_costo_acq_selezionato",
    headerName: "DIFFERENZA",
    headerAlign: "center",
    align: "right",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNamePrize(params.row.diff_costo_acq_selezionato),
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      if (params.row.diff_costo_acq_selezionato !== "") {
        return (
          <div
            style={
              params.row.title === "TOTALE GENERALE"
                ? { fontWeight: "bold", fontSize: 16 }
                : { fontSize: 16 }
            }
          >
            {RapprPrezzo(params.row.diff_costo_acq_selezionato)}
          </div>
        );
      }
      return <div>{params.row.diff_costo_acq_selezionato}</div>;
    },
  },
  {
    field: "diff_perc_selezionato",
    headerName: "DIFF. %",
    headerAlign: "center",
    align: "center",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNamePrize(params.row.diff_perc_selezionato),
    flex: 0.5,
    sortable: false,
    renderCell: (params) => {
      if (params.row?.diff_perc_selezionato !== "") {
        return (
          <div
            style={
              params.row.title === "TOTALE GENERALE"
                ? { fontWeight: "bold", fontSize: 16 }
                : { fontSize: 16 }
            }
          >
            {RapprPerc(params.row?.diff_perc_selezionato)}
          </div>
        );
      }
      return <div></div>;
    },
  },
  {
    field: "costo_garepub",
    headerName: "PREV. GARE PUB.",
    headerAlign: "center",
    align: "right",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      if (params.row.costo_garepub !== "") {
        return (
          <div
            style={
              params.row.title === "TOTALE GENERALE"
                ? { fontWeight: "bold", fontSize: 16 }
                : { fontSize: 16 }
            }
          >
            {RapprPrezzo(params.row.costo_garepub)}
          </div>
        );
      }
      return <div>{params.row.ccosto_garepub}</div>;
    },
  },
  {
    field: "diff_costo_acq_garepub",
    headerName: "DIFFERENZA",
    headerAlign: "center",
    align: "right",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNamePrize(params.row.diff_costo_acq_garepub),
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      if (params.row.diff_costo_acq_garepub !== "") {
        return (
          <div
            style={
              params.row.title === "TOTALE GENERALE"
                ? { fontWeight: "bold", fontSize: 16 }
                : { fontSize: 16 }
            }
          >
            {RapprPrezzo(params.row.diff_costo_acq_garepub)}
          </div>
        );
      }
      return <div>{params.row.diff_costo_acq_garepub}</div>;
    },
  },
  {
    field: "diff_perc_garepub",
    headerName: "DIFF. %",
    headerAlign: "center",
    align: "center",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNamePrize(params.row.diff_perc_garepub),
    flex: 0.5,
    sortable: false,
    renderCell: (params) => {
      if (params.row?.diff_perc_garepub !== "") {
        return (
          <div
            style={
              params.row.title === "TOTALE GENERALE"
                ? { fontWeight: "bold", fontSize: 16 }
                : { fontSize: 16 }
            }
          >
            {RapprPerc(params.row?.diff_perc_garepub)}
          </div>
        );
      }
      return <div></div>;
    },
  },
];

const renderStyledCellFileF = (params, valueFormatter) => (
  <div
    style={{
      fontSize: 16,
      fontWeight: params.row.title === "TOTALE" ? "bold" : "normal",
    }}
  >
    {valueFormatter
      ? valueFormatter(params.row)
      : params.row?.[params.field] ?? ""}
  </div>
);

const FILEF_TEMP_RESUME_COLUMNS = [
  {
    field: "title",
    headerName: "DETTAGLIO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    flex: 0.65,
    sortable: false,
    renderCell: (params) => renderStyledCellFileF(params),
  },
  {
    field: "n_molecole",
    headerName: "N.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    flex: 0.4,
    sortable: false,
    renderCell: (params) => renderStyledCellFileF(params),
  },
  {
    field: "costo_acq",
    headerName: "SPESA ACQ. 2024",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    flex: 1,
    sortable: false,
    renderCell: (params) =>
      renderStyledCellFileF(
        params,
        (row) => RapprPrezzo(row?.costo_acq) || row?.costo_acq
      ),
  },
  {
    field: "costo_minimo",
    headerName: "PREV. SPESA 2025",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    flex: 1,
    sortable: false,
    renderCell: (params) =>
      renderStyledCellFileF(
        params,
        (row) => RapprPrezzo(row?.costo_minimo) || row?.costo_minimo
      ),
  },
  {
    field: "differenza",
    headerName: "DIFFERENZA",
    headerAlign: "center",
    headerClassName: "theme_header",
    align: "right",
    flex: 0.8,
    sortable: false,
    cellClassName: (params) =>
      handleCellClassNamePrize(
        calcoloDifferenzaCosto(params.row?.costo_acq, params.row?.costo_minimo)
      ),
    renderCell: (params) =>
      renderStyledCellFileF(params, (row) =>
        RapprPrezzo(calcoloDifferenzaCosto(row?.costo_acq, row?.costo_minimo))
      ),
  },
  {
    field: "diff_perc",
    headerName: "DIFF. %",
    headerAlign: "center",
    headerClassName: "theme_header",
    align: "center",
    flex: 0.45,
    sortable: false,
    cellClassName: (params) => handleCellClassNamePrize(params.row?.diff_perc),
    renderCell: (params) =>
      renderStyledCellFileF(params, (row) => RapprPerc(row?.diff_perc)),
  },
];

export {
  ABB_RESUME_COLUMNS,
  ABB_SELECTED_LIST_COLUMNS,
  ABB_SELECTION_ACQ_COLUMNS,
  ABB_SELECTION_OFFERS_COLUMNS,
  ABB_SELECTION_GAREPUB_COLUMNS,
  ABB_TOWAIT_COLUMNS,
  ABB_TOWAIT_ATC_COLUMNS,
  ABB_SUPP_RESUME_COLUMNS,
  ABB_RESUME_COLUMNS_IPOTESI,
  ABB_SELECTION_ACQ_COLUMNS_BIS,
  FILEF_TEMP_RESUME_COLUMNS,
};
