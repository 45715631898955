import axios from "axios";

//se sviluppo
// const urlOffersResume = "http://localhost:3001/api/offersresume";
//se produzione
const urlOffersResume = "/api/offersresume";

let token = null;

const setToken = (newToken) => {
  token = "bearer " + newToken;
  console.log("TOKEN SETTATO:", token); // 📌 Verifica se il token viene impostato correttamente
};

// 📌 Modificato: ora recupera gli acquistati dalla MeshRoom
const getOfferaResumeByMeshRoomId = (meshRoomId) => {
  return axios.get(`${urlOffersResume}/meshroom/${meshRoomId}`);
};

const getOffersResumetByOffersResumetId = (offersResumeId) => {
  return axios.get(`${urlOffersResume}/singleoffersresume/${offersResumeId}`);
};

// //Ricezione
// const getOfferaResumeByUserId = (userId) => {
//   let newUrl = urlOffersResume + "/user/" + userId;
//   return axios.get(newUrl);
// };

// const getOffersResumetByOffersResumetId = (offersResumeId) => {
//   let newUrl = urlOffersResume + "/singleoffersresume/" + offersResumeId;
//   return axios.get(newUrl);
// };

//Invio
const salvaOffersResume = async (meshRoomId, newOffersResume) => {
  try {
    const config = {
      headers: { Authorization: token },
    };
    const res = await axios.post(
      `${urlOffersResume}/${meshRoomId}`,
      newOffersResume,
      config
    );
    return res;
    // const response = await axios.post(urlOffersResume, newOffersResume, config);

    // return response;
  } catch (error) {
    console.error(
      "Errore nella richiesta POST:",
      error.response?.data || error.message
    );
    return "error";
  }
};

const offersResumeService = {
  getOfferaResumeByMeshRoomId,
  getOffersResumetByOffersResumetId,
  salvaOffersResume,
  setToken,
};

export default offersResumeService;
