import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const EXP_OFF_CONFRONTO_PREZZO = ({ data, fornitore }) => {
  const [loadingEXPData, setLoadingEXPData] = useState(false);

  const createDownLoadData = async () => {
    setLoadingEXPData(true);
    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "CONFRONTO PREZZI - " + fornitore.ragione_sociale + ".xlsx"
      );
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      setLoadingEXPData(false);
    } catch (error) {
      // console.log("Si è verificato un errore durante l'esportazione: ", error);
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = () => {
    let table1 = [
      {
        A: "AIC",
        B: "NOME COMMERCIALE",
        C: "NUMERO PEZZI",
        D: "NUMERO PEZZI FARMADATI",
        E: "PREZZO UNITARIO",
        F: "PREZZO CONFEZIONE",
        G: "PREZZO CONF. CALCOLATO",
      },
    ];

    let CONF_NOMATCH = [];
    let UNITA_NOMATCH = [];

    let rowNum = 0;

    data.forEach((row) => {
      if (
        parseFloat(row?.prezzo_confezione_calcolato).toFixed(2) !==
        parseFloat(row?.prezzo_pub_confezione).toFixed(2)
      ) {
        CONF_NOMATCH.push(rowNum + 2);
      }

      if (
        Math.floor(parseFloat(row?.numero_pezzi_farmadati)) !==
        Math.floor(parseFloat(row?.numero_unita_confezione))
      ) {
        UNITA_NOMATCH.push(rowNum + 2);
      }

      table1.push({
        A: row?.aic,
        B: row?.nome_commerciale,
        C: row?.numero_unita_confezione,
        D: isNaN(parseFloat(row?.numero_pezzi_farmadati))
          ? "X"
          : parseFloat(row?.numero_pezzi_farmadati),
        E: isNaN(parseFloat(row?.prezzo_unitario))
          ? "X"
          : parseFloat(row?.prezzo_unitario),
        F: isNaN(parseFloat(row?.prezzo_pub_confezione))
          ? "X"
          : parseFloat(row?.prezzo_pub_confezione).toFixed(2),
        G: isNaN(parseFloat(row?.prezzo_confezione_calcolato))
          ? "X"
          : parseFloat(row?.prezzo_confezione_calcolato),
      });

      rowNum = rowNum + 1;
    });

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "CONFRONTO PREZZI");

    const workbookBlob = workbook2blob(wb);

    const dataInfo = {
      theadRange: `A1:G1`,
      CONF_NOMATCH: CONF_NOMATCH,
      UNITA_NOMATCH: UNITA_NOMATCH,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Calibri",
          horizontalAlignment: "center",
          border: true,
        });

        sheet.column("A").width(14);
        sheet.column("B").width(40).style({ horizontalAlignment: "left" });
        sheet
          .column("C")
          .width(20)
          .style({ horizontalAlignment: "center", numberFormat: "#,##0" });
        sheet
          .column("D")
          .width(25)
          .style({ horizontalAlignment: "center", numberFormat: "#,##0" });
        sheet
          .column("E")
          .width(20)
          .style({ horizontalAlignment: "right", numberFormat: "#,##0.00000" });

        sheet
          .column("F")
          .width(20)
          .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
        sheet
          .column("G")
          .width(25)
          .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });

        dataInfo.CONF_NOMATCH.forEach((VAL) => {
          sheet.cell(VAL, 4).style({
            fontColor: "ff0000",
            bold: false,
          });
        });

        dataInfo.UNITA_NOMATCH.forEach((VAL) => {
          sheet.cell(VAL, 6).style({
            fontColor: "ff0000",
            bold: false,
          });
        });

        if (dataInfo.theadRange) {
          sheet.range(dataInfo.theadRange).style({
            fill: "1976d2",
            fontColor: "ffffff",
            bold: false,
            horizontalAlignment: "center",
          });
        }
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
  return (
    <>
      <LoadingButton
        variant="contained"
        color="bluetest"
        loading={loadingEXPData}
        loadingPosition="start"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        EXP CONFRONTO PREZZI
      </LoadingButton>
    </>
  );
};

export default EXP_OFF_CONFRONTO_PREZZO;
