export const boxStyles = {
  height: "450px",
  width: "100%",
  paddingLeft: "5%",
  paddingRight: "5%",
  paddingTop: "1%",
  "& .theme_header": {
    backgroundColor: "#1976d2",
    color: "#fff",
    fontFamily: "Calibri",
    fontSize: "17px",
  },
  "& .theme_cell": {
    fontFamily: "Calibri",
    fontSize: "18px",
    border: "1px solid",
  },
  "& .theme_cell_nomatch": {
    fontFamily: "Calibri",
    fontSize: "18px",
    color: "#ff1a00",
    border: "1px solid #000",
  },
  "& .theme_cell_error": {
    fontFamily: "Calibri",
    backgroundColor: "#f00",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#fff",
    border: "1px solid #000",
  },
};

// export const errorBoxStyles = {
//   height: "450px",
//   width: "100%",
//   paddingLeft: "5%",
//   paddingRight: "5%",
//   paddingTop: "1%",
//   "& .theme_header": {
//     backgroundColor: "#1976d2",
//     color: "#fff",
//     fontFamily: "Calibri",
//     fontSize: "17px",
//   },
//   "& .theme_cell": {
//     fontFamily: "Calibri",
//     fontSize: "18px",
//     border: "1px solid",
//   },

// };
