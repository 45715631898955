import axios from "axios";
// import codOspListService from "./codOspListService";
// import garePubListService from "./garePubListService";

// se sviluppo
// const urlAbbinamento = "http://localhost:3001/api/abbinamento";
// se produzione
const urlAbbinamento = "/api/abbinamento";

let token = null;

const setToken = (newToken) => {
  token = "bearer " + newToken;
  console.log("TOKEN SETTATO:", token); // 📌 Verifica se il token viene impostato correttamente
};

// 📌 Modificato: ora recupera gli acquistati dalla MeshRoom
const getAbbinamentoByMeshRoomId = (meshRoomId) => {
  return axios.get(`${urlAbbinamento}/meshroom/${meshRoomId}`);
};

const getAbbinamentoByAbbinamentoId = (abbinamentoId) => {
  return axios.get(`${urlAbbinamento}/singleabbinamento/${abbinamentoId}`);
};

// const getAbbinamentoByUserId = (userId) => {
//   let newUrl = urlAbbinamento + "/user/" + userId;
//   return axios.get(newUrl);
// };

// const getAbbinamentoByAbbinamentoId = (abbinamentoId) => {
//   let newUrl = urlAbbinamento + "/singleabbinamento/" + abbinamentoId;
//   return axios.get(newUrl);
// };

// // Ricezione
// const getAbbinamento = () => {
//   let newUrl = urlAbbinamento + "/allabbinamento";
//   return axios.get(newUrl);
// };

// Invio
const salvaAbbinamento = async (meshRoomId, newAbbinamento) => {
  try {
    const config = {
      headers: { Authorization: token },
    };
    const res = await axios.post(
      `${urlAbbinamento}/${meshRoomId}`,
      newAbbinamento,
      config
    );

    // const res = await axios.post(urlAbbinamento, newAbbinamento, config);
    return res;
  } catch (error) {
    console.error(
      "Errore nella richiesta POST:",
      error.response?.data || error.message
    );
    return "error";
  }
};

const aggiornaAbbinamento = async (newAbbinamento, AbbinamentoId) => {
  const config = {
    headers: { Authorization: token },
  };

  let newUrl = `${urlAbbinamento}/updateabbinamento/${AbbinamentoId}`;

  try {
    const res = await axios.patch(newUrl, newAbbinamento, config);
    return res.data;
  } catch (error) {
    console.error("Errore durante l'aggiornamento dell'abbinamento:", error);

    if (error.response) {
      // Errore restituito dal server
      throw new Error(error.response.data.message || "Errore di aggiornamento");
    } else if (error.request) {
      // Nessuna risposta ricevuta dal server
      throw new Error("Nessuna risposta dal server. Controlla la connessione.");
    } else {
      // Altro errore imprevisto
      throw new Error("Errore sconosciuto durante l'aggiornamento.");
    }
  }
};

const abbinamentoService = {
  getAbbinamentoByMeshRoomId,
  getAbbinamentoByAbbinamentoId,
  // getAbbinamento,
  salvaAbbinamento,
  aggiornaAbbinamento,
  setToken,
};

export default abbinamentoService;
