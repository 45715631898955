import React, { useState } from "react";
import "../../App.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoginService from "../../services/login";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  // Funzione per gestire il cambio password
  const handlePasswordChange = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const token = LoginService.getToken();
      const user = LoginService.getUser();

      const response = await axios.put(
        // "http://localhost:3001/api/users/changePassword",
        "/api/users/changePassword",

        {
          userId: user.id,
          oldPassword,
          newPassword,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setMessage("Password aggiornata con successo!");
      setErrors({});
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");

      // Logout automatico dopo il cambio password
      setTimeout(() => {
        LoginService.logout();
        navigate("/login");
      }, 2000);
    } catch (error) {
      setMessage("");
      if (error.response) {
        if (error.response.status === 401) {
          setErrors({ oldPassword: "Vecchia password errata" });
        } else if (error.response.status === 400) {
          setErrors({ newPassword: error.response.data.error });
        } else {
          setMessage("Errore nel cambio password. Riprova.");
        }
      } else {
        setMessage("Errore di connessione. Contatta l'assistenza.");
      }
    }
  };

  // Funzione di validazione della password
  const validateForm = () => {
    const newErrors = {};

    if (!oldPassword) newErrors.oldPassword = "Inserisci la vecchia password";
    if (!newPassword) newErrors.newPassword = "Inserisci la nuova password";

    // Controllo sicurezza password
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;

    if (!passwordRegex.test(newPassword)) {
      newErrors.newPassword =
        "La password deve contenere almeno 10 caratteri, una lettera maiuscola, un numero e un carattere speciale.";
    }

    // Controllo conferma password
    if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = "Le password non coincidono";
    }

    return newErrors;
  };

  return (
    <div className="login">
      <Box
        component="form"
        className="formContainer"
        onSubmit={handlePasswordChange}
      >
        <p className="loginHeader">CAMBIO PASSWORD</p>

        <div className="inputField">
          <TextField
            id="oldPassword"
            label="Vecchia Password"
            type="password"
            placeholder="Inserisci la vecchia password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            error={!!errors.oldPassword}
            helperText={errors.oldPassword}
          />
        </div>

        <div className="inputField">
          <TextField
            id="newPassword"
            label="Nuova Password"
            type="password"
            placeholder="Inserisci la nuova password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            error={!!errors.newPassword}
            helperText={errors.newPassword}
          />
        </div>

        <div className="inputField">
          <TextField
            id="confirmPassword"
            label="Conferma Nuova Password"
            type="password"
            placeholder="Conferma la nuova password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
          />
        </div>

        <div className="submitButton">
          <Button variant="contained" color="success" type="submit">
            Conferma
          </Button>
        </div>
      </Box>

      {message && (
        <p
          style={{
            paddingTop: "2%",
            fontFamily: "Calibri",
            fontWeight: "bold",
            color: "green",
            fontSize: 18,
            textAlign: "center",
          }}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default ChangePassword;
