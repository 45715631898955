const FILEF_INSERTED_COLUMNS = [
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "center",
    flex: 0.3,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.aic}</div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "center",
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.nome_commerciale}</div>
    ),
  },
  {
    field: "nome_commerciale_farmadati",
    headerName: "NOME FARMADATI",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => {
      if (params.row.nome_commerciale_farmadati !== "NO MATCH") {
        return "theme_cell";
      } else {
        return "theme_cell_nomatch";
      }
    },
    sortable: false,
    align: "center",
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.nome_commerciale_farmadati}
      </div>
    ),
  },
];

const FILEF_ERRORS_COLUMNS = [
  {
    field: "id_temp",
    headerName: "RIGA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "center",
    flex: 0.3,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.id_temp + 2}</div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => {
      if (params.row?.flag_aic_error) {
        return "theme_cell_error";
      } else {
        return "theme_cell";
      }
    },
    sortable: false,
    align: "center",
    flex: 0.6,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.aic}</div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => {
      if (params.row?.flag_name_error) {
        return "theme_cell_error";
      } else {
        return "theme_cell";
      }
    },
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.nome_commerciale}</div>
    ),
  },
];

export { FILEF_INSERTED_COLUMNS, FILEF_ERRORS_COLUMNS };
