import * as XLSX from "xlsx";

import equivalenzeService from "../../services/equivalenze";

const acceptableFileName = ["xlsx", "xls"];

const checkFileName = (name) => {
  return acceptableFileName.includes(name.split(".").pop().toLowerCase());
};

const readDataFromFile = (data) => {
  const wb = XLSX.read(data, { type: "array" });
  const worksheet = wb.Sheets[wb.SheetNames[0]];
  const jsonData = XLSX.utils.sheet_to_json(worksheet);
  return jsonData;
};

const createFileData = (e) => {
  let newFileData = [];
  let id_data_temp = 0;

  e.forEach((Sel) => {
    newFileData.push({
      id_temp: id_data_temp++,
      aic: Sel.AIC || "",
      nome_commerciale: Sel["NOME COMMERCIALE"] || "",
    });
  });

  return newFileData;
};

const validateFileData = (newFileData) => {
  let errors = {
    aic_error: [],
    name_error: [],
  };

  newFileData.forEach((Sel) => {
    if (Sel.aic === "" || Sel.aic.toString().trim().length > 9)
      errors.aic_error.push(Sel);
    if (Sel.nome_commerciale === "") errors.name_error.push(Sel);
  });

  errors.flag_error =
    errors.aic_error.length > 0 || errors.name_error.length > 0;
  return errors;
};

const formatCorrectData = (newFileData) => {
  newFileData.forEach((Sel) => {
    if (Sel.aic.toString().length < 9) {
      Sel.aic = Sel.aic.toString().padStart(9, "0");
    } else {
      Sel.aic = Sel.aic.toString();
    }
  });

  return newFileData;
};

const handleErrors = (errors, setImportErrors) => {
  let newErrorsTEMP = [];
  const errorTypes = ["aic_error", "name_error"];

  errorTypes.forEach((type) => {
    errors[type].forEach((ERR_SEL) => {
      let temp = {
        id_temp: ERR_SEL.id_temp,
        aic: ERR_SEL.aic,
        nome_commerciale: ERR_SEL.nome_commerciale,
      };
      temp[`flag_${type}`] = true;
      newErrorsTEMP.push(temp);
    });
  });

  const commonElements = findCommonElementsWithFlags(newErrorsTEMP);
  setImportErrors(commonElements);
};

const findCommonElementsWithFlags = (data) => {
  const idMap = {};
  data.forEach((item) => {
    if (!idMap[item.id_temp]) {
      idMap[item.id_temp] = { ...item };
    } else {
      Object.keys(item).forEach((key) => {
        if (typeof item[key] === "boolean" && key.startsWith("flag_")) {
          idMap[item.id_temp][key] = idMap[item.id_temp][key] || item[key];
        }
      });
    }
  });
  return Object.values(idMap);
};

const handleFileValutation = async (
  data,
  setFileValutation,
  setNoMatchFlag,
  setNoMatch,
  setConfirmedFile,
  setLoadingFileUpload
) => {
  setLoadingFileUpload(true);
  let dataFileTEMP = [...data];
  let foundedAIC = [];
  let matchFarmadati = [];
  let AICList = [];

  await dataFileTEMP.map((SEL) => {
    AICList.push(SEL.aic);
    return dataFileTEMP;
  });

  const uniqueAICList = [...new Set(AICList)];

  equivalenzeService.getAIC(uniqueAICList).then((res) => {
    foundedAIC = res;

    let matchList = [];
    dataFileTEMP.map((Selected, index) => {
      let tempFound = [];
      foundedAIC.map((element) => {
        if (element.data.length > 0 && element.data[0].aic === Selected.aic) {
          tempFound = element.data[0];
        }
        return foundedAIC;
      });

      let matchListTemp = {
        inserted_excell: Selected,
        found_farmadati: tempFound,
      };

      matchList.push(matchListTemp);

      return dataFileTEMP;
    });

    let id_filef_temp = 0;

    matchList.map((Selected) => {
      let temp = [];
      if (
        Selected.found_farmadati.length === 0 ||
        Selected.inserted_excell.aic === undefined
      ) {
        temp = {
          id_temp: id_filef_temp,
          aic: Selected?.inserted_excell.aic.toString(),
          nome_commerciale: Selected?.inserted_excell.nome_commerciale,

          aic_farmadati: "NO MATCH",
          nome_commerciale_farmadati: "NO MATCH",
          // atc_farmadati: "NO MATCH",
          // atc_complementare_farmadati: "NO MATCH",
          // codice_gruppo_ospedaliero: "NO MATCH",
          // descrizione_gruppo_ospedaliero: "NO MATCH",
          // descrizione_atc_farmadati: "NO MATCH",
          // principio_attivo_farmadati: "NO MATCH",
          // forma_farmaceutica: "NO MATCH",
          // dosaggio: "NO MATCH",
          // fascia: "NO MATCH",
          // iva: 0,
        };
      } else {
        temp = {
          id_temp: id_filef_temp,

          aic: Selected?.inserted_excell.aic.toString(),
          nome_commerciale: Selected?.inserted_excell.nome_commerciale,

          aic_farmadati: Selected?.found_farmadati.aic,
          nome_commerciale_farmadati: Selected?.found_farmadati.descrizione_aic,
          // atc_farmadati: Selected?.found_farmadati.atc,
          // atc_complementare_farmadati:
          //   Selected?.found_farmadati.atc_complementare,
          // codice_gruppo_ospedaliero:
          //   Selected?.found_farmadati.codice_gruppo_ospedaliero,
          // descrizione_gruppo_ospedaliero:
          //   Selected?.found_farmadati.descrizione_gruppo_ospedaliero,
          // descrizione_atc_farmadati: Selected?.found_farmadati.descrizione_atc,
          // principio_attivo_farmadati:
          //   Selected?.found_farmadati.principio_attivo,
          // forma_farmaceutica: Selected?.found_farmadati.forma_farmaceutica,
          // dosaggio: Selected?.found_farmadati.dosaggio,
          // fascia: Selected?.found_farmadati.fascia,
          // iva: Selected?.found_farmadati.iva,
        };
      }
      matchFarmadati.push(temp);
      id_filef_temp = id_filef_temp + 1;

      return matchList;
    });

    let noMatch = [];
    let completeOBJ = [];

    matchFarmadati.map((Selected) => {
      if (Selected.aic_farmadati === "NO MATCH") {
        noMatch.push(Selected);
      } else {
        completeOBJ.push(Selected);
      }

      return matchFarmadati;
    });

    const finalOBJ = noMatch.concat(completeOBJ);

    if (noMatch.length > 0) {
      setNoMatchFlag(true);
    }

    setFileValutation(finalOBJ);
    setNoMatch(noMatch);
    setConfirmedFile(true);
    setLoadingFileUpload(false);
  });
};

const handleUploadDataControl = async (
  e,
  setImportErrors,
  setFileValutation,
  setNoMatchFlag,
  setNoMatch,
  setConfirmedFile,
  setLoadingFileUpload
) => {
  let newFileData = createFileData(e);

  let errors = validateFileData(newFileData);

  if (!errors.flag_error) {
    newFileData = await formatCorrectData(newFileData);
    await handleFileValutation(
      newFileData,
      setFileValutation,
      setNoMatchFlag,
      setNoMatch,
      setConfirmedFile,
      setLoadingFileUpload
    );
    setImportErrors(null);
  } else {
    handleErrors(errors, setImportErrors);
  }

  return newFileData;
};

const handleFile = async (
  e,
  setImportErrors,
  setFileValutation,
  setNoMatchFlag,
  setNoMatch,
  setConfirmedFile,
  setLoadingFileUpload
) => {
  const myfile = e.target.files[0];
  if (!myfile || !checkFileName(myfile.name)) {
    alert("Invalid file type!");
    return;
  }

  const data = await myfile.arrayBuffer();
  const mySheetData = readDataFromFile(data);
  handleUploadDataControl(
    mySheetData,
    setImportErrors,
    setFileValutation,
    setNoMatchFlag,
    setNoMatch,
    setConfirmedFile,
    setLoadingFileUpload
  );
};

export { handleFile };
