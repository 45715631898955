import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

//Funzioni
import { calcoloCosto } from "../../functions/FUN_CALCOLI";

const EXP_ABB_SELECTED_TAB = ({ data, abb_name, acq_year, listino_year }) => {
  // console.log("data", data);
  // console.log("abb_name", abb_name);

  const [loadingEXPData, setLoadingEXPData] = useState(false);

  const calcoloDifferenzaCosto = (CostoACQ, CostoOffSel) => {
    let diff_temp = CostoOffSel - CostoACQ;
    return Math.round(diff_temp * 100) / 100;
  };

  const createDownLoadData = async () => {
    setLoadingEXPData(true);

    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", "TABELLA ABBINATI.xlsx");
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      setLoadingEXPData(false);
    } catch (error) {
      console.log("Si è verificato un errore durante l'esportazione: ", error);
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = async () => {
    let table1 = [
      {
        A: "PRINC. ATTIVO",
        B: "TIPOLOGIA",
        C: "DIFF. SPESA 2025",
        D: "AIC 2025",
        E: "ATC COMP. 2025",
        F: "NOME COMMERCIALE 2025",
        G: "FORNITORE 2025",
        H: "PREZZO 2025",
        I: "SPESA 2025",
        J: "AIC 2024",
        K: "ATC COMP. 2024",
        L: "NOME COMMERCIALE 2024",
        M: "FORNITORE 2024",
        N: "QUANTITA'",
        O: "SPESA 2024",
        P: "PREZZO MEDIO 2024",
      },
    ];

    let objTEMP = [];

    await data.forEach((row) => {
      let diffCosto = calcoloDifferenzaCosto(
        calcoloCosto(row.prezzo_unitario, row.quantita),
        calcoloCosto(
          row.spec_checked.prezzo_unitario,
          row.spec_checked.quantita
        )
      );

      objTEMP.push({
        A: row.principio_attivo,
        B: row.descrizione_gruppo_ospedaliero,
        C: diffCosto,
        D: row.spec_checked.aic,
        E: row.spec_checked.atc_complementare,
        F: row.spec_checked.nome_commerciale,
        G: row.spec_checked.ragione_sociale,
        H: row.spec_checked.prezzo_unitario,
        I: calcoloCosto(
          row.spec_checked.prezzo_unitario,
          row.spec_checked.quantita
        ),
        J: row.aic,
        K: row.atc_complementare,
        L: row.nome_commerciale,
        M: row.fornitore.ragione_sociale,
        N: row.quantita,
        O: calcoloCosto(row.prezzo_unitario, row.quantita),
        P: row.prezzo_unitario,
      });
    });

    await objTEMP.sort((a, b) => {
      let x = a.C;
      let y = b.C;

      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    let countRisp = 0;
    let countNeutral = 0;

    await objTEMP.forEach((row) => {
      if (row.C < 0) {
        countRisp = countRisp + 1;
      } else if (row.C === 0) {
        countNeutral = countNeutral + 1;
      }
    });

    table1 = table1.concat(objTEMP);

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "TAB ABBINATI");

    const workbookBlob = workbook2blob(wb);
    let dataInfo = {};
    if (countRisp + countNeutral < objTEMP.length) {
      if (countRisp > 0 && countNeutral > 0) {
        dataInfo = {
          theadRange1: `A1:I1`,
          theadRange2: `J1:P1`,
          rispRange: `C2:C${countRisp + 1}`,
          neutralRange: `C${countRisp + 2}:C${countRisp + countNeutral + 1}`,
          spesaRange: `C${countRisp + countNeutral + 2}:C${objTEMP.length + 1}`,
        };
      } else if (countRisp === 0 && countNeutral > 0) {
        dataInfo = {
          theadRange1: `A1:I1`,
          theadRange2: `J1:P1`,
          neutralRange: `C2:C${countNeutral + 1}`,
          spesaRange: `C${countNeutral + 2}:C${objTEMP.length + 1}`,
        };
      } else if (countRisp > 0 && countNeutral === 0) {
        dataInfo = {
          theadRange1: `A1:I1`,
          theadRange2: `J1:P1`,
          rispRange: `C2:C${countRisp + 1}`,
          spesaRange: `C${countRisp + 2}:C${objTEMP.length + 1}`,
        };
      } else if (countRisp > 0 && countNeutral === 0) {
        dataInfo = {
          theadRange1: `A1:I1`,
          theadRange2: `J1:P1`,
          spesaRange: `C2:C${objTEMP.length + 1}`,
        };
      }
    } else {
      dataInfo = {
        theadRange1: `A1:I1`,
        theadRange2: `J1:P1`,
        rispRange: `C2:C${countRisp + 1}`,
        neutralRange: `C${countRisp + 2}:C${countRisp + countNeutral + 1}`,
      };
    }

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Calibri",
          horizontalAlignment: "left",
          border: true,
        });

        sheet.column("A").width(28);
        sheet.column("B").width(50);
        sheet
          .column("C")
          .width(17)
          .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
        sheet.column("D").width(16).style({ horizontalAlignment: "center" });
        sheet.column("E").width(16).style({ horizontalAlignment: "center" });
        sheet.column("F").width(28);
        sheet.column("G").width(28);
        sheet.column("H").width(17).style({ horizontalAlignment: "right" });
        sheet.column("I").width(17).style({ horizontalAlignment: "right" });
        sheet.column("J").width(16).style({ horizontalAlignment: "center" });
        sheet.column("K").width(16).style({ horizontalAlignment: "center" });
        sheet.column("L").width(28);
        sheet.column("M").width(28);
        sheet.column("N").width(15).style({ horizontalAlignment: "right" });
        sheet.column("O").width(17).style({ horizontalAlignment: "right" });
        sheet.column("P").width(22).style({ horizontalAlignment: "right" });

        if (dataInfo.theadRange1) {
          sheet.range(dataInfo.theadRange1).style({
            fill: "1976d2",
            fontColor: "ffffff",
            bold: false,
            horizontalAlignment: "center",
          });
        }
        if (dataInfo.theadRange2) {
          sheet.range(dataInfo.theadRange2).style({
            fill: "1566b7",
            fontColor: "ffffff",
            bold: false,
            horizontalAlignment: "center",
          });
        }
        if (dataInfo.rispRange) {
          sheet.range(dataInfo.rispRange).style({
            fill: "c6efce",
            fontColor: "006100",
            bold: false,
            border: true,
          });
        }
        if (dataInfo.neutralRange) {
          sheet.range(dataInfo.neutralRange).style({
            bold: false,
            border: true,
          });
        }
        if (dataInfo.spesaRange) {
          sheet.range(dataInfo.spesaRange).style({
            fill: "ffc7ce",
            fontColor: "9c0006",
            bold: false,
            border: true,
          });
        }
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <>
      <LoadingButton
        variant="contained"
        color="bluetest"
        loading={loadingEXPData}
        loadingPosition="start"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        EXP TAB SELEZIONE
      </LoadingButton>
    </>
  );
};

export default EXP_ABB_SELECTED_TAB;
