import axios from "axios";

//se sviluppo
// const urlOffersGroup = "http://localhost:3001/api/offersgroup";
//se produzione
const urlOffersGroup = "/api/offersgroup";

let token = null;

const setToken = (newToken) => {
  token = "bearer " + newToken;
  console.log("TOKEN SETTATO:", token); // 📌 Verifica se il token viene impostato correttamente
};

// 📌 Modificato: ora recupera gli acquistati dalla MeshRoom
const getOffersGroupByMeshRoomId = (meshRoomId) => {
  return axios.get(`${urlOffersGroup}/meshroom/${meshRoomId}`);
};

const getOffersGroupByOffersGroupId = (offersGroupId) => {
  return axios.get(`${urlOffersGroup}/singleoffersgroup/${offersGroupId}`);
};

// //Ricezione
// const getOffersGroupByUserId = (userId) => {
//   let newUrl = urlOffersGroup + "/user/" + userId;
//   return axios.get(newUrl);
// };

// const getOffersGroupByOffersGroupId = (offersGroupId) => {
//   let newUrl = urlOffersGroup + "/singleoffersgroup/" + offersGroupId;
//   return axios.get(newUrl);
// };

//Invio
const salvaOffersGroup = async (meshRoomId, newOffersGroup) => {
  try {
    const config = {
      headers: { Authorization: token },
    };
    const res = await axios.post(
      `${urlOffersGroup}/${meshRoomId}`,
      newOffersGroup,
      config
    );
    return res;
    // const res = await axios.post(urlOffersGroup, newOffersGroup, config);
    // return res;
  } catch (error) {
    console.error(
      "Errore nella richiesta POST:",
      error.response?.data || error.message
    );
    return "error";
  }
};

const aggiornaOffersGroup = async (newOffersGroup, OffersGroupId) => {
  const config = {
    headers: { Authorization: token },
  };

  let newUrl = `${urlOffersGroup}/updateoffersgroup/${OffersGroupId}`;

  const res = await axios.patch(newUrl, newOffersGroup, config);
  return res;
};

// // Nuova funzione per il caricamento del PDF
// const uploadPDFOfferta = async (formData) => {
//   let newUrl = urlOffersGroup + "/upload-pdf";

//   const config = {
//     headers: {
//       "Content-Type": "multipart/form-data",
//       Authorization: token, // Aggiungi il token se necessario
//     },
//   };

//   try {
//     const res = await axios.post(newUrl, formData, config);
//     return res.data.pdfPath;
//   } catch (error) {
//     console.error("Error uploading PDF", error);
//     throw error;
//   }
// };

const offersGroupService = {
  getOffersGroupByMeshRoomId,
  getOffersGroupByOffersGroupId,
  salvaOffersGroup,
  aggiornaOffersGroup,
  // uploadPDFOfferta,
  setToken,
};

export default offersGroupService;
