import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "@mui/material/Button";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import BeatLoader from "react-spinners/BeatLoader";

import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FILEF_SAVE_CONFIRM_POPUP = ({
  savePopUpOpen,
  handleSavePopUpClose,
  yearSel,
  loadingFinalSave,
  handleConfirmSave,
  saveErrorFLAG,
}) => {
  return (
    <>
      <Dialog
        open={savePopUpOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="lg"
        keepMounted
        onClose={handleSavePopUpClose}
        PaperProps={{
          style: {
            backgroundColor: "#fff",
            textAlign: "center",
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogTitle
            sx={{
              fontFamily: "Calibri",
              color: "#1976d2",
              fontSize: 25,
              textAlign: "center",
            }}
          >
            <b>CONFERMI IL SALVATAGGIO DEI CODICI AIC INSERITI?</b>
            <br />I codici verranno inseriti nell'anno{" "}
            <b>{yearSel ? yearSel.nome : "XXXX"}</b>
            <br />
            Sarà possibile incrociarli con un file di ACQUISTATO nella sezione
            ANALISI FILE F
          </DialogTitle>

          <Button
            variant="contained"
            component="label"
            color="success"
            startIcon={<CheckOutlinedIcon />}
            onClick={() => handleConfirmSave()}
          >
            CONFERMA
          </Button>
          {loadingFinalSave && (
            <div style={{ textAlign: "center", paddingTop: "1%" }}>
              <BeatLoader
                color={"#1976d2"}
                loading={loadingFinalSave}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                CARICAMENTO...
              </h6>
            </div>
          )}
          {saveErrorFLAG && (
            <div
              style={{
                fontFamily: "Calibri",
                fontSize: 27,
                color: "red",
                fontWeight: "bold",
              }}
            >
              ERRORE NEL SALVATAGGIO
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
