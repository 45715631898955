import React, { useState, useEffect } from "react";
import "../../../App.css";

import acquistatoService from "../../../services/acquistato";
import abbinamentoService from "../../../services/abbinamento";
import garepubService from "../../../services/garapub";
import offersResumeService from "../../../services/offers_resume";
import filefService from "../../../services/filef";

import AbbinatoSelStrutture from "./AbbinatoSelStrutture";
import AbbinatoGenerate from "./AbbinatoGenerate";

import AbbinatoViewResume from "./AbbinatoViewResume";

import SelectionMain from "./SELECTION_PAGES/SelectionMain";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";

import abb_search_options from "../../../utils/SEARCH_OPTIONS/abb_search_options.json";

import SAVE_ABB_POPUP from "../../../popup/ABB_POPUPS/SAVE_ABB_POPUP";
import ABBINATO_SEARCH_POPUP from "../../../popup/SEARCH_POPUP/ABBINATO_SEARCH_POPUP";

//FUNZIONI
import { calcoloCosto } from "../../../functions/FUN_CALCOLI";

import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
dayjs.extend(dayjsPluginUTC);

const theme = createTheme({
  palette: {
    bluetest: {
      main: "#1976d2",
      light: "#328ce7",
      dark: "#1566b7",
      contrastText: "#fff",
    },
    redtest: {
      main: "#ff0000",
      light: "#ff3333",
      dark: "#cc0000",
      contrastText: "#fff",
    },
  },
});

const AbbinatoMain = ({ user }) => {
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const [newABBFlag, setNewABBFlag] = useState(false);

  const [loadingPurchasedList, setLoadingPurchasedList] = useState(true);
  const [loadingOfferList, setLoadingOfferList] = useState(true);
  const [loadingAbbinatiList, setLoadingAbbinatiList] = useState(true);
  const [loadingFileFList, setLoadingFileFList] = useState(true);

  const [purchasedList, setPurchasedList] = useState([]);
  const [Purchased_List_Selected, setPurchased_List_Selected] = useState(null);
  //   console.log("purchasedList", purchasedList);
  // console.log("Purchased_List_Selected", Purchased_List_Selected);
  const [purchasedDownloaded, setPurchasedDownloaded] = useState(null);
  // console.log("purchasedDownloaded", purchasedDownloaded);

  const [offerList, setOfferList] = useState([]);
  const [Off_List_Selected, setOff_List_Selected] = useState(null);
  //   console.log("offerList", offerList);
  // console.log("Off_List_Selected", Off_List_Selected);

  const [abbinatiList, setAbbinatiList] = useState([]);
  const [Abb_List_Selected, setAbb_List_Selected] = useState(null);
  // console.log("abbinatiList", abbinatiList);
  //   console.log("Abb_List_Selected", Abb_List_Selected);
  const [abbinatoDownloaded, setAbbinatoDownloaded] = useState(null);
  // console.log("abbinatoDownloaded", abbinatoDownloaded);

  const [fileFList, setFileFList] = useState([]);
  const [FileF_List_Selected, setFileF_List_Selected] = useState(null);
  // console.log("fileFList", fileFList);
  //   console.log("FileF_List_Selected", FileF_List_Selected);
  // const [fileFDownloaded, setFileFDownloaded] = useState(null);
  // console.log("fileFDownloaded", fileFDownloaded);

  const [ABBINATO_VALFlag, setABBINATO_VALFlag] = useState(false);
  const [loadingABBValutation, setLoadingABBValutation] = useState(false);

  const [listaStrutture, setListaStrutture] = useState(null);
  const [loadingListaStrutture, setLoadingListaStrutture] = useState(false);
  const [struttureSelected, setStruttureSelected] = useState(null);

  const [flagConfermaStrutture, setFlagConfermaStrutture] = useState(false);

  // console.log("listaStrutture", listaStrutture);
  // console.log("struttureSelected", struttureSelected);

  //?STATI PRINCIPALI
  const [abbinatoResume, setAbbinatoResume] = useState(null);

  const [specMatchedTOT, setSpecMatchedTOT] = useState(null);
  const [specMatchedTOT_RESUME, setSpecMatchedTOT_RESUME] = useState(null);
  const [specToWait, setSpecToWait] = useState(null);
  const [specToWaitATC, setSpecToWaitATC] = useState(null);
  const [garePubBench, setGarePubBench] = useState(null);

  // console.log("abbinatoResume", abbinatoResume);
  // console.log("specMatchedTOT", specMatchedTOT);
  // console.log("specMatchedTOT_RESUME", specMatchedTOT_RESUME);

  // console.log("specToWait", specToWait);
  // console.log("specToWaitATC", specToWaitATC);
  // console.log("garePubBench", garePubBench);

  // const [fileFOBJ_TEMP, setFileFOBJ_TEMP] = useState(null);
  // console.log("fileFOBJ_TEMP", fileFOBJ_TEMP);

  const [fileFTAB_TEMP, setFileFTAB_TEMP] = useState(null);
  // console.log("fileFTAB_TEMP", fileFTAB_TEMP);

  //?STATI PER IL SALVATAGGIO
  const [finalAbbinatoToSave, setFinalAbbinatoToSave] = useState(null);
  // console.log("finalAbbinatoToSave", finalAbbinatoToSave);

  const [popUpSaveABBOpen, setPopUpSaveABBOpen] = useState(false);
  const [loadingSaveABB, setLoadingSaveABB] = useState(false);

  const [fileSavedName, setFileSavedName] = useState("");
  const [errors, setErrors] = useState({});

  //? Flag vari
  const [selectionListPageFlag, setSelectionListPageFlag] = useState(false);
  const [specSelectionPageFlag, setSpecSelectionPageFlag] = useState(false);

  const [daRichiederePageFlag, setDaRichiederePageFlag] = useState(false);
  const [dispMediciPageFlag, setDispMediciPageFlag] = useState(false);

  const [popUpExpMultipliOpen, setPopUpExpMultipliOpen] = useState(false);

  const [popUpGaraPubOpen, setPopUpGaraPubOpen] = useState(false);
  const [dettaglioGaraPub, setDettaglioGaraPub] = useState(null);

  //?SEARCH
  const [searchValue, setSearchValue] = useState("");

  const [searchedFlag, setSearchedFlag] = useState(false);
  const [filteredResults, setFilteredResults] = useState(null);

  const [searchPopupOpen, setSearchPopupOpen] = useState(false);
  const [notFoundFlag, setNotFoundFlag] = useState(false);

  const [selectedField, setSelectedField] = useState(null);
  const [fieldSuggestions, setFieldSuggestions] = useState([]);

  const [suppListNEW, setSuppListNEW] = useState(null);
  // console.log("suppListNEW", suppListNEW);
  const [selectSuppTEMP, setSelectSuppTEMP] = useState(null);

  //!SEL
  const [specTEMP, setSpecTEMP] = useState(null);

  const [specGP_TEMP, setSpecGP_TEMP] = useState(null);

  const [quantitaTOT_SPEC, setQuantitaTOT_SPEC] = useState(null);
  const [spesaTOT_SPEC, setSpesaTOT_SPEC] = useState(null);

  const [popUpChangeSelectionOpen, setPopUpChangeSelectionOpen] =
    useState(false);

  const [paramsTEMP, setParamsTEMP] = useState(null);

  const [popUpUpdateABBOpen, setPopUpUpdateABBOpen] = useState(false);
  const [loadingUpdateABB, setLoadingUpdateABB] = useState(false);

  const [garaDetailsOpen, setGaraDetailsOpen] = useState(false);
  const [gareSelected, setGareSelected] = useState(null);

  const [dettaglioFlag, setDettaglioFlag] = useState(false);
  const [quantitaTOT, setQuantitaTOT] = useState(null);
  const [spesaTOT, setSpesaTOT] = useState(null);

  const [acqSelGareList, setAcqSelGareList] = useState(null);

  const [gareSingleDetail, setGareSingleDetail] = useState(null);

  // const [gareSelected, setGareSelected] = useState(null);
  const [rowNameSEL, setRowNameSEL] = useState("");

  const [arrayGrupedTEMP, setArrayGrupedTEMP] = useState(null);

  const [arrayGrupedFlag, setArrayGrupedFlag] = useState(false);

  // const [popUpGaraPubOpen, setPopUpGaraPubOpen] = useState(false);
  // const [dettaglioGaraPub, setDettaglioGaraPub] = useState(null);

  // const [arrayGrupedTEMP_SPEC, setArrayGrupedTEMP_SPEC] = useState(null);

  const [arrayGrupedFlag_SPEC, setArrayGrupedFlag_SPEC] = useState(false);

  const handleGaraDetailsClose = () => {
    setGaraDetailsOpen(false);
    setGareSelected(null);
  };

  // const handleViewDetailsTab = (params) => {
  //   if (params.row.array_gare.length > 0) {
  //     setGareSelected(params.row.array_gare);
  //     setGaraDetailsOpen(true);
  //   }
  // };

  const handleCreateGareDetails = async (ACQ_LIST) => {
    let ACQ_LIST_TEMP = [...ACQ_LIST];

    await ACQ_LIST_TEMP.sort((a, b) => {
      let x =
        calcoloCosto(a.garePubList[0].prezzo_unitario, a.quantita) -
        calcoloCosto(a.spec_checked.prezzo_unitario, a.spec_checked.quantita);
      let y =
        calcoloCosto(b.garePubList[0].prezzo_unitario, b.quantita) -
        calcoloCosto(b.spec_checked.prezzo_unitario, b.spec_checked.quantita);

      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    const groupedData = ACQ_LIST_TEMP.reduce((acc, current) => {
      const { descrizione_gruppo_ospedaliero } = current;

      if (!acc[descrizione_gruppo_ospedaliero]) {
        acc[descrizione_gruppo_ospedaliero] = [];
      }

      acc[descrizione_gruppo_ospedaliero].push(current);
      return acc;
    }, {});

    let ArrayGruped = [];
    let id_count = 0;

    await Object.values(groupedData).map((SEL) => {
      let SPESA_TOT = 0;
      let Q_TOT = 0;

      SEL.map((SEL_BIS) => {
        SPESA_TOT =
          SPESA_TOT +
          calcoloCosto(
            SEL_BIS.spec_checked.prezzo_unitario,
            SEL_BIS.spec_checked.quantita
          );
        Q_TOT = Q_TOT + SEL_BIS.spec_checked.quantita;

        return SEL;
      });

      let temp = {
        id_temp: id_count,
        spesa_tot: Math.round(SPESA_TOT * 100) / 100,
        quantita_tot: Q_TOT,
        tipologia: SEL[0]?.descrizione_gruppo_ospedaliero,
        garePubList: SEL[0]?.garePubList,
      };

      ArrayGruped.push(temp);

      id_count = id_count + 1;

      return Object.values(groupedData);
    });

    setArrayGrupedTEMP(ArrayGruped);

    return ACQ_LIST_TEMP;
  };

  const handleViewDetailsTab = async (params) => {
    if (params.row?.title === "PREZZI MAGGIORI") {
      const NEW_OBJ = await handleCreateGareDetails(params.row.array_gare);
      setGareSelected(NEW_OBJ);
      setRowNameSEL(params.row?.title);
      setDettaglioFlag(true);
    } else if (params.row?.title === "PREZZI MINORI") {
      const NEW_OBJ = await handleCreateGareDetails(params.row.array_gare);
      setGareSelected(NEW_OBJ);
      setRowNameSEL(params.row?.title);
      setDettaglioFlag(true);
    } else if (params.row?.title === "PREZZI ALLINEATI") {
      const NEW_OBJ = await handleCreateGareDetails(params.row.array_gare);
      setGareSelected(NEW_OBJ);
      setRowNameSEL(params.row?.title);
      setDettaglioFlag(true);
    } else if (params.row?.title === "MOLECOLE NON INCROCIATE") {
    } else {
    }
  };

  const handleCreateTabACQ_RESUME = async (ACQ_SEL) => {
    const GARE_SEL_TEMP = [...gareSelected];

    let GARE_LIST_TEMP = [...ACQ_SEL?.garePubList];

    let ARRAY_TEMP = [];

    let Q_TOT = 0;
    let SPESA_TOT = 0;

    await GARE_SEL_TEMP.map((SEL) => {
      if (
        ACQ_SEL?.descrizione_gruppo_ospedaliero ===
        SEL?.descrizione_gruppo_ospedaliero
      ) {
        Q_TOT = Q_TOT + SEL.spec_checked.quantita;
        SPESA_TOT =
          SPESA_TOT +
          calcoloCosto(
            SEL.spec_checked.prezzo_unitario,
            SEL.spec_checked.quantita
          );

        ARRAY_TEMP.push(SEL);
      }

      return GARE_SEL_TEMP;
    });

    await GARE_LIST_TEMP.map((SEL) => {
      SEL.quantita_totale = Q_TOT;
      SEL.spesa_totale = Math.round(SPESA_TOT * 100) / 100;

      SEL.spesa_temp = calcoloCosto(SEL.prezzo_unitario, Q_TOT);

      return GARE_LIST_TEMP;
    });

    setQuantitaTOT(Q_TOT);
    setSpesaTOT(Math.round(SPESA_TOT * 100) / 100);

    setAcqSelGareList(GARE_LIST_TEMP);

    return ARRAY_TEMP;
  };

  const handleCreateTabACQ_RESUME_BIS = async (ACQ_SEL) => {
    const GARE_SEL_TEMP = [...gareSelected];
    let GARE_LIST_TEMP = [...ACQ_SEL?.garePubList];

    let ARRAY_TEMP = [];

    let Q_TOT = 0;
    let SPESA_TOT = 0;

    await GARE_SEL_TEMP.map((SEL) => {
      if (ACQ_SEL?.tipologia === SEL?.descrizione_gruppo_ospedaliero) {
        Q_TOT = Q_TOT + SEL.spec_checked.quantita;
        SPESA_TOT =
          SPESA_TOT +
          calcoloCosto(
            SEL.spec_checked.prezzo_unitario,
            SEL.spec_checked.quantita
          );

        ARRAY_TEMP.push(SEL);
      }

      return GARE_SEL_TEMP;
    });

    await GARE_LIST_TEMP.map((SEL) => {
      SEL.quantita_totale = Q_TOT;
      SEL.spesa_totale = Math.round(SPESA_TOT * 100) / 100;

      SEL.spesa_temp = calcoloCosto(SEL.prezzo_unitario, Q_TOT);

      return GARE_LIST_TEMP;
    });

    setQuantitaTOT(Q_TOT);
    setSpesaTOT(Math.round(SPESA_TOT * 100) / 100);
    setAcqSelGareList(GARE_LIST_TEMP);

    return ARRAY_TEMP;
  };

  const handleSingleDetail = async (params) => {
    const ACQ_TAB_TEMP = await handleCreateTabACQ_RESUME(params.row);

    setGareSingleDetail(ACQ_TAB_TEMP);
  };

  const handleSingleDetailGrouped = async (params) => {
    const ACQ_TAB_TEMP = await handleCreateTabACQ_RESUME_BIS(params.row);

    setGareSingleDetail(ACQ_TAB_TEMP);
  };

  const handleBackToInitialPage = () => {
    setAbbinatoResume(null);
    setSpecMatchedTOT(null);
    setSpecMatchedTOT_RESUME(null);
    setSpecToWait(null);
    setSpecToWaitATC(null);
    setGarePubBench(null);

    setAbbinatoDownloaded(null);

    setFinalAbbinatoToSave(null);
    setABBINATO_VALFlag(false);
  };

  //*Funzioni per selezione Acquistato e Strutture
  const handlePurchasedSelection = (newValue) => {
    if (newValue === null) {
      setPurchasedDownloaded(null);
      setListaStrutture(null);
    }

    setPurchased_List_Selected(newValue);
  };

  const handleCreaListaStrutture = async (AQC_SEL) => {
    if (!AQC_SEL?.id) {
      console.error("Invalid selection ID");
      return;
    }

    setLoadingListaStrutture(true);

    try {
      let purcasedDWN = await acquistatoService.getAcquistatoByPurchasedId(
        AQC_SEL.id
      );
      let purchasedTEMP = purcasedDWN.data;

      let listaStruttureTEMP = ["TUTTE LE STRUTTURE"];

      await purchasedTEMP.acquistato_inserito.forEach((SEL) => {
        listaStruttureTEMP.push(SEL.struttura);
      });

      const listaStrutture_DoppiRimossi = [...new Set(listaStruttureTEMP)];

      let finalListaStruttureTEMP = [];
      let countTEMP = 0;

      await listaStrutture_DoppiRimossi.forEach((SEL) => {
        finalListaStruttureTEMP.push({ id: countTEMP++, nome_struttura: SEL });
      });

      setPurchasedDownloaded(purchasedTEMP);
      setListaStrutture(finalListaStruttureTEMP);
    } catch (error) {
      console.error("Errore durante il recupero dei dati acquistati", error);
    } finally {
      setLoadingListaStrutture(false);
    }
  };

  const handleStruttureChange = (e, newValue) => {
    const tutteLeStruttureSelezionato = newValue.some(
      (option) => option.id === 0
    );

    if (tutteLeStruttureSelezionato) {
      const tutteLeStrutture = listaStrutture.filter(
        (option) => option.id !== 0
      );
      setStruttureSelected(tutteLeStrutture);
    } else {
      setStruttureSelected(newValue);
    }
  };

  //!! DA QUI SI CREA L'ABBINATO

  //*Funzioni di calcolo

  const calcoloDifferenzaPerc = (Costo, Differenza) => {
    if (Costo === 0) return 0;
    let diff_temp = (Differenza / Costo) * 100;
    return Math.round(diff_temp * 100) / 100;
  };

  const calcoloDifferenzaCosto = (CostoACQ, CostoOffSel) => {
    let diff_temp = CostoOffSel - CostoACQ;
    return Math.round(diff_temp * 100) / 100;
  };

  const calcoloPercentuale = (NumPerc, Totale) => {
    const PERC_TEMP = (NumPerc / Totale) * 100;
    return Math.round(PERC_TEMP * 100) / 100;
  };

  //*Funzioni di calcolo
  const handleCreaACQStruttureSelezionate = async (ACQ_TEMP) => {
    let ACQ_TO_FILTER = [...ACQ_TEMP.acquistato_inserito];

    const nomiStrutture = new Set(
      struttureSelected.map((item) => item.nome_struttura)
    );

    const filteredACQ = await ACQ_TO_FILTER.filter((obj) =>
      nomiStrutture.has(obj.struttura)
    );

    return filteredACQ;
  };

  const createOffersResumeView = async (offersResumeTEMP) => {
    let finalOBJ = [];
    let id_count = 0;

    await offersResumeTEMP.offerte_inserite.map((OFF_SEL) => {
      OFF_SEL.offerta_obj.map((SINGLE_OFF_SEL) => {
        let temp = {
          id_temp: id_count,
          is_checked: SINGLE_OFF_SEL?.is_checked ?? null,

          ragione_sociale: OFF_SEL?.ragione_sociale ?? null,
          cf_piva: OFF_SEL?.cf_piva ?? null,
          ordine_min: OFF_SEL?.ordine_min ?? null,
          pdf_offerta_id: OFF_SEL?.pdf_offerta_id ?? null,

          date: SINGLE_OFF_SEL?.date ?? null,
          data_protocollo: SINGLE_OFF_SEL?.data_protocollo ?? null,
          data_inserimento: OFF_SEL?.data_inserimento ?? null,

          aic: SINGLE_OFF_SEL?.aic ?? null,
          nome_commerciale: SINGLE_OFF_SEL?.nome_commerciale ?? null,
          numero_protocollo: SINGLE_OFF_SEL?.numero_protocollo ?? null,
          atc: SINGLE_OFF_SEL?.atc ?? null,
          atc_complementare: SINGLE_OFF_SEL?.atc_complementare ?? null,
          descrizione_atc: SINGLE_OFF_SEL?.descrizione_atc ?? null,
          principio_attivo: SINGLE_OFF_SEL?.principio_attivo ?? null,
          codice_gruppo_ospedaliero:
            SINGLE_OFF_SEL?.codice_gruppo_ospedaliero ?? null,
          descrizione_gruppo_ospedaliero:
            SINGLE_OFF_SEL?.descrizione_gruppo_ospedaliero ?? null,

          prezzo_unitario: SINGLE_OFF_SEL?.prezzo_unitario ?? null,

          dosaggio: SINGLE_OFF_SEL?.dosaggio ?? null,
          fascia: SINGLE_OFF_SEL?.fascia ?? null,
          forma_farmaceutica: SINGLE_OFF_SEL?.forma_farmaceutica ?? null,
          iva: SINGLE_OFF_SEL?.iva ?? null,
          numero_unita_confezione:
            SINGLE_OFF_SEL?.numero_unita_confezione ?? null,
          prezzo_netto_exfactory_confezione:
            SINGLE_OFF_SEL?.prezzo_netto_exfactory_confezione ?? null,
          prezzo_netto_exfactory_unitario:
            SINGLE_OFF_SEL?.prezzo_netto_exfactory_unitario ?? null,
          prezzo_pub_confezione: SINGLE_OFF_SEL?.prezzo_pub_confezione ?? null,
          prezzo_pub_unitario: SINGLE_OFF_SEL?.prezzo_pub_unitario ?? null,
          sconto_precentuale_prezzo_pub:
            SINGLE_OFF_SEL?.sconto_precentuale_prezzo_pub ?? null,
          filef_flag: SINGLE_OFF_SEL?.filef_flag ?? null,
        };

        id_count = id_count + 1;

        finalOBJ.push(temp);
        return OFF_SEL.offerta_obj;
      });

      return offersResumeTEMP.offerte_inserite;
    });

    return finalOBJ;
  };

  const sortByPrice = (a, b) => a.prezzo_unitario - b.prezzo_unitario;

  const creaSezione = (
    title,
    nMolecole,
    costoAcq,
    costoMin,
    diffPercMin,
    costoSel,
    diffPercSel,
    costoGarePub,
    diffCostoGarePub,
    diffPercGarePub
  ) => {
    return {
      title: title,
      n_molecole: nMolecole,
      costo_acq: Math.round(costoAcq * 100) / 100,
      costo_minimo: Math.round(costoMin * 100) / 100,
      diff_costo_acq_minimo: Math.round((costoMin - costoAcq) * 100) / 100,
      diff_perc_minimo: diffPercMin,
      costo_selezionato: Math.round(costoSel * 100) / 100,
      diff_costo_acq_selezionato: Math.round((costoSel - costoAcq) * 100) / 100,
      diff_perc_selezionato: diffPercSel,
      costo_garepub: costoGarePub,
      diff_costo_acq_garepub: diffCostoGarePub,
      diff_perc_garepub: diffPercGarePub,
    };
  };

  const handleBenchmarkingVAL = async (Array) => {
    const OFFERS_TEMP = [...Array];

    let SEL_GareSI = [];
    let SEL_GareNO = [];

    await OFFERS_TEMP.map((Selected) => {
      if (Selected.flag_match) {
        if (Selected.garePubList && Selected.garePubList.length > 0) {
          SEL_GareSI.push(Selected);
        } else {
          SEL_GareNO.push(Selected);
        }
      }

      return OFFERS_TEMP;
    });

    let GP_MIN_array = [];
    let GP_MAG_array = [];
    let GP_EQ_array = [];

    let GP_MIN_count = 0;
    let GP_MAG_count = 0;
    let GP_EQ_count = 0;

    let GP_MIN_spesaGP = 0;
    let GP_MAG_spesaGP = 0;
    let GP_EQ_spesaGP = 0;

    let GP_MIN_spesaABB = 0;
    let GP_MAG_spesaABB = 0;
    let GP_EQ_spesaABB = 0;

    await SEL_GareSI.map((SEL_SI) => {
      let spesaGP_TEMP = calcoloCosto(
        SEL_SI?.garePubList[0]?.prezzo_unitario,
        SEL_SI?.quantita
      );

      let spesaABB_TEMP = calcoloCosto(
        SEL_SI?.spec_checked?.prezzo_unitario,
        SEL_SI?.quantita
      );

      let diffSpesa_TEMP = calcoloDifferenzaCosto(spesaABB_TEMP, spesaGP_TEMP);

      if (diffSpesa_TEMP > 0) {
        GP_MAG_count = GP_MAG_count + 1;
        GP_MAG_spesaGP = GP_MAG_spesaGP + spesaGP_TEMP;
        GP_MAG_spesaABB = GP_MAG_spesaABB + spesaABB_TEMP;
        GP_MAG_array.push(SEL_SI);
      } else if (diffSpesa_TEMP === 0) {
        GP_EQ_count = GP_EQ_count + 1;
        GP_EQ_spesaGP = GP_EQ_spesaGP + spesaGP_TEMP;
        GP_EQ_spesaABB = GP_EQ_spesaABB + spesaABB_TEMP;
        GP_EQ_array.push(SEL_SI);
      } else if (diffSpesa_TEMP < 0) {
        GP_MIN_count = GP_MIN_count + 1;
        GP_MIN_spesaGP = GP_MIN_spesaGP + spesaGP_TEMP;
        GP_MIN_spesaABB = GP_MIN_spesaABB + spesaABB_TEMP;
        GP_MIN_array.push(SEL_SI);
      }

      return SEL_GareSI;
    });

    let spesaABB_TEMP = 0;

    await SEL_GareNO.map((SEL_NO) => {
      spesaABB_TEMP =
        spesaABB_TEMP +
        calcoloCosto(SEL_NO?.spec_checked?.prezzo_unitario, SEL_NO?.quantita);

      return SEL_GareNO;
    });

    const TOT_SPESA_GP = GP_MIN_spesaGP + GP_MAG_spesaGP + GP_EQ_spesaGP;
    const TOT_SPESA_ABB = GP_MIN_spesaABB + GP_MAG_spesaABB + GP_EQ_spesaABB;

    const VAL_OBJ_TEMP = [
      {
        title: "PREZZI MAGGIORI",
        n_molecole: GP_MIN_count,

        spesaABB: Math.round(GP_MIN_spesaABB * 100) / 100,
        perc_spesaABB: calcoloPercentuale(
          GP_MIN_spesaABB,
          spesaABB_TEMP + TOT_SPESA_ABB
        ),

        spesaGarePub: Math.round(GP_MIN_spesaGP * 100) / 100,
        diff_spesa: Math.round((GP_MIN_spesaGP - GP_MIN_spesaABB) * 100) / 100,
        perc_diff_spesa: calcoloDifferenzaPerc(
          GP_MIN_spesaABB,
          GP_MIN_spesaGP - GP_MIN_spesaABB
        ),
        array_gare: GP_MIN_array,
      },
      {
        title: "PREZZI MINORI",
        n_molecole: GP_MAG_count,

        spesaABB: Math.round(GP_MAG_spesaABB * 100) / 100,
        perc_spesaABB: calcoloPercentuale(
          GP_MAG_spesaABB,
          spesaABB_TEMP + TOT_SPESA_ABB
        ),

        spesaGarePub: Math.round(GP_MAG_spesaGP * 100) / 100,
        diff_spesa: Math.round((GP_MAG_spesaGP - GP_MAG_spesaABB) * 100) / 100,
        perc_diff_spesa: calcoloDifferenzaPerc(
          GP_MAG_spesaABB,
          GP_MAG_spesaGP - GP_MAG_spesaABB
        ),

        array_gare: GP_MAG_array,
      },
      {
        title: "PREZZI ALLINEATI",
        n_molecole: GP_EQ_count,

        spesaABB: Math.round(GP_EQ_spesaABB * 100) / 100,
        perc_spesaABB: calcoloPercentuale(
          GP_EQ_spesaABB,
          spesaABB_TEMP + TOT_SPESA_ABB
        ),

        spesaGarePub: Math.round(GP_EQ_spesaGP * 100) / 100,
        diff_spesa: 0,
        perc_diff_spesa: 0,
        array_gare: GP_EQ_array,
      },
      {
        title: "TOTALE MOLECOLE INCROCIATE",
        n_molecole: SEL_GareSI.length,

        spesaABB: Math.round(TOT_SPESA_ABB * 100) / 100,
        perc_spesaABB: calcoloPercentuale(
          TOT_SPESA_ABB,
          spesaABB_TEMP + TOT_SPESA_ABB
        ),

        spesaGarePub: Math.round(TOT_SPESA_GP * 100) / 100,
        diff_spesa: Math.round((TOT_SPESA_GP - TOT_SPESA_ABB) * 100) / 100,
        perc_diff_spesa: calcoloDifferenzaPerc(
          TOT_SPESA_ABB,
          TOT_SPESA_GP - TOT_SPESA_ABB
        ),

        array_gare: [],
      },
      {
        title: "MOLECOLE NON INCROCIATE",
        n_molecole: SEL_GareNO.length,

        spesaABB: Math.round(spesaABB_TEMP * 100) / 100,
        perc_spesaABB: calcoloPercentuale(
          spesaABB_TEMP,
          spesaABB_TEMP + TOT_SPESA_ABB
        ),

        spesaGarePub: "",
        diff_spesa: "",
        perc_diff_spesa: "",
        array_gare: [],
      },
      {
        title: "TOTALE MOLECOLE ANALIZZATE",
        n_molecole: SEL_GareSI.length + SEL_GareNO.length,

        spesaABB: Math.round((spesaABB_TEMP + TOT_SPESA_ABB) * 100) / 100,
        perc_spesaABB: 100,

        spesaGarePub: Math.round((spesaABB_TEMP + TOT_SPESA_GP) * 100) / 100,
        diff_spesa: Math.round((TOT_SPESA_GP - TOT_SPESA_ABB) * 100) / 100,
        perc_diff_spesa: calcoloDifferenzaPerc(
          TOT_SPESA_ABB,
          spesaABB_TEMP + TOT_SPESA_GP - TOT_SPESA_ABB
        ),
        array_gare: [],
      },
    ];

    const VAL_PIE_TEMP = [
      {
        id: "gp_minori",
        label: "PREZZI MAGGIORI",
        n_specialita: GP_MIN_count,
        perc_spesaABB: calcoloPercentuale(
          GP_MIN_spesaABB,
          spesaABB_TEMP + TOT_SPESA_ABB
        ),
        value: Math.round(GP_MIN_spesaABB * 100) / 100,
      },
      {
        id: "gp_maggiori",
        label: "PREZZI MINORI",
        n_specialita: GP_MAG_count,
        perc_spesaABB: calcoloPercentuale(
          GP_MAG_spesaABB,
          spesaABB_TEMP + TOT_SPESA_ABB
        ),
        value: Math.round(GP_MAG_spesaABB * 100) / 100,
      },
      {
        id: "gp_uguali",
        label: "PREZZI ALLINEATI",
        n_specialita: GP_EQ_count,
        perc_spesaABB: calcoloPercentuale(
          GP_EQ_spesaABB,
          spesaABB_TEMP + TOT_SPESA_ABB
        ),
        value: Math.round(GP_EQ_spesaABB * 100) / 100,
      },
      {
        id: "senza_gp",
        label: "MOLECOLE NON INCROCIATE",
        n_specialita: SEL_GareNO.length,
        perc_spesaABB: calcoloPercentuale(
          spesaABB_TEMP,
          spesaABB_TEMP + TOT_SPESA_ABB
        ),
        value: Math.round(spesaABB_TEMP * 100) / 100,
      },
    ];

    return {
      BENCH_TAB: VAL_OBJ_TEMP,
      BENCH_PIE: VAL_PIE_TEMP,
    };
  };

  const handleAbbinatoValutation = async (OBJTEMP) => {
    let OBJ_TO_VALUE = [...OBJTEMP];

    let updatedOBJ_TO_VALUE = [];

    let GPOSPList = [];

    OBJ_TO_VALUE.forEach((Selected) => {
      if (Selected.codice_gruppo_ospedaliero !== "") {
        GPOSPList.push(Selected.codice_gruppo_ospedaliero);
      }
    });

    const uniqueGPOSPList = [...new Set(GPOSPList)];

    let foundedGPOPS = await garepubService.getGPOSP(uniqueGPOSPList);

    const foundedGPOPSMap = Object.fromEntries(
      foundedGPOPS.map((element) => [
        element.data[0]?.codice_gruppo_ospedaliero,
        element.data,
      ])
    );

    updatedOBJ_TO_VALUE = OBJ_TO_VALUE.map((selected) => {
      const matchingData = foundedGPOPSMap[selected.codice_gruppo_ospedaliero];
      if (matchingData) {
        matchingData.sort(sortByPrice);
        selected.garePubList = matchingData;
      }
      return selected;
    });

    let toSelectSingle = [];
    let toSelectCollective = [];
    let toWait = [];
    let toWait_atc = [];

    //!Da vedere singolarmente
    let costoACQ_toSelectSingle = 0;
    let costoACQ_toSelectCollective = 0;

    let costoACQ_toWait = 0;
    let costoACQ_toWait_atc = 0;

    let costoMIN_toSelectSingle = 0;
    let costoMIN_toSelectCollective = 0;

    let costoSEL_toSelectSingle = 0;
    let costoSEL_toSelectCollective = 0;

    updatedOBJ_TO_VALUE.forEach((AbbSelected) => {
      if (AbbSelected.flag_match) {
        if (AbbSelected.flag_prize_collective) {
          costoACQ_toSelectCollective += calcoloCosto(
            AbbSelected.prezzo_unitario,
            AbbSelected.quantita
          );
          costoMIN_toSelectCollective += calcoloCosto(
            AbbSelected.cod_osp_list[0].prezzo_unitario,
            AbbSelected.cod_osp_list[0].quantita
          );
          costoSEL_toSelectCollective += calcoloCosto(
            AbbSelected.spec_checked.prezzo_unitario,
            AbbSelected.spec_checked.quantita
          );

          toSelectCollective.push(AbbSelected);
        } else {
          costoACQ_toSelectSingle += calcoloCosto(
            AbbSelected.prezzo_unitario,
            AbbSelected.quantita
          );
          costoMIN_toSelectSingle += calcoloCosto(
            AbbSelected.cod_osp_list[0].prezzo_unitario,
            AbbSelected.cod_osp_list[0].quantita
          );
          costoSEL_toSelectSingle += calcoloCosto(
            AbbSelected.spec_checked.prezzo_unitario,
            AbbSelected.spec_checked.quantita
          );

          toSelectSingle.push(AbbSelected);
        }
      } else if (AbbSelected.flag_gp_osp) {
        costoACQ_toWait += calcoloCosto(
          AbbSelected.prezzo_unitario,
          AbbSelected.quantita
        );
        toWait.push(AbbSelected);
      } else {
        costoACQ_toWait_atc += calcoloCosto(
          AbbSelected.prezzo_unitario,
          AbbSelected.quantita
        );
        toWait_atc.push(AbbSelected);
      }
    });

    const GARE_PUB_BENCHMARKING = await handleBenchmarkingVAL(
      updatedOBJ_TO_VALUE
    );

    // console.log("GARE_PUB_BENCHMARKING", GARE_PUB_BENCHMARKING);

    let toSelectTOT = toSelectSingle.concat(toSelectCollective);

    const differenzaCosti = (element) =>
      calcoloDifferenzaCosto(
        calcoloCosto(element.prezzo_unitario, element.quantita),
        calcoloCosto(
          element.spec_checked.prezzo_unitario,
          element.spec_checked.quantita
        )
      );

    toSelectTOT.sort((a, b) => {
      const differenzaA = differenzaCosti(a);
      const differenzaB = differenzaCosti(b);

      return differenzaA - differenzaB;
    });

    let SPEC_TO_SELECT_SINGLE = [
      creaSezione(
        "PREZZO MINIMO UNICO",
        toSelectSingle.length,
        costoACQ_toSelectSingle,
        costoMIN_toSelectSingle,
        calcoloDifferenzaPerc(
          costoACQ_toSelectSingle,
          costoMIN_toSelectSingle - costoACQ_toSelectSingle
        ),
        costoSEL_toSelectSingle,
        calcoloDifferenzaPerc(
          costoACQ_toSelectSingle,
          costoSEL_toSelectSingle - costoACQ_toSelectSingle
        ),
        "",
        "",
        ""
      ),
    ];

    let SPEC_TO_SELECT_COLLECTIVE = [
      creaSezione(
        "PREZZI MINIMI DISTINTI",
        toSelectCollective.length,
        costoACQ_toSelectCollective,
        costoMIN_toSelectCollective,
        calcoloDifferenzaPerc(
          costoACQ_toSelectCollective,
          costoMIN_toSelectCollective - costoACQ_toSelectCollective
        ),
        costoSEL_toSelectCollective,
        calcoloDifferenzaPerc(
          costoACQ_toSelectCollective,
          costoSEL_toSelectCollective - costoACQ_toSelectCollective
        ),
        "",
        "",
        ""
      ),
    ];

    let TOTALE_ABBINAMENTI = [
      creaSezione(
        "TOTALE ABBINAMENTI",
        toSelectSingle.length + toSelectCollective.length,
        costoACQ_toSelectSingle + costoACQ_toSelectCollective,
        costoMIN_toSelectSingle + costoMIN_toSelectCollective,
        calcoloDifferenzaPerc(
          costoACQ_toSelectSingle + costoACQ_toSelectCollective,
          costoMIN_toSelectSingle +
            costoMIN_toSelectCollective -
            (costoACQ_toSelectSingle + costoACQ_toSelectCollective)
        ),
        costoSEL_toSelectSingle + costoSEL_toSelectCollective,
        calcoloDifferenzaPerc(
          costoACQ_toSelectSingle + costoACQ_toSelectCollective,
          costoSEL_toSelectSingle +
            costoSEL_toSelectCollective -
            (costoACQ_toSelectSingle + costoACQ_toSelectCollective)
        ),
        GARE_PUB_BENCHMARKING.BENCH_TAB[5].spesaGarePub,
        GARE_PUB_BENCHMARKING.BENCH_TAB[5].diff_spesa,
        GARE_PUB_BENCHMARKING.BENCH_TAB[5].perc_diff_spesa
        // calcoloDifferenzaPerc(
        //   GARE_PUB_BENCHMARKING.BENCH_TAB[5].spesaABB,
        //   GARE_PUB_BENCHMARKING.BENCH_TAB[5].diff_spesa
        // )
      ),
    ];

    let SPEC_TO_WAIT = [
      creaSezione(
        "NON PERVENUTI",
        toWait.length,
        costoACQ_toWait,
        costoACQ_toWait,
        "",
        costoACQ_toWait,
        "",
        "",
        "",
        ""
      ),
    ];

    let SPEC_TO_WAIT_ATC = [
      creaSezione(
        "DISPOSITIVI MEDICI",
        toWait_atc.length,
        costoACQ_toWait_atc,
        costoACQ_toWait_atc,
        "",
        costoACQ_toWait_atc,
        "",
        "",
        "",
        ""
      ),
    ];

    let TOTALE_NON_ABBINATI = [
      creaSezione(
        "TOTALE NON ABBINATI",
        toWait.length + toWait_atc.length,
        costoACQ_toWait + costoACQ_toWait_atc,
        costoACQ_toWait + costoACQ_toWait_atc,
        "",
        costoACQ_toWait + costoACQ_toWait_atc,
        "",
        "",
        "",
        ""
      ),
    ];

    let TOTALE_GENERALE = [
      creaSezione(
        "TOTALE GENERALE",
        updatedOBJ_TO_VALUE.length,
        costoACQ_toSelectSingle +
          costoACQ_toSelectCollective +
          costoACQ_toWait +
          costoACQ_toWait_atc,
        costoMIN_toSelectSingle +
          costoMIN_toSelectCollective +
          costoACQ_toWait +
          costoACQ_toWait_atc,
        calcoloDifferenzaPerc(
          costoACQ_toSelectSingle +
            costoACQ_toSelectCollective +
            costoACQ_toWait +
            costoACQ_toWait_atc,
          costoMIN_toSelectSingle +
            costoMIN_toSelectCollective -
            (costoACQ_toSelectSingle + costoACQ_toSelectCollective)
        ),
        costoSEL_toSelectSingle +
          costoSEL_toSelectCollective +
          costoACQ_toWait +
          costoACQ_toWait_atc,
        calcoloDifferenzaPerc(
          costoACQ_toSelectSingle +
            costoACQ_toSelectCollective +
            costoACQ_toWait +
            costoACQ_toWait_atc,
          costoSEL_toSelectSingle +
            costoSEL_toSelectCollective -
            (costoACQ_toSelectSingle + costoACQ_toSelectCollective)
        ),
        "",
        "",
        ""
      ),
    ];

    const RESUME_TAB = SPEC_TO_SELECT_SINGLE.concat(SPEC_TO_SELECT_COLLECTIVE)
      .concat(TOTALE_ABBINAMENTI)
      .concat(SPEC_TO_WAIT)
      .concat(SPEC_TO_WAIT_ATC)
      .concat(TOTALE_NON_ABBINATI)
      .concat(TOTALE_GENERALE);

    return {
      RESUME_TAB: RESUME_TAB,
      GARE_PUB: GARE_PUB_BENCHMARKING,
      SPEC_TO_CHECK: toSelectTOT,
      OBJ_COMPLETE: updatedOBJ_TO_VALUE,
      TO_WAIT: toWait,
      TO_WAIT_ATC: toWait_atc,
    };
  };

  const changeFileFFlags = (ACQ_TO_ELAB, fileFListDWN) => {
    const fileF_TEMP = [...fileFListDWN.data?.obj_file];

    const updatedData = ACQ_TO_ELAB.map((item) => ({
      ...item,
      filef_flag: fileF_TEMP.includes(item.aic) ? true : item.filef_flag,
    }));

    return updatedData;
  };

  const roundTwo = (value) => Math.round(value * 100) / 100;

  const createFileFResume = async (OBJ_TO_ELAB) => {
    let TOT_FILEF = 0;
    let TOT_NO_FILEF = 0;

    let SPESA_ACQ_FILEF = 0;
    let SPESA_ACQ_NO_FILEF = 0;

    let SPESA_OFF_MIN_FILEF = 0;
    let SPESA_OFF_MIN_NO_FILEF = 0;

    await OBJ_TO_ELAB.forEach((SEL) => {
      if (SEL?.filef_flag) {
        let SPESA_ACQ_TEMP = calcoloCosto(SEL?.prezzo_unitario, SEL?.quantita);

        let SPESA_OFF_MIN_TEMP = calcoloCosto(
          SEL?.cod_osp_list[0]?.prezzo_unitario,
          SEL?.cod_osp_list[0]?.quantita
        );

        TOT_FILEF = TOT_FILEF + 1;
        SPESA_ACQ_FILEF = SPESA_ACQ_FILEF + SPESA_ACQ_TEMP;
        SPESA_OFF_MIN_FILEF = SPESA_OFF_MIN_FILEF + SPESA_OFF_MIN_TEMP;
      } else {
        let SPESA_ACQ_TEMP = calcoloCosto(SEL?.prezzo_unitario, SEL?.quantita);

        let SPESA_OFF_MIN_TEMP = calcoloCosto(
          SEL?.cod_osp_list[0]?.prezzo_unitario,
          SEL?.cod_osp_list[0]?.quantita
        );
        TOT_NO_FILEF = TOT_NO_FILEF + 1;
        SPESA_ACQ_NO_FILEF = SPESA_ACQ_NO_FILEF + SPESA_ACQ_TEMP;
        SPESA_OFF_MIN_NO_FILEF = SPESA_OFF_MIN_NO_FILEF + SPESA_OFF_MIN_TEMP;
      }
    });

    const final_OBJ = {
      TOT_FILEF: TOT_FILEF,
      TOT_NO_FILEF: TOT_NO_FILEF,

      SPESA_ACQ_FILEF: roundTwo(SPESA_ACQ_FILEF),
      SPESA_ACQ_NO_FILEF: roundTwo(SPESA_ACQ_NO_FILEF),

      SPESA_OFF_MIN_FILEF: roundTwo(SPESA_OFF_MIN_FILEF),
      SPESA_OFF_MIN_NO_FILEF: roundTwo(SPESA_OFF_MIN_NO_FILEF),

      RISPARMIO_PERC_FILEF: calcoloDifferenzaPerc(
        SPESA_ACQ_FILEF,
        SPESA_OFF_MIN_FILEF - SPESA_ACQ_FILEF
      ),
      RISPARMIO_PERC_NO_FILEF: calcoloDifferenzaPerc(
        SPESA_ACQ_NO_FILEF,
        SPESA_OFF_MIN_NO_FILEF - SPESA_ACQ_NO_FILEF
      ),
    };

    return final_OBJ;
  };

  const handleCreaAbbinato = async () => {
    try {
      setLoadingABBValutation(true);

      const offersListDWN =
        await offersResumeService.getOffersResumetByOffersResumetId(
          Off_List_Selected.id
        );

      let fileFListDWN = null;

      if (FileF_List_Selected !== null) {
        fileFListDWN = await filefService.getFileFByFileFId(
          FileF_List_Selected.id
        );
      }

      const offersListTEMP = offersListDWN.data;
      const purchasedTEMP = { ...purchasedDownloaded };

      const ACQ_SEL = await handleCreaACQStruttureSelezionate(purchasedTEMP);
      let ACQ_SEL_TEMP = [...ACQ_SEL];

      const SINGLE_OFFERS_TEMP = await createOffersResumeView(offersListTEMP);

      let ACQ_SEL_TEMP_ELAB = [];

      if (FileF_List_Selected !== null) {
        ACQ_SEL_TEMP_ELAB = changeFileFFlags(ACQ_SEL_TEMP, fileFListDWN);
      } else {
        ACQ_SEL_TEMP_ELAB = [...ACQ_SEL_TEMP];
      }

      await ACQ_SEL_TEMP_ELAB.map((purchasedSelected) => {
        let id_subTable = 0;
        if (purchasedSelected?.codice_gruppo_ospedaliero.length > 0) {
          const COD_OSP_List = [];

          SINGLE_OFFERS_TEMP.map((offerSelected) => {
            let temp = [];

            if (
              purchasedSelected.codice_gruppo_ospedaliero ===
              offerSelected.codice_gruppo_ospedaliero
            ) {
              temp = {
                id_subTable: id_subTable,
                // is_checked: offerSelected.is_checked,
                flag_is_checked: false,

                //?info principali
                ragione_sociale: offerSelected.ragione_sociale,
                cf_piva: offerSelected.cf_piva,
                ordine_min: offerSelected.ordine_min,
                pdf_offerta_id: offerSelected.pdf_offerta_id,

                aic: offerSelected.aic,
                nome_commerciale: offerSelected.nome_commerciale,
                numero_protocollo: offerSelected.numero_protocollo,
                atc: offerSelected.atc,
                atc_complementare: offerSelected.atc_complementare,
                descrizione_atc: offerSelected.descrizione_atc,
                principio_attivo: offerSelected.principio_attivo,
                codice_gruppo_ospedaliero:
                  offerSelected.codice_gruppo_ospedaliero,
                descrizione_gruppo_ospedaliero:
                  offerSelected.descrizione_gruppo_ospedaliero,

                prezzo_unitario: offerSelected.prezzo_unitario,

                //?info ACQ (salvate nelle singole off per comodità dei calcoli)
                id_acquistato_control: purchasedSelected._id,
                prezzo_unitario_acq: purchasedSelected.prezzo_unitario,
                quantita: purchasedSelected.quantita,
                costo_acquistato: calcoloCosto(
                  purchasedSelected.prezzo_unitario,
                  purchasedSelected.quantita
                ),

                //?date
                data_fine: offerSelected.date[1],
                data_inizio: offerSelected.date[0],
                data_protocollo: offerSelected.data_protocollo,
                data_inserimento: offerSelected.data_inserimento,

                //?info extra
                dosaggio: offerSelected.dosaggio,
                fascia: offerSelected.fascia,
                forma_farmaceutica: offerSelected.forma_farmaceutica,
                iva: offerSelected.iva,
                numero_unita_confezione: offerSelected.numero_unita_confezione,
                prezzo_netto_exfactory_confezione:
                  offerSelected.prezzo_netto_exfactory_confezione,
                prezzo_netto_exfactory_unitario:
                  offerSelected.prezzo_netto_exfactory_unitario,
                prezzo_pub_confezione: offerSelected.prezzo_pub_confezione,
                prezzo_pub_unitario: offerSelected.prezzo_pub_unitario,
                sconto_precentuale_prezzo_pub:
                  offerSelected.sconto_precentuale_prezzo_pub,
                filef_flag: offerSelected.filef_flag,
              };

              id_subTable = id_subTable + 1;
              COD_OSP_List.push(temp);
            } else {
            }
            return SINGLE_OFFERS_TEMP;
          });

          COD_OSP_List.sort((a, b) => {
            let x = a.prezzo_unitario;
            let y = b.prezzo_unitario;

            if (x < y) {
              return -1;
            } else if (x > y) {
              return 1;
            }
            return 0;
          });

          purchasedSelected.cod_osp_list = COD_OSP_List;
          purchasedSelected.spec_checked = COD_OSP_List[0];
          purchasedSelected.id_acq = purchasedSelected._id;
          purchasedSelected.note = "NOTE:";
        } else {
          purchasedSelected.cod_osp_list = [];
          purchasedSelected.id_acq = purchasedSelected._id;
          purchasedSelected.note = "NOTE:";
        }

        return ACQ_SEL_TEMP_ELAB;
      });

      let ACQ_COD_OSP_MATCHED = [];
      let ACQ_COD_OSP_NOTMATCHED = [];

      await ACQ_SEL_TEMP_ELAB.forEach((purchasedSelected) => {
        if (purchasedSelected.cod_osp_list.length > 0) {
          purchasedSelected.flag_match = true;
          purchasedSelected.flag_assigned = false;
          purchasedSelected.flag_prize_collective = false;
          purchasedSelected.flag_gp_osp = true;

          ACQ_COD_OSP_MATCHED.push(purchasedSelected);
        } else {
          purchasedSelected.flag_match = false;
          ACQ_COD_OSP_NOTMATCHED.push(purchasedSelected);
        }
      });

      let MATCH_SINGLE_MIN = [];
      let MATCH_MULTIPLE_MIN = [];

      ACQ_COD_OSP_MATCHED.forEach((Selected) => {
        let samePrize = false;

        if (Selected.cod_osp_list.length === 1) {
          Selected.cod_osp_list[0].flag_is_checked = true;
          MATCH_SINGLE_MIN.push(Selected);
        } else {
          if (
            Selected.cod_osp_list[0].prezzo_unitario ===
            Selected.cod_osp_list[1].prezzo_unitario
          ) {
            samePrize = true;
          } else {
            Selected.cod_osp_list[0].flag_is_checked = true;
            MATCH_SINGLE_MIN.push(Selected);
          }
        }

        if (samePrize) {
          Selected.flag_prize_collective = true;
          MATCH_MULTIPLE_MIN.push(Selected);
        }
      });

      let WITHOUT_GP_OSP = [];
      let WITH_GP_OSP_NOMATCH = [];

      ACQ_COD_OSP_NOTMATCHED.forEach((Selected) => {
        if (Selected.codice_gruppo_ospedaliero === "") {
          Selected.flag_gp_osp = false;
          Selected.flag_assigned = false;

          WITHOUT_GP_OSP.push(Selected);
        } else {
          Selected.flag_gp_osp = true;

          WITH_GP_OSP_NOMATCH.push(Selected);
        }
      });

      let OBJToValue = MATCH_SINGLE_MIN.concat(MATCH_MULTIPLE_MIN)
        .concat(WITHOUT_GP_OSP)
        .concat(WITH_GP_OSP_NOMATCH);

      const finalOBJ = await handleAbbinatoValutation(OBJToValue);

      const resumeSPEC_TEST = await handleCreateSpecResume(
        finalOBJ.SPEC_TO_CHECK
      );

      // console.log("finalOBJ", finalOBJ);
      // console.log("resumeSPEC_TEST", resumeSPEC_TEST);

      const resumeSPEC_TEST_SORTED = resumeSPEC_TEST.sort((a, b) => {
        const diffA =
          calcoloCosto(a.cod_osp_list[0].prezzo_unitario, a.quantita_tot) -
          a.spesa_tot;
        const diffB =
          calcoloCosto(b.cod_osp_list[0].prezzo_unitario, b.quantita_tot) -
          b.spesa_tot;
        return diffA - diffB;
      });

      if (FileF_List_Selected !== null) {
        const fileF_TOSAVE = await createFileFResume(finalOBJ.SPEC_TO_CHECK);

        setFileFTAB_TEMP([
          {
            title: "FILE F",
            n_molecole: fileF_TOSAVE?.TOT_FILEF,
            costo_acq: fileF_TOSAVE?.SPESA_ACQ_FILEF,
            costo_minimo: fileF_TOSAVE?.SPESA_OFF_MIN_FILEF,
            diff_perc: fileF_TOSAVE?.RISPARMIO_PERC_FILEF,
          },
          {
            title: "NO FILE F",
            n_molecole: fileF_TOSAVE?.TOT_NO_FILEF,
            costo_acq: fileF_TOSAVE?.SPESA_ACQ_NO_FILEF,
            costo_minimo: fileF_TOSAVE?.SPESA_OFF_MIN_NO_FILEF,
            diff_perc: fileF_TOSAVE?.RISPARMIO_PERC_NO_FILEF,
          },
          {
            title: "TOTALE",
            n_molecole: fileF_TOSAVE?.TOT_FILEF + fileF_TOSAVE?.TOT_NO_FILEF,
            costo_acq: roundTwo(
              fileF_TOSAVE?.SPESA_ACQ_FILEF + fileF_TOSAVE?.SPESA_ACQ_NO_FILEF
            ),
            costo_minimo: roundTwo(
              fileF_TOSAVE?.SPESA_OFF_MIN_FILEF +
                fileF_TOSAVE?.SPESA_OFF_MIN_NO_FILEF
            ),
            diff_perc: calcoloDifferenzaPerc(
              fileF_TOSAVE?.SPESA_ACQ_FILEF + fileF_TOSAVE?.SPESA_ACQ_NO_FILEF,
              fileF_TOSAVE?.SPESA_OFF_MIN_FILEF +
                fileF_TOSAVE?.SPESA_OFF_MIN_NO_FILEF -
                (fileF_TOSAVE?.SPESA_ACQ_FILEF +
                  fileF_TOSAVE?.SPESA_ACQ_NO_FILEF)
            ),
          },
        ]);
      } else {
        setFileFTAB_TEMP([
          {
            title: "FILE F",
            n_molecole: 0,
            costo_acq: 0,
            costo_minimo: 0,
            diff_perc: 0,
          },
          {
            title: "NO FILE F",
            n_molecole: 0,
            costo_acq: 0,
            costo_minimo: 0,
            diff_perc: 0,
          },
          {
            title: "TOTALE",
            n_molecole: 0,
            costo_acq: 0,
            costo_minimo: 0,
            diff_perc: 0,
          },
        ]);
      }

      setAbbinatoResume(finalOBJ.RESUME_TAB);
      setSpecMatchedTOT(finalOBJ.SPEC_TO_CHECK);
      setSpecMatchedTOT_RESUME(resumeSPEC_TEST_SORTED);

      setFinalAbbinatoToSave(finalOBJ.OBJ_COMPLETE);
      setSpecToWait(finalOBJ.TO_WAIT);
      setSpecToWaitATC(finalOBJ.TO_WAIT_ATC);
      setGarePubBench(finalOBJ.GARE_PUB);

      setSuppListNEW(handleSuppFilter(finalOBJ.SPEC_TO_CHECK));

      setABBINATO_VALFlag(true);

      //? ERRORI
    } catch (error) {
      console.error("Errore durante il recupero dei dati: ", error);
      //? FINALE
    } finally {
      setLoadingABBValutation(false);
    }
  };

  //!VALUTAZIONE SU ABBINATO GIA SALVATO

  const handleCreateSpecResume = async (SPEC_TEMP) => {
    const SPEC_TEMP_TOELAB = [...SPEC_TEMP];

    const groupedData = SPEC_TEMP_TOELAB.reduce((acc, current) => {
      const { descrizione_gruppo_ospedaliero } = current;

      if (!acc[descrizione_gruppo_ospedaliero]) {
        acc[descrizione_gruppo_ospedaliero] = [];
      }

      acc[descrizione_gruppo_ospedaliero].push(current);
      return acc;
    }, {});

    let ArrayGruped = [];
    let id_count = 0;

    await Object.values(groupedData).map((SEL) => {
      let SPESA_TOT = 0;
      let Q_TOT = 0;

      SEL.map((SEL_BIS) => {
        SPESA_TOT =
          SPESA_TOT + calcoloCosto(SEL_BIS.prezzo_unitario, SEL_BIS.quantita);
        Q_TOT = Q_TOT + SEL_BIS.quantita;

        return SEL;
      });

      let temp = {
        id_temp: id_count,
        spesa_tot: Math.round(SPESA_TOT * 100) / 100,
        quantita_tot: Q_TOT,
        tipologia: SEL[0]?.descrizione_gruppo_ospedaliero,
        cod_osp_list: SEL[0]?.cod_osp_list,
        garePubList: SEL[0]?.garePubList,
      };

      ArrayGruped.push(temp);

      id_count = id_count + 1;

      return Object.values(groupedData);
    });

    // console.log("ArrayGruped", ArrayGruped);

    return ArrayGruped;
  };

  const handleDownloadAbbinato = async () => {
    try {
      setLoadingABBValutation(true);

      const abbinatoDWN =
        await abbinamentoService.getAbbinamentoByAbbinamentoId(
          Abb_List_Selected.id
        );

      let OBJToValue = [...abbinatoDWN.data.obj_file];

      const finalOBJ = await handleAbbinatoValutation(OBJToValue);

      const resumeSPEC_TEST = await handleCreateSpecResume(
        finalOBJ.SPEC_TO_CHECK
      );

      setAbbinatoDownloaded(abbinatoDWN.data);

      setAbbinatoResume(finalOBJ.RESUME_TAB);
      setSpecMatchedTOT(finalOBJ.SPEC_TO_CHECK);
      setSpecMatchedTOT_RESUME(resumeSPEC_TEST);
      setFinalAbbinatoToSave(finalOBJ.OBJ_COMPLETE);
      setSpecToWait(finalOBJ.TO_WAIT);
      setSpecToWaitATC(finalOBJ.TO_WAIT_ATC);
      setGarePubBench(finalOBJ.GARE_PUB);

      setABBINATO_VALFlag(true);

      //? ERRORI
    } catch (error) {
      console.error("Errore durante il recupero dei dati: ", error);
      //? FINALE
    } finally {
      setLoadingABBValutation(false);
    }
  };

  //!save abb
  const handlePopUpSaveABBClose = () => {
    setPopUpSaveABBOpen(false);
    setFileSavedName("");
  };

  const ValidateForm = () => {
    const fileSavedNameCheck = fileSavedName;
    const newErrors = {};

    if (!fileSavedNameCheck || fileSavedNameCheck === "") {
      newErrors.fileSavedName = "Inserire il nome del file!";
    }

    return newErrors;
  };

  const handleSaveNewABB = async () => {
    const formErrors = ValidateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setErrors({});
      setLoadingSaveABB(true);

      const finaleAbbinamentoDate = dayjs()
        .utc()
        .local()
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ");

      let finalFileData = {
        nome_abbinato: fileSavedName,
        data: finaleAbbinamentoDate,
        nome_acquistato: Purchased_List_Selected.nome_salvataggio,
        nome_listino: Off_List_Selected.nome_listino,
        obj_file: finalAbbinatoToSave,
      };

      try {
        abbinamentoService.setToken(user.token);

        await abbinamentoService.salvaAbbinamento(user.meshRoom, finalFileData);

        setNewABBFlag(false);
        setPurchasedDownloaded(false);

        setListaStrutture(null);
        setStruttureSelected(null);
        setFlagConfermaStrutture(false);

        handlePopUpSaveABBClose();

        handleBackToInitialPage();

        setUpdateTrigger((prev) => prev + 1);
      } catch (error) {
        console.error("Errore durante il salvataggio:", error);
      } finally {
        setLoadingSaveABB(false);
      }
    }
  };

  //!SELECTION PAGE
  const handleSelectionListPage = (params) => {
    if (params.row.title === "TOTALE ABBINAMENTI") {
      setSelectionListPageFlag(true);
    } else if (params.row.title === "NON PERVENUTI") {
      setDaRichiederePageFlag(true);
    } else if (params.row.title === "DISPOSITIVI MEDICI") {
      setDispMediciPageFlag(true);
    } else if (params.row.title === "PREZZI MINIMI DISTINTI") {
      setPopUpExpMultipliOpen(true);
    }
  };

  const handleReturnToResumePage = () => {
    setSelectionListPageFlag(false);
    setDaRichiederePageFlag(false);
    setDispMediciPageFlag(false);

    //---
    setDettaglioFlag(false);
    setGareSingleDetail(null);

    setSpesaTOT_SPEC(null);
    setQuantitaTOT_SPEC(null);
  };

  const updateResume = async () => {
    let RESUME_TEMP = [...abbinatoResume];
    let MOD_TEMP = [...specMatchedTOT];

    let costoSEL_toSelectSingle = 0;
    let costoSEL_toSelectCollective = 0;

    await MOD_TEMP.map((SEL) => {
      if (SEL.flag_match) {
        if (SEL.flag_prize_collective) {
          costoSEL_toSelectCollective += calcoloCosto(
            SEL.spec_checked.prezzo_unitario,
            SEL.spec_checked.quantita
          );
        } else {
          costoSEL_toSelectSingle += calcoloCosto(
            SEL.spec_checked.prezzo_unitario,
            SEL.spec_checked.quantita
          );
        }
      }

      return MOD_TEMP;
    });

    // console.log("costoSEL_toSelectSingle", costoSEL_toSelectSingle);
    // console.log("RESUME_TEMP[0].costo_acq", RESUME_TEMP[0].costo_acq);

    //?mopdifica RIGA 1
    RESUME_TEMP[0].costo_selezionato =
      Math.round(costoSEL_toSelectSingle * 100) / 100;

    RESUME_TEMP[0].diff_costo_acq_selezionato =
      Math.round((costoSEL_toSelectSingle - RESUME_TEMP[0].costo_acq) * 100) /
      100;

    RESUME_TEMP[0].diff_perc_selezionato = calcoloDifferenzaPerc(
      RESUME_TEMP[0].costo_acq,
      costoSEL_toSelectSingle - RESUME_TEMP[0].costo_acq
    );

    //?mopdifica RIGA 2
    RESUME_TEMP[1].costo_selezionato =
      Math.round(costoSEL_toSelectCollective * 100) / 100;

    RESUME_TEMP[1].diff_costo_acq_selezionato =
      Math.round(
        (costoSEL_toSelectCollective - RESUME_TEMP[1].costo_acq) * 100
      ) / 100;

    RESUME_TEMP[1].diff_perc_selezionato = calcoloDifferenzaPerc(
      RESUME_TEMP[1].costo_acq,
      costoSEL_toSelectCollective - RESUME_TEMP[1].costo_acq
    );

    //?mopdifica RIGA 3
    RESUME_TEMP[2].costo_selezionato =
      Math.round(
        (costoSEL_toSelectSingle + costoSEL_toSelectCollective) * 100
      ) / 100;

    RESUME_TEMP[2].diff_costo_acq_selezionato =
      Math.round(
        (costoSEL_toSelectSingle +
          costoSEL_toSelectCollective -
          RESUME_TEMP[2].costo_acq) *
          100
      ) / 100;

    RESUME_TEMP[2].diff_perc_selezionato = calcoloDifferenzaPerc(
      RESUME_TEMP[2].costo_acq,
      costoSEL_toSelectSingle +
        costoSEL_toSelectCollective -
        RESUME_TEMP[2].costo_acq
    );

    //?mopdifica RIGA 7
    RESUME_TEMP[6].costo_selezionato =
      Math.round(
        (RESUME_TEMP[5].costo_selezionato +
          costoSEL_toSelectSingle +
          costoSEL_toSelectCollective) *
          100
      ) / 100;

    RESUME_TEMP[6].diff_costo_acq_selezionato =
      Math.round(
        (RESUME_TEMP[5].costo_selezionato +
          costoSEL_toSelectSingle +
          costoSEL_toSelectCollective -
          RESUME_TEMP[6].costo_acq) *
          100
      ) / 100;

    RESUME_TEMP[6].diff_perc_selezionato = calcoloDifferenzaPerc(
      RESUME_TEMP[6].costo_acq,
      RESUME_TEMP[5].costo_selezionato +
        costoSEL_toSelectSingle +
        costoSEL_toSelectCollective -
        RESUME_TEMP[6].costo_acq
    );

    setAbbinatoResume(RESUME_TEMP);
  };

  const handleReturnToResumePageAndUpdate = async () => {
    await updateResume();
    setSelectionListPageFlag(false);
    setDaRichiederePageFlag(false);
    setDispMediciPageFlag(false);

    //---
    setDettaglioFlag(false);
    setGareSingleDetail(null);
  };

  const handleBackToSelectionList = () => {
    setSpecTEMP(null);
    setSpecSelectionPageFlag(false);
  };

  //!SEARCH
  const handleResetSearch = () => {
    setFilteredResults(null);
    setSearchedFlag(false);
    setNotFoundFlag(false);
    setSearchPopupOpen(false);
  };

  const handleSearch = () => {
    if (searchValue === null) {
      handleResetSearch();
    } else {
      let ArrayToSearch = [...specMatchedTOT];
      const filtered = ArrayToSearch.filter((item) =>
        item[selectedField.field]
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );

      if (filtered.length > 0) {
        setFilteredResults(filtered);
        setNotFoundFlag(false);
        setSearchPopupOpen(false);

        setSearchedFlag(true);
      } else {
        setFilteredResults(filtered);
        setNotFoundFlag(true);
      }
    }
  };

  const createSuggestionsList = async (newValue, abbData) => {
    let arrayToSearch = [...abbData];

    let SUGG_TEMP = [];

    await arrayToSearch.map((Selected) => {
      SUGG_TEMP.push(Selected[newValue.field]);

      return arrayToSearch;
    });

    const SUGG_TEMP_UNIQUE = [...new Set(SUGG_TEMP)];
    SUGG_TEMP_UNIQUE.sort();

    return SUGG_TEMP_UNIQUE;
  };

  const handleFieldChange = async (e, newValue) => {
    if (newValue !== null) {
      setSelectedField(newValue);

      let SUGGESTIONS_LIST_TEMP = await createSuggestionsList(
        newValue,
        specMatchedTOT
      );

      setFieldSuggestions(SUGGESTIONS_LIST_TEMP);
    }
  };

  const handleSuppTEMPChange = async (e, newValue) => {
    setSelectSuppTEMP(newValue);
  };

  const filterDataBySelectedSupplier = (selectedSupplier) => {
    if (selectedSupplier === null) {
      handleResetSearch();
    } else {
      let ArrayToSearch = [...specMatchedTOT];
      console.log("selectedSupplier", selectedSupplier);

      const filtered = ArrayToSearch.filter(
        (item) =>
          (item.fornitore &&
            item.fornitore.ragione_sociale === selectedSupplier.name) ||
          (Array.isArray(item.cod_osp_list) &&
            item.cod_osp_list.some(
              (subItem) => subItem.ragione_sociale === selectedSupplier.name
            ))
      );

      if (filtered.length > 0) {
        setFilteredResults(filtered);
        setNotFoundFlag(false);
        setSearchPopupOpen(false);

        setSearchedFlag(true);
      } else {
        setFilteredResults(filtered);
        setNotFoundFlag(true);
      }
    }
  };

  const handleSearchPrezziMultipli = () => {
    let ArrayToSearch = [...specMatchedTOT];

    const filtered = ArrayToSearch.filter((item) => {
      return item.flag_prize_collective === true;
    });

    if (filtered.length > 0) {
      setFilteredResults(filtered);
      setNotFoundFlag(false);
      setSearchPopupOpen(false);

      setSearchedFlag(true);
    } else {
      setFilteredResults(filtered);
      setNotFoundFlag(true);
    }
  };

  //!SEL

  //?CREA LE DUE TABELLE (OFFERTE E GARE) PER IL DETTAGLIO SELEZIONE
  const handleSpecSelectionPage = async (params) => {
    // console.log("HERE");
    let ALL_SPEC_TEMP = [...specMatchedTOT];
    // console.log("ALL_SPEC_TEMP", ALL_SPEC_TEMP);

    //?crea lista gare pubbliche
    let gp_TEMP = [];
    if (params.row?.garePubList && params.row.garePubList.length > 0) {
      gp_TEMP = [...params.row?.garePubList];

      // console.log("gp_TEMP", gp_TEMP);

      await gp_TEMP.map((Selected) => {
        Selected.costo_acquistato = calcoloCosto(
          params.row?.prezzo_unitario,
          params.row?.quantita
        );
        Selected.costo_garapub = calcoloCosto(
          Selected?.prezzo_unitario,
          params.row?.quantita
        );

        return gp_TEMP;
      });
    } else {
    }

    //?controlla se ci sono gruppi ospedalieri in comune e crea un array
    let GROUPED_SPEC_TEMP = [];
    let SPESA_TOT_TEMP = 0;
    let Q_TOT_TEMP = 0;

    await ALL_SPEC_TEMP.map((Selected) => {
      if (
        Selected?.descrizione_gruppo_ospedaliero ===
        params.row.descrizione_gruppo_ospedaliero
      ) {
        GROUPED_SPEC_TEMP.push(Selected);
        Q_TOT_TEMP = Q_TOT_TEMP + Selected.quantita;
        SPESA_TOT_TEMP =
          SPESA_TOT_TEMP +
          calcoloCosto(Selected.prezzo_unitario, Selected.quantita);
      }

      return ALL_SPEC_TEMP;
    });

    await GROUPED_SPEC_TEMP.map((SEL) => {
      SEL.cod_osp_list.map((SEL_BIS) => {
        SEL_BIS.quantita_totale = Q_TOT_TEMP;
        SEL_BIS.spesa_totale = Math.round(SPESA_TOT_TEMP * 100) / 100;

        SEL_BIS.spesa_temp = calcoloCosto(SEL_BIS.prezzo_unitario, Q_TOT_TEMP);

        return SEL.cod_osp_list;
      });

      return GROUPED_SPEC_TEMP;
    });

    await gp_TEMP.map((SEL) => {
      SEL.quantita_totale = Q_TOT_TEMP;
      SEL.spesa_totale = Math.round(SPESA_TOT_TEMP * 100) / 100;

      SEL.spesa_temp = calcoloCosto(SEL.prezzo_unitario, Q_TOT_TEMP);

      return gp_TEMP;
    });

    setSpecTEMP(
      GROUPED_SPEC_TEMP.sort((a, b) => b.prezzo_unitario - a.prezzo_unitario)
    );
    setSpecGP_TEMP(gp_TEMP);
    setSpecSelectionPageFlag(true);

    setQuantitaTOT_SPEC(Q_TOT_TEMP);
    setSpesaTOT_SPEC(Math.round(SPESA_TOT_TEMP * 100) / 100);
  };

  const handleSpecSelectionPageBIS = async (params) => {
    // console.log("params", params);
    let ALL_SPEC_TEMP = [...specMatchedTOT];
    // console.log("ALL_SPEC_TEMP", ALL_SPEC_TEMP);

    // //?crea lista gare pubbliche
    let gp_TEMP = [];
    if (params.row?.garePubList && params.row.garePubList.length > 0) {
      gp_TEMP = [...params.row?.garePubList];

      // console.log("gp_TEMP", gp_TEMP);

      await gp_TEMP.map((Selected) => {
        Selected.costo_acquistato = calcoloCosto(
          params.row?.prezzo_unitario,
          params.row?.quantita
        );
        Selected.costo_garapub = calcoloCosto(
          Selected?.prezzo_unitario,
          params.row?.quantita
        );

        return gp_TEMP;
      });
    } else {
    }

    // //?controlla se ci sono gruppi ospedalieri in comune e crea un array
    let GROUPED_SPEC_TEMP = [];
    let SPESA_TOT_TEMP = 0;
    let Q_TOT_TEMP = 0;

    await ALL_SPEC_TEMP.map((Selected) => {
      if (
        Selected?.descrizione_gruppo_ospedaliero ===
        params.row?.cod_osp_list[0]?.descrizione_gruppo_ospedaliero
      ) {
        GROUPED_SPEC_TEMP.push(Selected);
        Q_TOT_TEMP = Q_TOT_TEMP + Selected.quantita;
        SPESA_TOT_TEMP =
          SPESA_TOT_TEMP +
          calcoloCosto(Selected.prezzo_unitario, Selected.quantita);
      }

      return ALL_SPEC_TEMP;
    });

    await GROUPED_SPEC_TEMP.map((SEL) => {
      SEL.cod_osp_list.map((SEL_BIS) => {
        SEL_BIS.quantita_totale = Q_TOT_TEMP;
        SEL_BIS.spesa_totale = Math.round(SPESA_TOT_TEMP * 100) / 100;

        SEL_BIS.spesa_temp = calcoloCosto(SEL_BIS.prezzo_unitario, Q_TOT_TEMP);

        return SEL.cod_osp_list;
      });

      return GROUPED_SPEC_TEMP;
    });

    await gp_TEMP.map((SEL) => {
      SEL.quantita_totale = Q_TOT_TEMP;
      SEL.spesa_totale = Math.round(SPESA_TOT_TEMP * 100) / 100;

      SEL.spesa_temp = calcoloCosto(SEL.prezzo_unitario, Q_TOT_TEMP);

      return gp_TEMP;
    });

    setSpecTEMP(
      GROUPED_SPEC_TEMP.sort((a, b) => b.prezzo_unitario - a.prezzo_unitario)
    );
    setSpecGP_TEMP(gp_TEMP);
    setSpecSelectionPageFlag(true);

    setQuantitaTOT_SPEC(Q_TOT_TEMP);
    setSpesaTOT_SPEC(Math.round(SPESA_TOT_TEMP * 100) / 100);
  };

  //?APRE E CHIUDE POPUP PER CONFERMA MODIFICHE DI SELZIONE
  const handlePopUpChangeSelectionOpen = (params) => {
    setPopUpChangeSelectionOpen(true);
    setParamsTEMP(params.row);
  };

  const handlePopUpChangeSelectionClose = () => {
    setPopUpChangeSelectionOpen(false);
    setParamsTEMP(null);
  };

  //?MODIFICA LA SELEZIONE
  const handleDoubleClickSpecSelection = async (params) => {
    let TEMP_SPEC_TOT = [...specMatchedTOT];
    let TEMP_SPEC_SEL = [...specTEMP];

    // console.log("TEMP_SPEC_SEL", TEMP_SPEC_SEL);

    //!!!!!
    let newSel = {};

    await TEMP_SPEC_SEL.map((SEL_SPEC) => {
      SEL_SPEC.cod_osp_list.map((Selected) => {
        if (Selected.id_subTable === paramsTEMP.id_subTable) {
          newSel = Selected;
          Selected.flag_is_checked = true;
        } else {
          Selected.flag_is_checked = false;
        }
        return SEL_SPEC.cod_osp_list;
      });

      SEL_SPEC.spec_checked = newSel;
      SEL_SPEC.flag_assigned = true;

      return TEMP_SPEC_SEL;
    });

    // await TEMP_SPEC_SEL.map((SEL_SPEC) => {

    //   return TEMP_SPEC_SEL;
    // });

    await TEMP_SPEC_SEL.map((SEL_SPEC) => {
      TEMP_SPEC_TOT = TEMP_SPEC_TOT.filter((SEL) => SEL._id !== SEL_SPEC._id);

      TEMP_SPEC_TOT.push(SEL_SPEC);

      return TEMP_SPEC_SEL;
    });

    // console.log("TEMP_SPEC_TOT", TEMP_SPEC_TOT);-

    TEMP_SPEC_TOT.sort((a, b) => {
      let x = calcoloDifferenzaCosto(
        a.spec_checked.costo_acquistato,
        calcoloCosto(a.spec_checked.prezzo_unitario, a.spec_checked.quantita)
      );
      let y = calcoloDifferenzaCosto(
        b.spec_checked.costo_acquistato,
        calcoloCosto(b.spec_checked.prezzo_unitario, b.spec_checked.quantita)
      );

      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    setSpecMatchedTOT(TEMP_SPEC_TOT);
    setSpecTEMP(TEMP_SPEC_SEL);
    setPopUpChangeSelectionOpen(false);
  };

  //?SALVATAGGIO MODIFICHE NEL DATABASE
  const handleUpdateNewABB = async () => {
    setLoadingUpdateABB(true);

    let ABB_TOUPDATE_TEMP = { ...abbinatoDownloaded };
    let ABB_TOUPDATE_TEMP_OBJ = [...abbinatoDownloaded.obj_file];
    let SPEC_TOT_TEMP = [...specMatchedTOT];

    let noMatchTEMP = ABB_TOUPDATE_TEMP_OBJ.filter(
      (SEL) => SEL.flag_match === false
    );

    let newOBJ = SPEC_TOT_TEMP.concat(noMatchTEMP);

    ABB_TOUPDATE_TEMP.obj_file = newOBJ;

    abbinamentoService.setToken(user.token);

    await abbinamentoService
      .aggiornaAbbinamento(ABB_TOUPDATE_TEMP, ABB_TOUPDATE_TEMP.id)
      .then((res) => {
        setAbbinatoDownloaded(res.data);
      });

    setPopUpUpdateABBOpen(false);
    setLoadingUpdateABB(false);
  };

  const handleSuppFilter = (data) => {
    if (data !== null) {
      const ragioniSocialiSet = new Set();

      data.forEach((item) => {
        // Aggiunge la ragione sociale del fornitore
        if (item.fornitore && item.fornitore.ragione_sociale) {
          ragioniSocialiSet.add(item.fornitore.ragione_sociale);
        }

        // Aggiunge le ragioni sociali dall'array cod_osp_list
        if (Array.isArray(item.cod_osp_list)) {
          item.cod_osp_list.forEach((subItem) => {
            if (subItem.ragione_sociale) {
              ragioniSocialiSet.add(subItem.ragione_sociale);
            }
          });
        }
      });

      return Array.from(ragioniSocialiSet).map((name, index) => ({
        id: index + 1, // Genera un ID univoco basato sull'index
        name: name,
      }));
    }
  };

  //!POPUP
  const handleGPPopUp = (params) => {
    setDettaglioGaraPub(params.row);
    setPopUpGaraPubOpen(true);
  };

  const handlePopUpGaraPubClose = () => {
    setPopUpGaraPubOpen(false);
    setDettaglioGaraPub(null);
  };

  useEffect(() => {
    if (!user.meshRoom) return;

    acquistatoService
      .getAcquistatoByMeshRoomId(user.meshRoom)
      .then((res) => {
        setPurchasedList(res.data);
        setLoadingPurchasedList(false);
      })
      .catch((error) => {
        console.error("Errore durante il recupero degli acquistati:", error);
      });

    offersResumeService
      .getOfferaResumeByMeshRoomId(user.meshRoom)
      .then((res) => {
        setOfferList(res.data);
        setLoadingOfferList(false);
      })
      .catch((error) => {
        console.error(
          "Errore durante il recupero dei listini dal server:",
          error
        );
      });

    abbinamentoService
      .getAbbinamentoByMeshRoomId(user.meshRoom)
      .then((res) => {
        setAbbinatiList(res.data);
        setLoadingAbbinatiList(false);
      })
      .catch((error) => {
        console.error("Errore durante il recupero degli abbinati:", error);
      });

    filefService
      .getFileFByMeshRoomId(user.meshRoom)
      .then((res) => {
        setFileFList(res.data);
        setLoadingFileFList(false);
      })
      .catch((error) => {
        console.error("Errore durante il recupero dei filef:", error);
      });
  }, [updateTrigger, user.meshRoom]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="abbPage">
          {!ABBINATO_VALFlag ? (
            <>
              <div
                style={{
                  textAlign: "right",
                  marginBottom: "1%",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  color="bluetest"
                  startIcon={!newABBFlag ? <AddCircleIcon /> : <ListAltIcon />}
                  onClick={() => setNewABBFlag(!newABBFlag)}
                >
                  {!newABBFlag ? "CREA NUOVO ABBINATO" : "VISUALIZZA ABBINATI"}
                </Button>
              </div>
              <div>
                {!newABBFlag ? (
                  <AbbinatoViewResume
                    loadingAbbinatiList={loadingAbbinatiList}
                    abbinatiList={abbinatiList}
                    Abb_List_Selected={Abb_List_Selected}
                    setAbb_List_Selected={setAbb_List_Selected}
                    handleDownloadAbbinato={handleDownloadAbbinato}
                    loadingABBValutation={loadingABBValutation}
                  />
                ) : (
                  <>
                    {!flagConfermaStrutture ? (
                      <AbbinatoSelStrutture
                        loadingPurchasedList={loadingPurchasedList}
                        purchasedList={purchasedList}
                        Purchased_List_Selected={Purchased_List_Selected}
                        listaStrutture={listaStrutture}
                        loadingListaStrutture={loadingListaStrutture}
                        struttureSelected={struttureSelected}
                        handlePurchasedSelection={handlePurchasedSelection}
                        handleCreaListaStrutture={handleCreaListaStrutture}
                        handleStruttureChange={handleStruttureChange}
                        setFlagConfermaStrutture={setFlagConfermaStrutture}
                      />
                    ) : (
                      <AbbinatoGenerate
                        setFlagConfermaStrutture={setFlagConfermaStrutture}
                        loadingOfferList={loadingOfferList}
                        offerList={offerList}
                        Off_List_Selected={Off_List_Selected}
                        setOff_List_Selected={setOff_List_Selected}
                        loadingFileFList={loadingFileFList}
                        fileFList={fileFList}
                        FileF_List_Selected={FileF_List_Selected}
                        setFileF_List_Selected={setFileF_List_Selected}
                        handleCreaAbbinato={handleCreaAbbinato}
                        loadingABBValutation={loadingABBValutation}
                      />
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <SelectionMain
                //*Flags
                selectionListPageFlag={selectionListPageFlag}
                specSelectionPageFlag={specSelectionPageFlag}
                daRichiederePageFlag={daRichiederePageFlag}
                dispMediciPageFlag={dispMediciPageFlag}
                //*Oggetti Abbinato
                abbinatoDownloaded={abbinatoDownloaded}
                abbinatoResume={abbinatoResume}
                specMatchedTOT={specMatchedTOT}
                specToWait={specToWait}
                specToWaitATC={specToWaitATC}
                garePubBench={garePubBench}
                // fileFOBJ_TEMP={fileFOBJ_TEMP}
                fileFTAB_TEMP={fileFTAB_TEMP}
                //*
                handleBackToInitialPage={handleBackToInitialPage}
                newABBFlag={newABBFlag}
                handleSelectionListPage={handleSelectionListPage}
                handleReturnToResumePage={handleReturnToResumePage}
                handleReturnToResumePageAndUpdate={
                  handleReturnToResumePageAndUpdate
                }
                setPopUpSaveABBOpen={setPopUpSaveABBOpen}
                popUpExpMultipliOpen={popUpExpMultipliOpen}
                setPopUpExpMultipliOpen={setPopUpExpMultipliOpen}
                searchedFlag={searchedFlag}
                setSearchedFlag={setSearchedFlag}
                setSearchPopupOpen={setSearchPopupOpen}
                filteredResults={filteredResults}
                handleResetSearch={handleResetSearch}
                handleSpecSelectionPage={handleSpecSelectionPage}
                handleSpecSelectionPageBIS={handleSpecSelectionPageBIS}
                handleBackToSelectionList={handleBackToSelectionList}
                specTEMP={specTEMP}
                specGP_TEMP={specGP_TEMP}
                popUpGaraPubOpen={popUpGaraPubOpen}
                handleGPPopUp={handleGPPopUp}
                handlePopUpGaraPubClose={handlePopUpGaraPubClose}
                dettaglioGaraPub={dettaglioGaraPub}
                popUpChangeSelectionOpen={popUpChangeSelectionOpen}
                paramsTEMP={paramsTEMP}
                handlePopUpChangeSelectionOpen={handlePopUpChangeSelectionOpen}
                handlePopUpChangeSelectionClose={
                  handlePopUpChangeSelectionClose
                }
                handleDoubleClickSpecSelection={handleDoubleClickSpecSelection}
                setPopUpUpdateABBOpen={setPopUpUpdateABBOpen}
                popUpUpdateABBOpen={popUpUpdateABBOpen}
                // setLoadingUpdateABB={setLoadingUpdateABB}
                loadingUpdateABB={loadingUpdateABB}
                handleUpdateNewABB={handleUpdateNewABB}
                //---
                garaDetailsOpen={garaDetailsOpen}
                gareSelected={gareSelected}
                setGaraDetailsOpen={setGaraDetailsOpen}
                setGareSelected={setGareSelected}
                handleGaraDetailsClose={handleGaraDetailsClose}
                handleViewDetailsTab={handleViewDetailsTab}
                //-----
                dettaglioFlag={dettaglioFlag}
                setDettaglioFlag={setDettaglioFlag}
                gareSingleDetail={gareSingleDetail}
                arrayGrupedFlag={arrayGrupedFlag}
                rowNameSEL={rowNameSEL}
                // arrayGrupedFlag={arrayGrupedFlag}
                setArrayGrupedFlag={setArrayGrupedFlag}
                handleSingleDetail={handleSingleDetail}
                handleSingleDetailGrouped={handleSingleDetailGrouped}
                arrayGrupedTEMP={arrayGrupedTEMP}
                setGareSingleDetail={setGareSingleDetail}
                quantitaTOT={quantitaTOT}
                spesaTOT={spesaTOT}
                setQuantitaTOT={setQuantitaTOT}
                setSpesaTOT={setSpesaTOT}
                acqSelGareList={acqSelGareList}
                quantitaTOT_SPEC={quantitaTOT_SPEC}
                spesaTOT_SPEC={spesaTOT_SPEC}
                //---
                arrayGrupedFlag_SPEC={arrayGrupedFlag_SPEC}
                setArrayGrupedFlag_SPEC={setArrayGrupedFlag_SPEC}
                specMatchedTOT_RESUME={specMatchedTOT_RESUME}
              />
            </>
          )}
          <SAVE_ABB_POPUP
            popUpSaveABBOpen={popUpSaveABBOpen}
            loadingSaveABB={loadingSaveABB}
            handlePopUpSaveABBClose={handlePopUpSaveABBClose}
            fileSavedName={fileSavedName}
            setFileSavedName={setFileSavedName}
            errors={errors}
            handleSaveNewABB={handleSaveNewABB}
          />
          <ABBINATO_SEARCH_POPUP
            searchPopupOpen={searchPopupOpen}
            setSearchPopupOpen={setSearchPopupOpen}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            abb_search_options={abb_search_options}
            handleSearch={handleSearch}
            handleSearchPrezziMultipli={handleSearchPrezziMultipli}
            notFoundFlag={notFoundFlag}
            selectedField={selectedField}
            fieldSuggestions={fieldSuggestions}
            handleFieldChange={handleFieldChange}
            searchedFlag={searchedFlag}
            handleResetSearch={handleResetSearch}
            filteredResults={filteredResults}
            suppListNEW={suppListNEW}
            selectSuppTEMP={selectSuppTEMP}
            handleSuppTEMPChange={handleSuppTEMPChange}
            filterDataBySelectedSupplier={filterDataBySelectedSupplier}
          />
        </div>
      </ThemeProvider>
    </>
  );
};

export default AbbinatoMain;
