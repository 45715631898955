import React, { useState, useEffect } from "react";
import "../../../../App.css";

// SERVICES
import acquistatoService from "../../../../services/acquistato";

// FUNCTIONS
import { handleColumnOrder } from "../../../../functions/TAB_SORT/ACQ_VIEW_SORT";
import {
  handlePurchasedDownload,
  handleResetACQDownload,
} from "../../../../functions/DOWNLOAD/ACQ_VIEW_DWN";
import {
  handleResetSearch,
  handleSearch,
  createSuggestionsList,
  handleFieldChange,
} from "../../../../functions/TAB_SEARCH/ACQ_VIEW_SEARCH";

import {
  ButtonContainer,
  LoaderContainer,
  ErrorContainer,
} from "../../../../functions/STYLE/ACQUISTATO_NEW/ACQ_VIEW_CONTAINER";

// COLUMNS
import { ACQ_VIEW_COLUMNS } from "../../../../colums/ACQ_COLUMNS";

// EXPS
import EXP_ACQ_VIEW_COMPLETE from "../../../../exports/ACQ/EXP_ACQ_VIEW_COMPLETE";
import EXP_ACQ_FABBISOGNO from "../../../../exports/ACQ/EXP_ACQ_FABBISOGNO";

// POPUPS
import PURCHASED_SEARCH_POPUP from "../../../../popup/SEARCH_POPUP/PURCHASED_SEARCH_POPUP";

// MUI ELEMENTS
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// ICONS
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

// LOADERS
import BeatLoader from "react-spinners/BeatLoader";

// OPTIONS
import acq_search_options from "../../../../utils/SEARCH_OPTIONS/acq_search_options.json";

const ButtonGroup = ({
  handleReset,
  setSearchPopupOpen,
  searchedFlag,
  handleResetClick,
  SingleACQ_Downloaded,
}) => (
  <div>
    <div style={{ display: "inline-block", width: "10%", textAlign: "left" }}>
      <Button
        variant="contained"
        color="redtest"
        startIcon={<ArrowBackIosNewOutlinedIcon />}
        onClick={handleReset}
      >
        INDIETRO
      </Button>
    </div>
    <div style={{ display: "inline-block", width: "10%", textAlign: "left" }}>
      <Button
        variant="contained"
        color="bluetest"
        startIcon={<SearchIcon />}
        onClick={() => setSearchPopupOpen(true)}
      >
        CERCA
      </Button>
    </div>
    {searchedFlag ? (
      <div style={{ display: "inline-block", width: "40%", textAlign: "left" }}>
        <Button
          variant="contained"
          color="redtest"
          startIcon={<RestartAltIcon />}
          onClick={handleResetClick}
        >
          RESET CERCA
        </Button>
      </div>
    ) : (
      <div
        style={{ display: "inline-block", width: "40%", textAlign: "left" }}
      ></div>
    )}
    <div style={{ display: "inline-block", width: "25%", textAlign: "right" }}>
      <EXP_ACQ_VIEW_COMPLETE data={SingleACQ_Downloaded} />
    </div>
    <div style={{ display: "inline-block", width: "15%", textAlign: "right" }}>
      <EXP_ACQ_FABBISOGNO data={SingleACQ_Downloaded} />
    </div>
  </div>
);

const PurchasedView = ({ user, setChangePageFlag }) => {
  const [purchasedList, setPurchasedList] = useState([]);
  const [ACQ_Selected, setACQ_Selected] = useState(null);
  const [SingleACQ_Downloaded, setSingleACQ_Downloaded] = useState(null);
  const [flagACQDownloadError, setFlagACQDownloadError] = useState(false);
  const [loadingPurchaseds, setLoadingPurchaseds] = useState(true);
  const [loadingSinglePurchased, setLoadingSinglePurchased] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredResults, setFilteredResults] = useState(null);
  const [fieldSuggestions, setFieldSuggestions] = useState([]);
  const [searchedFlag, setSearchedFlag] = useState(false);
  const [searchPopupOpen, setSearchPopupOpen] = useState(false);
  const [notFoundFlag, setNotFoundFlag] = useState(false);
  const [selectedField, setSelectedField] = useState(
    acq_search_options.find((option) => option.name === "NOME COMMERCIALE")
  );
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    if (!user.meshRoom) return;

    acquistatoService
      .getAcquistatoByMeshRoomId(user.meshRoom)
      .then((res) => {
        setPurchasedList(res.data);
        setLoadingPurchaseds(false);
      })
      .catch((error) => {
        console.error("Errore durante il recupero degli acquistati:", error);
      });
  }, [user.meshRoom]);

  const handleColumnOrderWrapper = (columnSEL) => {
    handleColumnOrder(
      columnSEL,
      searchedFlag,
      filteredResults,
      SingleACQ_Downloaded,
      sortOrder,
      setFilteredResults,
      setSingleACQ_Downloaded,
      setSortOrder
    );
  };

  const handleDownload = () => {
    handlePurchasedDownload(
      ACQ_Selected,
      setLoadingSinglePurchased,
      setFlagACQDownloadError,
      setSingleACQ_Downloaded,
      setFieldSuggestions,
      setChangePageFlag
    );
  };

  const handleReset = () => {
    handleResetACQDownload(
      setSingleACQ_Downloaded,
      setACQ_Selected,
      setChangePageFlag
    );
  };

  const handleSearchClick = () => {
    handleSearch(
      searchValue,
      SingleACQ_Downloaded,
      selectedField,
      setFilteredResults,
      setNotFoundFlag,
      setSearchPopupOpen,
      setSearchedFlag,
      () =>
        handleResetSearch(
          setFilteredResults,
          setSearchedFlag,
          setNotFoundFlag,
          setSearchPopupOpen
        )
    );
  };

  const handleFieldChangeWrapper = (e, newValue) => {
    handleFieldChange(
      newValue,
      SingleACQ_Downloaded,
      setSelectedField,
      setFieldSuggestions,
      createSuggestionsList
    );
  };

  const handleResetClick = () => {
    handleResetSearch(
      setFilteredResults,
      setSearchedFlag,
      setNotFoundFlag,
      setSearchPopupOpen
    );
  };

  return (
    <>
      {loadingPurchaseds ? (
        <LoaderContainer>
          <BeatLoader
            color="#1976d2"
            loading={loadingPurchaseds}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </LoaderContainer>
      ) : (
        <>
          {SingleACQ_Downloaded === null ? (
            <>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={purchasedList}
                getOptionLabel={(option) => option.nome_salvataggio}
                sx={{ width: "100%", padding: "10% 38% 0" }}
                renderInput={(params) => (
                  <TextField {...params} label="LISTA ACQUISTATI" />
                )}
                value={ACQ_Selected}
                onChange={(e, newValue) => setACQ_Selected(newValue)}
              />
              {ACQ_Selected && (
                <ButtonContainer>
                  <Button
                    variant="contained"
                    color="bluetest"
                    startIcon={<FileDownloadOutlinedIcon />}
                    onClick={handleDownload}
                  >
                    CARICA SELEZIONATO
                  </Button>
                </ButtonContainer>
              )}
              {loadingSinglePurchased && (
                <LoaderContainer>
                  <BeatLoader
                    color="#1976d2"
                    loading={loadingSinglePurchased}
                    size={30}
                  />
                </LoaderContainer>
              )}
              {flagACQDownloadError && (
                <ErrorContainer>
                  ERRORE NEL CARICAMENTO DELL'ACQUISTATO
                </ErrorContainer>
              )}
            </>
          ) : (
            <Box sx={{ height: 430, width: "100%", paddingTop: "2%" }}>
              <ButtonGroup
                handleReset={handleReset}
                setSearchPopupOpen={setSearchPopupOpen}
                searchedFlag={searchedFlag}
                handleResetClick={handleResetClick}
                SingleACQ_Downloaded={SingleACQ_Downloaded}
              />

              <DataGridPro
                sx={{
                  height: "430px",
                  width: "100%",
                  marginTop: "2%",
                  "& .theme_header": {
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    fontFamily: "Calibri",
                    fontSize: "16px",
                  },
                  "& .theme_cell": {
                    fontFamily: "Calibri",
                    fontSize: "17px",
                    border: "1px solid",
                  },
                }}
                columns={ACQ_VIEW_COLUMNS}
                rows={
                  searchedFlag
                    ? filteredResults
                    : SingleACQ_Downloaded.acquistato_inserito
                }
                getRowId={(row) => row._id}
                headerHeight={40}
                rowHeight={33}
                onColumnHeaderDoubleClick={handleColumnOrderWrapper}
                disableColumnMenu
                hideFooter
              />
            </Box>
          )}
        </>
      )}
      <PURCHASED_SEARCH_POPUP
        searchPopupOpen={searchPopupOpen}
        setSearchPopupOpen={setSearchPopupOpen}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        acq_search_options={acq_search_options}
        handleSearchClick={handleSearchClick}
        notFoundFlag={notFoundFlag}
        selectedField={selectedField}
        fieldSuggestions={fieldSuggestions}
        handleFieldChangeWrapper={handleFieldChangeWrapper}
        searchedFlag={searchedFlag}
        handleResetClick={handleResetClick}
      />
    </>
  );
};

export default PurchasedView;
