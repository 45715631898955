import React, { useState, useEffect } from "react";
import "../../../../App.css";
import "../../../../styles/ACQUISTATO/Insert.css";

// SERVICES
import acquistatoService from "../../../../services/acquistato";

// COMPONENTS
import PurchasedForm from "./PurchasedForm";
import PurchasedImport from "./PurchasedImport";

// FUNCTIONS
import {
  handleResetSearch,
  handleSearch,
  createSuggestionsList,
  handleFieldChange,
} from "../../../../functions/TAB_SEARCH/ACQ_INSERT_SEARCH";

import { handleColumnOrder } from "../../../../functions/TAB_SORT/ACQ_INSERT_SORT";

import { visualizzaData } from "../../../../functions/DATE/VISUALIZZA_DATA";

import { boxStyles } from "../../../../boxStyles/ACQUISTATO/INSERT_BOXES";

// COLUMNS
import { ACQ_MAIN_COLUMNS } from "../../../../colums/ACQ_COLUMNS";

// EXPS
import EXP_ACQ_MAIN_COMPLETE from "../../../../exports/ACQ/EXP_ACQ_MAIN_COMPLETE";

// POPUPS
import ACQ_SAVE_CONFIRM_POPUP from "../../../../popup/ACQ_POPUP/ACQ_SAVE_CONFIRM_POPUP";
import PURCHASED_SEARCH_POPUP from "../../../../popup/SEARCH_POPUP/PURCHASED_SEARCH_POPUP";

// MUI ELEMENTS
import Button from "@mui/material/Button";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

// ICONS
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SearchIcon from "@mui/icons-material/Search";

// OPTIONS
import acq_search_options from "../../../../utils/SEARCH_OPTIONS/acq_search_options.json";

const PurchasedInsert = ({ user, setChangePageFlag }) => {
  const [Dates, setDates] = useState([]);
  const [DatesFlag, setDatesFlag] = useState(false);

  const [errors, setErrors] = useState({});
  const [fileSavedName, setFileSavedName] = useState("");

  const [savePopUpOpen, setSavePopUpOpen] = useState(false);

  const [loadingFinalSave, setLoadingFinalSave] = useState(false);

  const updateDates = (newFileData) => {
    setDates(newFileData);
  };

  const checkedDatesFlag = (flag) => {
    setDatesFlag(flag);
  };

  const handleChangeDates = () => {
    setDatesFlag(false);
    setChangePageFlag(true);
  };

  const [fileData, setFileData] = useState([]);
  const [fileDataSaved, setFileDataSaved] = useState(false);

  const [searchedFlag, setSearchedFlag] = useState(false);
  const [searchPopupOpen, setSearchPopupOpen] = useState(false);
  const [notFoundFlag, setNotFoundFlag] = useState(false);

  const [selectedField, setSelectedField] = useState(
    acq_search_options.find((option) => option.name === "NOME COMMERCIALE")
  );

  const [searchValue, setSearchValue] = useState("");
  const [filteredResults, setFilteredResults] = useState(null);

  const [fieldSuggestions, setFieldSuggestions] = useState([]);

  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {}, []);

  const updateFileData = (newOfferData) => {
    newOfferData.map((Selected) => {
      Selected.date = Dates;
      return newOfferData;
    });

    setFileData(newOfferData);
  };

  const insertedFile = (flag) => {
    setFileDataSaved(flag);
  };

  const handleSavePopUpClose = () => {
    setSavePopUpOpen(false);
    setLoadingFinalSave(false);
    setFileSavedName("");
  };

  const handleFinalSavePurchased = async () => {
    const formErrors = ValidateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setLoadingFinalSave(true);
      setErrors({});

      let finalFileData = {
        nome_salvataggio: fileSavedName,
        anno: Dates[1].substring(0, 4),
        is_valid: true,
        acquistato_inserito: fileData,
      };

      acquistatoService.setToken(user.token);

      await acquistatoService
        .salvaAcquistato(user.meshRoom, finalFileData)
        .then((res) => {
          handleRemove();
          handleChangeDates();
          setFileSavedName("");
          setLoadingFinalSave(false);
        });
    }
  };

  const handleRemove = () => {
    setFileData([]);
    insertedFile(false);
    setFileSavedName("");
    setFileDataSaved(false);
  };

  const ValidateForm = () => {
    const fileSavedNameCheck = fileSavedName;
    const newErrors = {};

    if (!fileSavedNameCheck || fileSavedNameCheck === "") {
      newErrors.fileSavedName = "Inserire il nome per il Database";
    }

    return newErrors;
  };

  const handleSearchClick = () => {
    handleSearch(
      searchValue,
      fileData,
      selectedField,
      setFilteredResults,
      setNotFoundFlag,
      setSearchPopupOpen,
      setSearchedFlag,
      () =>
        handleResetSearch(
          setFilteredResults,
          setSearchedFlag,
          setNotFoundFlag,
          setSearchPopupOpen
        )
    );
  };

  const handleFieldChangeWrapper = (e, newValue) => {
    handleFieldChange(
      newValue,
      fileData,
      setSelectedField,
      setFieldSuggestions,
      createSuggestionsList
    );
  };

  const handleResetClick = () => {
    handleResetSearch(
      setFilteredResults,
      setSearchedFlag,
      setNotFoundFlag,
      setSearchPopupOpen
    );
  };

  const handleColumnOrderWrapper = (columnSEL) => {
    handleColumnOrder(
      columnSEL,
      searchedFlag,
      filteredResults,
      fileData,
      sortOrder,
      setFilteredResults,
      setFileData,
      setSortOrder
    );
  };

  const ButtonGroupInsert = ({
    handleRemove,
    setSearchPopupOpen,
    setSavePopUpOpen,
  }) => (
    <div className="container">
      <div className="inline-block left-align width-13">
        <Button
          variant="contained"
          component="label"
          color="redtest"
          startIcon={<DeleteOutlinedIcon />}
          onClick={handleRemove}
        >
          RIMUOVI IL FILE
        </Button>
      </div>
      <div className="inline-block left-align width-52">
        <Button
          variant="contained"
          color="bluetest"
          startIcon={<SearchIcon />}
          onClick={() => setSearchPopupOpen(true)}
        >
          CERCA
        </Button>
      </div>
      <div className="inline-block right-align width-20">
        <EXP_ACQ_MAIN_COMPLETE data={fileData} periodo={Dates} />
      </div>
      <div className="inline-block right-align width-15">
        <Button
          variant="contained"
          component="label"
          color="success"
          startIcon={<SaveOutlinedIcon />}
          onClick={() => setSavePopUpOpen(true)}
        >
          SALVA ACQUISTATO
        </Button>
      </div>
    </div>
  );

  return (
    <>
      {!fileDataSaved && !DatesFlag && (
        <>
          <PurchasedForm
            updateDates={updateDates}
            checkedDatesFlag={checkedDatesFlag}
            setChangePageFlag={setChangePageFlag}
          />
        </>
      )}
      {!fileDataSaved && DatesFlag && (
        <>
          <PurchasedImport
            updateFileData={updateFileData}
            insertedFile={insertedFile}
            dates={Dates}
            handleChangeDates={handleChangeDates}
          />
        </>
      )}
      {fileDataSaved && (
        <>
          <ButtonGroupInsert
            handleRemove={handleRemove}
            setSearchPopupOpen={setSearchPopupOpen}
            setSavePopUpOpen={setSavePopUpOpen}
          />

          <Box sx={boxStyles}>
            <div className="container">
              <div className="inline-block left-align width-50">
                <p className="left-align text-blue font-calibri font-size-22">
                  PERIODO SELEZIONATO:{" "}
                  <b>
                    {visualizzaData(Dates[0].split("T")[0])} -{" "}
                    {visualizzaData(Dates[1].split("T")[0])}{" "}
                  </b>
                </p>
              </div>
              <div className="inline-block right-align width-50">
                <p className="right-align text-blue font-calibri font-size-22">
                  <b>TOT ELEMENTI: {fileData.length}</b>
                </p>
              </div>
            </div>

            <DataGridPro
              columns={ACQ_MAIN_COLUMNS}
              rows={!searchedFlag ? fileData : filteredResults}
              getRowId={(row) => row.id_acquistato_temp}
              headerHeight={40}
              rowHeight={33}
              onColumnHeaderDoubleClick={handleColumnOrderWrapper}
              disableColumnMenu
              hideFooter
            />
          </Box>
          <ACQ_SAVE_CONFIRM_POPUP
            savePopUpOpen={savePopUpOpen}
            handleSavePopUpClose={handleSavePopUpClose}
            handleFinalSavePurchased={handleFinalSavePurchased}
            errors={errors}
            fileSavedName={fileSavedName}
            setFileSavedName={setFileSavedName}
            loadingFinalSave={loadingFinalSave}
          />
          <PURCHASED_SEARCH_POPUP
            searchPopupOpen={searchPopupOpen}
            setSearchPopupOpen={setSearchPopupOpen}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            acq_search_options={acq_search_options}
            handleSearchClick={handleSearchClick}
            notFoundFlag={notFoundFlag}
            selectedField={selectedField}
            fieldSuggestions={fieldSuggestions}
            handleFieldChangeWrapper={handleFieldChangeWrapper}
            searchedFlag={searchedFlag}
            handleResetClick={handleResetClick}
          />
        </>
      )}
    </>
  );
};

export default PurchasedInsert;
