import axios from "axios";

//se sviluppo
// const urlFileF = "http://localhost:3001/api/filef";
//se produzione
const urlFileF = "/api/filef";

let token = null;

const setToken = (newToken) => {
  token = "bearer " + newToken;
  console.log("TOKEN SETTATO:", token); // 📌 Verifica se il token viene impostato correttamente
};

// 📌 Modificato: ora recupera i fielf dalla MeshRoom
const getFileFByMeshRoomId = (meshRoomId) => {
  return axios.get(`${urlFileF}/meshroom/${meshRoomId}`);
};

const getFileFByFileFId = (filefId) => {
  return axios.get(`${urlFileF}/singlefilef/${filefId}`);
};

// 📌 Modificato: ora salva i filef nella MeshRoom
const salvaFileF = async (meshRoomId, newFileF) => {
  try {
    const config = {
      headers: { Authorization: token },
    };
    const res = await axios.post(`${urlFileF}/${meshRoomId}`, newFileF, config);
    return res;
  } catch (error) {
    console.error(
      "Errore nella richiesta POST:",
      error.response?.data || error.message
    );
    return "error";
  }
};

const filefService = {
  getFileFByMeshRoomId,
  getFileFByFileFId,
  salvaFileF,
  setToken,
};

export default filefService;
